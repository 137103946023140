import { Injectable } from '@angular/core';
import { HttpService } from '@brand-magic/lib-http';
import { DateTime } from 'luxon';

import { LicenseeService } from './licensee.service';
import { ICachingService } from './caching-service.interface';
import {
	ILicensee,
	ILead,
} from '@brand-magic/lib-types';

@Injectable({
	providedIn: 'root'
})
export class LeadService implements ICachingService {
	private cachingEnabled = true;
	private leads: ILead[] | null = null;

	constructor(
		private httpService: HttpService,
		private licenseeService: LicenseeService,
	) {
	}

	public async getLeads(): Promise<ILead[]> {
		if (this.leads != null && this.cachingEnabled) {
			return this.leads;
		}

		try {
			const response = await this.httpService
				.get<ILead[]>('/api/get-leads');

			if (!response.success) {
				console.log('Could not retreive leads: ' + response.helpMessage);
			}

			this.leads = response.data;
			if (this.leads != null) {
				this.leads.forEach((l: any) => {
					l.creationDate = DateTime.fromISO(l.creationDate);
				});
			}
		} catch (exception) {
			console.error(exception);
		}

		return this.leads ?? [];
	}

	public async getLeadById(leadId: string): Promise<(ILead | null)> {
		if (this.leads == null) {
			return null;
		}
		return this.leads.find(l => l.leadId === leadId) ?? null;
	}

	public clearCaches() {
		this.leads = null;
	}

	public enableCaching(isEnabled: boolean) {
		this.cachingEnabled = isEnabled;
	}
}

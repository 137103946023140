import { Injectable } from '@angular/core';
import {
	IHttpResponse,
} from '@brand-magic/lib-types';
import {
	HttpService,
} from '@brand-magic/lib-http';
import {
	ICachingService
} from './caching-service.interface';

@Injectable({
	providedIn: 'root'
})
export class AdminService implements ICachingService {
	private cachingEnabled = true;
	private statsResponse: IHttpResponse<any> | null = null;

	constructor(
		private httpService: HttpService,
	) {
	}

	public async getAdminStats(): Promise<IHttpResponse<any>> {
		if (this.statsResponse != null) {
			return this.statsResponse;
		}

		const response = await this.httpService
			.post<any, any>('/api/get-admin-stats', {});

		if (response.success) {
			this.statsResponse = response;
		} else {
			console.error(`Unable to load stats: ${ response.helpMessage }`);
		}

		return response;
	}

	public clearCaches() {
		this.statsResponse = null;
	}

	public enableCaching(enable: boolean) {
		this.cachingEnabled = enable;
	}
}

import {
	Component,
	ViewChild,
	AfterViewInit,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { DateTime } from 'luxon';
import {
	ICoupon,
	IHttpResponse,
	ILicenseePersonalisation,
} from '@brand-magic/lib-types';
import {
	StringUtil,
	TypeUtil,
} from '@brand-magic/lib-util';
import { CouponService } from '../../services/coupon.service';
import { LicenseeService } from '../../services/licensee.service';
import { PersonalisationService } from '../../services/personalisation.service';
import { AdminAddCouponDialog } from '../admin-add-coupon/admin-add-coupon.dialog';

@Component({
	selector: 'brand-magic-admin-coupon',
	templateUrl: './admin-coupon.template.html',
	styleUrls: ['./admin-coupon.style.scss'],
})
export class AdminCouponComponent implements AfterViewInit {
	private personalisation!: ILicenseePersonalisation;

	public isLoading = true;
	public availablePageSizes: number[] = [5, 10, 15, 20];
	public totalItems = 100;
	public pageSize = 10;
	public currentIndex = 0;

	public errors: string[] = [];

	public displayedColumns: string[] = [
		'couponCode', 'playerName', 'email', 'invited',
		'redeemed', 'isUnlimited', 'expiryDate', 'isLimited', 'limitedPlayMax', 'send', 'delete',
	];

	public allCoupons: ICoupon[] = [];
	public dataSource = new MatTableDataSource<ICoupon>();

	@ViewChild(MatPaginator)
	public paginator!: MatPaginator;

	constructor(
		public dialog: MatDialog,
		private couponService: CouponService,
		private personalisationService: PersonalisationService,
		private licenseeService: LicenseeService,
	) {
	}

	public ngAfterViewInit() {
		this.loadCoupons();
	}

	private async loadCoupons() {
		this.isLoading = true;

		const requests = [
			this.personalisationService.getDefaultPersonalisation(),
			this.couponService.getAdminCoupons(),
		];

		let responses;
		try {
			responses = await Promise.all(requests);
		} catch (exception: any) {
			this.errors.push('We were not able to load your coupon data: ' + exception.message);
			return;
		}

		const personalisationResponse = responses[0] as IHttpResponse<ILicenseePersonalisation>;
		if (!personalisationResponse.success) {
			this.errors.push(personalisationResponse.helpMessage as string);
		} else {
			this.personalisation = personalisationResponse.data as ILicenseePersonalisation;
		}

		this.allCoupons = responses[1] as ICoupon[];

		this.dataSource.data = this.allCoupons;
		this.dataSource.paginator = this.paginator;
		this.isLoading = false;
	}

	public isSent(coupon: ICoupon) {
		return coupon.invited != null || coupon.played != null;
	}

	public dateToString(dt: any): string {
		if (dt == null) {
			return '';
		}

		let dateTime;

		if (TypeUtil.isString(dt)) {
			dateTime = DateTime.fromISO(dt as string);
		} else if (TypeUtil.isDate(dt)) {
			dateTime = DateTime.fromJSDate(dt as Date);
		} else if (TypeUtil.isLuxon(dt)) {
			dateTime = dt;
		}

		if (dateTime == null) {
			return dt.toString();
		}

		return dateTime.toLocaleString(DateTime.DATETIME_SHORT);
	}

	public async onSend(coupon: ICoupon) {
		this.errors.length = 0;

		this.isLoading = true;
		const sendResponse = await this.couponService.emailCouponToUser(
			coupon,
			this.personalisation.settings.quizHomeUrl,
		);

		if (!sendResponse.success) {
			this.errors.push(sendResponse.helpMessage as string);
		}

		this.isLoading = false;
	}

	public onOpenAddCoupon() {
		const dialogRef = this.dialog.open(AdminAddCouponDialog, {
			width: '640px',
		});

		dialogRef.afterClosed().subscribe(result => {
			this.addCoupon(
				result.couponsToAdd,
				result.isUnlimited,
				result.expiryDate,
				result.isLimitedPlay,
				result.limitedPlayMax
			);
		});
	}

	public async addCoupon(
		couponCount: number,
		isUnlimited: boolean,
		expiryDate: Date,
		isLimitedPlay: boolean,
		limitedPlayMax: number | null,
	) {
		this.isLoading = true;
		const response = await this.couponService.addAdminCoupons(couponCount, isUnlimited, expiryDate, isLimitedPlay, limitedPlayMax);
		this.isLoading = false;

		if (!response.success) {
			console.log(`Could not load coupons: ${ response.helpMessage }`);
			return;
		}

		await this.loadCoupons();
	}

	public async onDelete(coupon: ICoupon) {
		this.isLoading = true;
		const response = await this.couponService.deleteAdminCoupon(coupon.couponId);
		this.isLoading = false;

		if (!response.success) {
			console.log(`Could not load coupons: ${ response.helpMessage }`);
			return;
		}

		this.loadCoupons();
	}
}

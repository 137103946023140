<div class="container">
	<ng-container
		*ngIf="!showSuccessMessage">
		<h1>Update your password</h1>

		<brand-magic-error-display
			[header]="errorMessage"
			[additionalInformation]=[]
			[errors]="errors">
		</brand-magic-error-display>

		<div
			class="working"
			*ngIf="isWorking">
			<mat-spinner></mat-spinner>
		</div>

		<div
			*ngIf="!isWorking">
			<brand-magic-change-password
				(request)="onRequest($event)"
				(fail)="onFail($event)"
				(cancel)="onCancel()">
			</brand-magic-change-password>
		</div>
	</ng-container>

	<ng-container
		*ngIf="showSuccessMessage">
		<h1>Password updated!</h1>

		<p>
			Your password has successfully been updated.
			Return to the login page to login and access the administration area.
		</p>

		<div class="action-buttons">
			<button
				mat-raised-button
				(click)="onCancel()">
				<mat-icon>arrow_back</mat-icon>
				Return to login
			</button>
		</div>
	</ng-container>
</div>

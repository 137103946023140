
<div class="container admin">

	<div
		*ngIf="!isLoaded"
		class="container">
		<h1>Loading personalisation content</h1>
	</div>

	<h1>Personalisation defaults</h1>

	<p>
		These values will be used to populate the initial personalisation
		values when a licensee is added to the system.
	</p>

	<ng-container
		*ngIf="isLoaded && personalisation == null">
		<h3>
			<mat-icon color="warn">warning</mat-icon>
			We could not load any personalisation defaults
		</h3>

		<p>
			This can happen if you have never updated the personalisation before,
			but usually this is due to an error getting values out of the database.
		</p>

		<p>
			If this is the first time you have used this system, you can
			generate new, blank defaults. Otherwise, this might be a bad idea.
		</p>

		<div class="action-buttons">
			<button
				mat-raised-button
				class="mat-elevation-z4"
				color="warn"
				(click)="createNewDefault()">
				<mat-icon>warning</mat-icon>
				Create empty defaults
			</button>

		</div>
	</ng-container>

	<div
		class="menu-container"
		*ngIf="isLoaded && personalisation != null">

		<div class="personalise-menu">
			<mat-selection-list [multiple]="false">
				<mat-list-option
					*ngFor="let option of availableOptions"
					[selected]="selectedOption === option"
					(click)="onMenuItemSelected(option)">
					{{ option.text }}
				</mat-list-option>
			</mat-selection-list>
		</div>

		<mat-divider vertical="true"></mat-divider>

		<div class="personalise-content">
			<div
				class="personalise-container">

				<div
					*ngIf="errors.length > 0"
					class="errors">
					<h1>We found some errors with the information you provided</h1>

					<div>
						You did not provide values for some required fields.
					</div>

					<div>
						Please double-check the form and try again.
					</div>

					<brand-magic-error-display
						[errors]="errors">
					</brand-magic-error-display>
				</div>

				<ng-container
					*ngIf="selectedOption.code === 'primary'">
					<h1>Primary colour settings</h1>

					<p>
						These are the default colours that will
						appear when new licensees create their
						account.
					</p>

					<brand-magic-personalisation-primary-edit
						*ngIf="personalisation != null"
						[personalisation]="personalisation">
					</brand-magic-personalisation-primary-edit>
				</ng-container>


				<ng-container
					*ngIf="selectedOption.code === 'accent'">
					<h1>Accent colour settings</h1>

					<p>
						These colours are used to provide contrast and highlight specific areas
						of a page.
					</p>

					<brand-magic-personalisation-accent-edit
						*ngIf="personalisation != null"
						[personalisation]="personalisation">
					</brand-magic-personalisation-accent-edit>
				</ng-container>

				<ng-container
					*ngIf="selectedOption.code === 'settings'">
					<div class="settings-container">
						<h1>Settings</h1>

						<brand-magic-personalisation-settings-edit
							[personalisation]="personalisation">
						</brand-magic-personalisation-settings-edit>
					</div>
				</ng-container>

				<ng-container
					*ngIf="selectedOption.code === 'personal-details'">
					<div class="personal-details-container">
						<h1>Personal Details page personalisation</h1>

						<brand-magic-personalisation-personaldetails-edit
							[personalisation]="personalisation">
						</brand-magic-personalisation-personaldetails-edit>
					</div>
				</ng-container>

				<ng-container
					*ngIf="selectedOption.code === 'payment'">
					<div class="payment-container">
						<h1>Payment page personalisation</h1>

						<brand-magic-personalisation-payment-edit
							[personalisation]="personalisation">
						</brand-magic-personalisation-payment-edit>
					</div>
				</ng-container>

				<ng-container
					*ngIf="selectedOption.code === 'instructions'">
					<div class="quiztips-container">
						<h1>Instructions video</h1>

						<brand-magic-personalisation-instructions-edit
							[personalisation]="personalisation">
						</brand-magic-personalisation-instructions-edit>
					</div>
				</ng-container>

				<ng-container
					*ngIf="selectedOption.code === 'personalities'">

					<div class="personalities-container">
						<div class="personalities-menu">
							<mat-selection-list [multiple]="false">
								<mat-list-option
									*ngFor="let grp of availablePersonalityControlGroups"
									[selected]="selectedPersonalityGroup === grp"
									(click)="onPersonalitySelected(grp)">
									<div class="personality-select">
										<span class="svg" [innerHTML]="getSvg(grp.personality)"></span>
										<span
											*ngIf="grp.hasErrors"
											class="warning">*</span>
										<span
											[ngClass]="{ 'warning': grp.hasErrors }">
											{{ grp.personality.name }}
										</span>
									</div>
								</mat-list-option>
							</mat-selection-list>
						</div>

						<mat-divider vertical="true"></mat-divider>

						<div class="personalities-edit">
							<h1>Brand personality content</h1>

							<h2
								*ngIf="selectedPersonalityGroup != null">
								Content for <i>The {{ selectedPersonalityGroup.personality.name }}</i>
							</h2>

							<p>
								This copy is pulled into the result emails (see email template).
								It explains to your test player (lead) in more detail what their
								Brand Personality is all about.
							</p>

							<p class="warning">
								We recommend that you do not edit the main body of copy unless you
								know the personalities extremely well.
							</p>

							<brand-magic-personalisation-emails-edit
								[personalisation]="personalisation"
								[personality]="selectedPersonalityGroup.personality">
							</brand-magic-personalisation-emails-edit>
						</div>
					</div>
				</ng-container>

				<ng-container
					*ngIf="selectedOption.code === 'quiz-results'">
					<h1>Test results page</h1>

					<p>
						This template will be the default test results text for licensees when
						they sign up to Brand Personalities.
					</p>

					<p>
						It is recommended that assume that a licensee will not edit this text
						and thus should work as-is for new licensees.
					</p>

					<dl>
						<dt>{{ "{{{ PLAYER_NAME }}}" }}</dt>
						<dd>The name of the person playing the test</dd>
						<dt>{{ "{{{ PERSONALITY }}}" }}</dt>
						<dd>The name of the selected personality</dd>
						<dt>{{ "{{{ PERSONALITY_DETAIL_LINK }}}" }}</dt>
						<dd>
							This produces a link back to the full description for the user's
							chosen personality
						</dd>
						<dt>{{ "{{{ RESULT }}}" }}</dt>
						<dd>This the content block defined in the personality content block.</dd>
						<dt>{{ "{{{ LINK_TO_QUIZ_HOME \"Link text goes here\" }}}" }}</dt>
						<dd>This produces a link back to the homepage of your test</dd>
						<dt>{{ "{{{ CTA1_BUTTON \"Button text 1\" }}}" }}</dt>
						<dd>Create a button that links to the CTA 1 URL in your personalisation</dd>
						<dt>{{ "{{{ CTA2_BUTTON \"Button text 2\" }}}" }}</dt>
						<dd>Create a button that links to the CTA 2 URL in your personalisation</dd>
					</dl>

					<brand-magic-personalisation-quizresults-edit
						[personalisation]="personalisation"
						[replacementValues]="replacementValues"
						[availablePersonalityControlGroups]="availablePersonalityControlGroups"
						[(selectedPersonalityGroup)]="selectedPersonalityGroup">
					</brand-magic-personalisation-quizresults-edit>
				</ng-container>

				<ng-container
					*ngIf="selectedOption.code === 'emails'">
					<h1>Email templates</h1>

					<p>
						This template provides the content for the test results emails that are sent to
						your leads.
					</p>

					<p class="warning">
						We strongly advise against removing the <code>{{ "{{ RESULT }}" }}</code> code,
						as it should provide the bulk of the email content pertaining to the
						selected personality.
					</p>

					<p>
						To personalise the email message, use these codes:
					</p>

					<dl>
						<dt>{{ "{{{ PLAYER_NAME }}}" }}</dt>
						<dd>The name of the person playing the test</dd>
						<dt>{{ "{{{ PERSONALITY }}}" }}</dt>
						<dd>The name of the selected personality</dd>
						<dt>{{ "{{{ PERSONALITY_DETAIL_LINK }}}" }}</dt>
						<dd>
							This produces a link back to the full description for the user's
							chosen personality
						</dd>
						<dt>{{ "{{{ RESULT }}}" }}</dt>
						<dd>This the content block defined in the personality configuration block.</dd>
						<dt>{{ "{{{ LINK_TO_QUIZ_HOME \"Link text goes here\" }}}" }}</dt>
						<dd>This produces a link back to the homepage of your test</dd>
						<dt>{{ "{{{ CTA1_BUTTON \"Button text 1\" }}}" }}</dt>
						<dd>Create a button that links to the CTA 1 URL in your personalisation</dd>
						<dt>{{ "{{{ CTA2_BUTTON \"Button text 2\" }}}" }}</dt>
						<dd>Create a button that links to the CTA 2 URL in your personalisation</dd>
					</dl>
					<brand-magic-personalisation-resultemails-edit
						[personalisation]="personalisation"
						[replacementValues]="replacementValues">
					</brand-magic-personalisation-resultemails-edit>
				</ng-container>

				<div class="action-buttons">
					<button
						mat-raised-button
						color="primary"
						(click)="onSave()">
						<mat-icon>save</mat-icon>
						Save changes
					</button>
				</div>
			</div>
		</div>
	</div>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { HttpService } from './services/http.service';

export {
	HttpService,
};

@NgModule({
	imports: [
		CommonModule,
		HttpClientModule,
	],
	exports: [
	],
	providers: [
		HttpService
	],
})
export class LibHttpModule {
}

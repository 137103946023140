import {
	Component,
	Input,
	Output,
	EventEmitter,
	AfterViewInit,
} from '@angular/core';
import {
	DomSanitizer,
	SafeHtml,
} from '@angular/platform-browser';
import {
	ILicenseePersonalisation,
	IPersonality
} from '@brand-magic/lib-types';
import { Toolbar } from '../../toolbar';
import { Hsl } from '@brand-magic/lib-util';

interface IPersonalityContentGroup {
	personality: IPersonality;
	hasErrors: boolean;
}

@Component({
	selector: 'brand-magic-personalisation-quizresults-edit',
	templateUrl: './personalisation-quizresults-edit.template.html',
	styleUrls: ['./personalisation-quizresults-edit.style.scss'],
})
export class PersonalisationQuizResultsEditComponent implements AfterViewInit {
	private heroLightnessAdj = 10;
	private stripLightnessAdj = 20;

	public htmlEditorOptions: any = Toolbar.options;

	@Input()
	public personalisation!: ILicenseePersonalisation;

	@Input()
	public replacementValues!: any;

	@Input()
	public availablePersonalityControlGroups: IPersonalityContentGroup[] = [];

	@Input()
	public selectedPersonalityGroup!: IPersonalityContentGroup;

	@Output()
	public selectedPersonalityGroupChange = new EventEmitter<IPersonalityContentGroup>();

	public useHeroShades = false;

	public buttonCss: SafeHtml | null = null;

	constructor(
		private sanitizer: DomSanitizer,
	) {
	}

	public ngAfterViewInit() {
		this.buttonCss = this.sanitizer.bypassSecurityTrustHtml(`
<style>
.cta-button {
	padding: 0.5rem 2rem;
	font-size: 1.2rem;
	font-weight: 500;
	border: 1px solid #aaaaaaaa;
	text-decoration: none;
	border-radius: 5px;
}
.cta-button:hover {
	cursor: pointer;
}

a.cta1, a.cta1:visited, a.cta1:active {
	background-color: ${this.personalisation.color.accent.backgroundColor};
	color: ${this.personalisation.color.accent.color};
}
a.cta1:hover {
	background-color: ${this.personalisation.color.accent.hoverBackgroundColor};
	color: ${this.personalisation.color.accent.hoverColor};
}

a.cta2, a.cta2:visited, a.cta2:active {
	background-color: ${this.personalisation.color.primary.backgroundColor};
	color: ${this.personalisation.color.primary.color};
}
a.cta2:hover {
	background-color: ${this.personalisation.color.primary.hoverBackgroundColor};
	color: ${this.personalisation.color.primary.hoverColor};
}
`);
	}

	public getLinkStyle() {
		return this.sanitizer.bypassSecurityTrustHtml(`<style>
	a, a:hover, a:visited {
		color: ${this.personalisation.color.primary.linkColor};
	}
</style>`);
	}

	public onSelectionChanged(changeEvt: any) {
		this.selectedPersonalityGroupChange.emit(changeEvt.value);
	}

	public getSelectedSvg() {
		return this.sanitizer.bypassSecurityTrustHtml(this.selectedPersonalityGroup.personality.svg);
	}


	private getSelectedColourHsl(): Hsl {
		const c = this.selectedPersonalityGroup.personality.colour;
		const hsl = new Hsl();
		hsl.h = c.h;
		hsl.s = c.s;
		hsl.l = c.l;

		return hsl;
	}

	public getHeroBackground() {
		if (!this.useHeroShades) {
			return '#dedede';
		}

		const hsl = this.getSelectedColourHsl();
		const bg = hsl.adjustLightness(this.heroLightnessAdj);
		return bg.toCss();
	}

	public getHeroContrast() {
		if (!this.useHeroShades) {
			return '#000';
		}

		const hsl = this.getSelectedColourHsl();
		const bg = hsl.adjustLightness(this.heroLightnessAdj);
		const fg = bg.getMaxContrast();
		return fg.toCss();
	}

	public getHeroStripBackground() {
		if (!this.useHeroShades) {
			return '#eeeeee';
		}

		const hsl = this.getSelectedColourHsl();
		const bg = hsl.adjustLightness(this.stripLightnessAdj);
		return bg.toCss();
	}

	public getHeroStripContrast() {
		if (!this.useHeroShades) {
			return '#333';
		}

		const hsl = this.getSelectedColourHsl();
		const bg = hsl.adjustLightness(this.stripLightnessAdj);
		const fg = bg.getMaxContrast();
		return fg.toCss();
	}

	public isPersonalisationBgLight() {
		const hsl = Hsl.fromHex(this.personalisation.color.primary.backgroundColor);
		return hsl.l >= 50;
	}
}

import { Component } from '@angular/core';

@Component({
	selector: 'brand-magic-subscription-upgrade-complete',
	templateUrl: './subscription-upgrade-complete.template.html',
	styleUrls: ['./subscription-upgrade-complete.style.scss'],
})
export class SubscriptionUpgradeCompleteComponent {

	constructor() {
	}

	public onReload() {
		document.location.href = '/pages/subscription';
	}
}

import { Component } from '@angular/core';
import { LicenceService } from '../../services/licence.service';
import { ILicence } from '@brand-magic/lib-types';

@Component({
	selector: 'brand-magic-admin-licence',
	templateUrl: './admin-licence.template.html',
	styleUrls: ['./admin-licence.style.scss'],
})
export class AdminLicenceComponent {
	public errors: string[] = [];
	public htmlEditorOptions: any = {
		attribution: false,
		listAdvancedTypes: false,
		height: 300,
		htmlAllowedAttrs: ['alt', 'class', 'href', 'src', 'style'],
		htmlAllowedEmptyTags: ['hr'],
		htmlAllowedTags: [
			'a', 'b', 'br', 'div', 'p', 'h1', 'h2', 'h3', 'h4',
			'pre', 'hr', 'i', 'li', 'ol', 'string', 'ul', 'u'
		],
		htmlExecuteScripts: false,
		htmlRemoveTags: ['script', 'style', 'base'],
		pastePlain: true,
		toolbarButtons: [['bold', 'italic', 'underline', '|'],['paragraphFormat', '|'],['formatUL', 'formatOL', '|'],['undo', 'redo', 'fullscreen']],
		toolbarButtonsXS: [['bold', 'italic', 'underline'],['paragraphFormat', '|'],['formatUL', 'formatOL'],['undo', 'redo', 'fullscreen']],
		toolbarButtonsSM: [['bold', 'italic', 'underline'],['paragraphFormat', '|'],['formatUL', 'formatOL'],['undo', 'redo', 'fullscreen']],
		toolbarButtonsMD: [['bold', 'italic', 'underline'],['paragraphFormat', '|'],['formatUL', 'formatOL'],['undo', 'redo', 'fullscreen']],
	}

	public isLoading = true;
	public licenceContent = '';

	constructor(
		private licenceService: LicenceService,
	) {
		this.loadLicence();
	}

	private async loadLicence() {
		this.isLoading = true;
		this.errors.length = 0;

		const response = await this.licenceService.getCurrentLicence();
		if (!response.success) {
			this.errors.push(response.helpMessage as string);
		} else {
			const licence = response.data;
			this.licenceContent = licence?.content ?? '';
		}

		this.isLoading = false;
	}

	public async onSave() {
		this.isLoading = true;
		this.errors.length = 0;

		const licence: ILicence = {
			content: this.licenceContent,
		};

		const response = await this.licenceService.updateLicence(licence);
		if (!response.success) {
			this.errors.push(response.helpMessage as string);
		}

		this.isLoading = false;
	}

}

import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import {
	ICoupon,
	IHttpResponse,
	IEmail,
	CouponStatus,
} from '@brand-magic/lib-types';
import {
	StringUtil,
	TypeUtil,
} from '@brand-magic/lib-util';
import {
	HttpService,
} from '@brand-magic/lib-http';
import {
	LicenseeService,
} from './licensee.service';
import {
	EmailService,
} from './email.service';
import {
	StripeService,
} from './stripe.service';

@Injectable({
	providedIn: 'root'
})
export class CouponService {

	constructor(
		private httpService: HttpService,
		private stripeService: StripeService,
		private licenseeService: LicenseeService,
		private emailService: EmailService,
	) {
	}

	public async getCoupons(): Promise<ICoupon[]> {
		const response = await this.httpService
			.get<ICoupon[]>('/api/get-coupons');

		if (!response.success) {
			console.error(`Unable to add coupons: ${ response.helpMessage }`);
		}

		const coupons = response.data as ICoupon[];
		if (coupons == null) {
			return [];
		}

		return coupons;
	}

	public async getAdminCoupons(): Promise<ICoupon[]> {
		const response = await this.httpService
			.get<ICoupon[]>('/api/get-admin-coupons');

		if (!response.success) {
			console.error(`Unable to add coupons: ${ response.helpMessage }`);
		}

		let coupons = response.data as ICoupon[];
		if (coupons == null) {
			return [];
		}
		coupons.forEach(c => {
			if (c.unlimitedExpiryDate != null) {
				const dtStr = c.unlimitedExpiryDate as unknown as string;
				c.unlimitedExpiryDate = DateTime.fromISO(dtStr);
			}
		});

		return coupons;
	}

	public async addCoupons(count: number) {
		const requestData = {
			count: count,
		};

		const response = await this.httpService
			.post<any, any>('/api/add-coupons', requestData);

		if (!response.success) {
			console.error(`Unable to add coupons: ${ response.helpMessage }`);
		}

		return response;
	}

	public async addAdminCoupons(count: number, isUnlimited: boolean, expiryDate: Date | null, isLimitedPlay: boolean, limitedPlayMax: number | null) {
		const requestData = {
			count: count,
			isUnlimited: isUnlimited,
			expiryDate: expiryDate?.toISOString(),
			isLimitedPlay: isLimitedPlay,
			limitedPlayMax: limitedPlayMax,
		};

		const response = await this.httpService
			.post<any, any>('/api/add-admin-coupons', requestData);

		if (!response.success) {
			console.error(`Unable to add coupons: ${ response.helpMessage }`);
		}

		return response;
	}

	public async deleteAdminCoupon(couponId: string) {
		const requestData = {
			couponId: couponId,
		};

		const response = await this.httpService
			.post<any, any>('/api/delete-admin-coupons', requestData);

		if (!response.success) {
			console.error(`Unable to delete coupons: ${ response.helpMessage }`);
		}

		return response;
	}

	public async createPaymentIntent(): Promise<IHttpResponse<string>> {
		return this.stripeService.createCouponPaymentIntent();
	}

	public async emailCouponToUser(
		coupon: ICoupon,
		quizHomeUrl: string,
	): Promise<IHttpResponse<ICoupon>> {
		const response: IHttpResponse<ICoupon> = {
			helpMessage: null,
			success: false,
			data: null
		};

		if (StringUtil.nullOrEmpty(coupon.playerName)) {
			response.helpMessage = 'Please enter the name of the player who will receive the coupon';
			return response;
		}

		if (StringUtil.nullOrEmpty(coupon.email)) {
			response.helpMessage = 'Please enter the email address of the player who will receive this coupon';
			return response;
		}

		const subject = 'You have been gifted a Brand Magic coupon!'
		const body = `Hi ${coupon.playerName},

<p>Please use this coupon code when signing up to play the Brand Personalities Quiz. It will allow you to play for free.</p>

<p>Coupon code: <b>${coupon.couponCode}</b></p>

<p>You can <a href="${quizHomeUrl}">take the test here</a>.</p>

<p>
Let the magic begin<br />
The Brand Magic team<br />
<a href="www.brandmagic.com.au"></a>
</p>
`;
		const fromAddressee = {
			name: 'Brand Magic',
			email: 'info@brandmagic.com.au',
		};
		const toAddressee = {
			name: coupon.playerName,
			email: coupon.email
		};
		const email: IEmail = {
			from: fromAddressee,
			to: [toAddressee],
			subject: subject,
			html: body
		};

		let emailResponse;
		try {
			emailResponse = await this.emailService.sendEmail(email);
			if (!response.success) {
				response.helpMessage = emailResponse.helpMessage;
			}
		} catch (exception: any) {
			response.helpMessage = 'Unfortunately we were not able to send your reference at this time: ' + exception.toString();
		}

		if (!StringUtil.nullOrEmpty(response.helpMessage)) {
			return response;
		}

		let markResponse;
		try {
			const requestData = {
				coupon: coupon,
			};

			markResponse = await this.httpService
				.post<any, any>('/api/mark-coupon-as-sent', requestData);
		} catch (exception) {
			response.helpMessage = 'Unfortunately we were not able to mark this coupon as sent';
			return response;
		}

		if (markResponse.success) {
			coupon.invited = DateTime.now();
			coupon.status = CouponStatus.Invited;
			response.data = coupon;
		} else {
			response.helpMessage = markResponse.helpMessage;
		}

		response.success = StringUtil.nullOrEmpty(response.helpMessage);

		return response;
	}

	private async markCouponAsSent(coupon: ICoupon) {
		const requestData = {
			couponId: coupon.couponId,
		};

		const response = await this.httpService
			.post<any, any>('/api/mark-coupon-as-sent', requestData);

		if (!response.success) {
			console.error(`Unable to mark coupons as sent: ${ response.helpMessage }`);
		}

		return response;
	}

}

import { Component } from '@angular/core';

@Component({
	selector: 'brand-magic-complete-account',
	templateUrl: './complete-account.template.html',
	styleUrls: ['./complete-account.style.scss'],
})
export class CompleteAccountDialog {

	constructor(
	) {
	}
}

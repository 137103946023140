import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';

import { AuthGuard } from '@brand-magic/lib-auth';
import { AdminAuthGuard } from './admin/admin-auth-guard/admin-auth.guard';

import { ShellComponent } from './shell/shell.component';
import { AuthShellComponent } from './auth-shell/auth-shell.component';
import { AdminShellComponent } from './admin-shell/admin-shell.component';
import { PageShellComponent } from './page-shell/page-shell.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './auth/login/login.component';
import { RecoverAccountComponent } from './auth/recover-account/recover-account.component';
import { UpdatePasswordComponent } from './auth/update-password/update-password.component';
import { CreateAccountComponent } from './auth/create-account/create-account.component';
import { AccountCreatedComponent } from './auth/account-created/account-created.component';

import { StripeRefreshComponent } from './stripe/stripe-refresh/stripe-refresh.component';
import { StripeReturnComponent } from './stripe/stripe-return/stripe-return.component';

import { AdminCommissionsComponent } from './admin/admin-commissions/admin-commissions.component';
import { AdminCouponComponent } from './admin/admin-coupon/admin-coupon.component';
import { AdminLicenseesComponent } from './admin/admin-licensees/admin-licensees.component';
import { AdminLicenseeEditComponent } from './admin/admin-licensee-edit/admin-licensee-edit.component';
import { AdminPersonalisationComponent } from './admin/admin-personalisation/admin-personalisation.component';
import { AdminSubscriptionComponent } from './admin/admin-subscription/admin-subscription.component';
import { AdminLicenceComponent } from './admin/admin-licence/admin-licence.component';
import { AdminQuizEditComponent } from './admin/admin-quiz-edit/admin-quiz-edit.component';

import { LeadsComponent } from './pages/leads/leads.component';
import { LeadDisplayComponent } from './pages/lead-display/lead-display.component';
import { SubscriptionComponent } from './pages/subscription/subscription.component';
import { SubscriptionUpgradeComponent } from './pages/subscription-upgrade/subscription-upgrade.component';
import { SubscriptionUpgradeCompleteComponent } from './pages/subscription-upgrade-complete/subscription-upgrade-complete.component';
import { EmbeddingCodesComponent } from './pages/embedding-codes/embedding-codes.component';
import { PersonaliseComponent } from './pages/personalise/personalise.component';
import { CouponsComponent } from './pages/coupons/coupons.component';
import { CouponsPaymentComponent } from './pages/coupons-payment/coupons-payment.component';
import { ResourcesComponent } from './pages/resources/resources.component';
import { LearningHubComponent } from './pages/learning-hub/learning-hub.component';
import { AccountDetailsComponent } from './pages/account-details/account-details.component';
import { ReferAColleagueComponent } from './pages/refer-a-colleague/refer-a-colleague.component';

const routes: Routes = [{
	path: '',
	component: ShellComponent,
	children: [{
		path: 'stripe',
		component: AuthShellComponent,
		children: [{
			path: 'return',
			component: StripeReturnComponent,
		}, {
			path: 'refresh',
			component: StripeRefreshComponent,
		}]
	}, {
		path: 'auth',
		component: AuthShellComponent,
		children: [{
			path: 'login',
			component: LoginComponent,
		}, {
			path: 'create-account',
			component: CreateAccountComponent,
		}, {
			path: 'account-created',
			component: AccountCreatedComponent,
		}, {
			path: 'recover-account',
			component: RecoverAccountComponent,
		}, {
			path: 'reset-password/:resetCode',
			component: UpdatePasswordComponent,
		}, {
			path: '',
			pathMatch: 'full',
			redirectTo: 'login'
		}]
	}, {
		path: 'admin',
		component: AdminShellComponent,
		children: [{
			path: 'commissions',
			component: AdminCommissionsComponent,
			canActivate: [AdminAuthGuard],
		}, {
			path: 'coupons',
			component: AdminCouponComponent,
			canActivate: [AdminAuthGuard],
		}, {
			path: 'quiz-edit',
			component: AdminQuizEditComponent,
			canActivate: [AdminAuthGuard],
		}, {
			path: 'licensees',
			component: AdminLicenseesComponent,
			canActivate: [AdminAuthGuard],
		}, {
			path: 'licensee/:licenseeId',
			component: AdminLicenseeEditComponent,
			canActivate: [AdminAuthGuard],
		}, {
			path: 'personalisation',
			component: AdminPersonalisationComponent,
			canActivate: [AdminAuthGuard],
		}, {
			path: 'subscriptions',
			component: AdminSubscriptionComponent,
			canActivate: [AdminAuthGuard],
		}, {
			path: 'licence',
			component: AdminLicenceComponent,
			canActivate: [AdminAuthGuard],
		}, {
			path: '',
			pathMatch: 'full',
			redirectTo: 'commissions'
		}]
	}, {
		path: 'pages',
		component: PageShellComponent,
		children: [{
			path: 'home',
			component: HomeComponent,
			canActivate: [AuthGuard],
		}, {
			path: 'leads',
			component: LeadsComponent,
			canActivate: [AuthGuard],
		}, {
			path: 'leads/:leadId',
			component: LeadDisplayComponent,
			canActivate: [AuthGuard],
		}, {
			path: 'embedding-codes',
			component: EmbeddingCodesComponent,
			canActivate: [AuthGuard]
		}, {
			path: 'personalise',
			component: PersonaliseComponent,
			canActivate: [AuthGuard],
		}, {
			path: 'coupons',
			component: CouponsComponent,
			canActivate: [AuthGuard],
		}, {
			path: 'coupons-payment',
			component: CouponsPaymentComponent,
			canActivate: [AuthGuard],
		}, {
			path: 'resources',
			component: ResourcesComponent,
			canActivate: [AuthGuard],
		}, {
			path: 'learning-hub',
			component: LearningHubComponent,
			canActivate: [AuthGuard],
		}, {
			path: 'account-details',
			component: AccountDetailsComponent,
			canActivate: [AuthGuard],
		}, {
			path: 'refer-a-colleague',
			component: ReferAColleagueComponent,
			canActivate: [AuthGuard],
		}, {
			path: 'subscription',
			component: SubscriptionComponent,
			canActivate: [AuthGuard],
		}, {
			path: 'subscription-upgrade/:subscriptionLevelId',
			component: SubscriptionUpgradeComponent,
			canActivate: [AuthGuard],
		}, {
			path: 'subscription-upgrade-complete',
			component: SubscriptionUpgradeCompleteComponent,
			canActivate: [AuthGuard],
		}, {
			path: '',
			pathMatch: 'full',
			redirectTo: 'home'
		}]
	}, {
		path: '',
		pathMatch: 'full',
		redirectTo: '/pages/home'
	}]
}];


@NgModule({
	imports: [
		// RouterModule.forRoot(routes, extraOptions),
		RouterModule.forRoot(routes),
	],
	declarations: [
	],
	exports: [
		RouterModule,
	]
})
export class AppRoutingModule {
}

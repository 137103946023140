import {
	Component,
	Output,
	EventEmitter,
} from '@angular/core';
import {
	FormControl,
} from '@angular/forms';
import {
	StringUtil
} from '@brand-magic/lib-util';
import {
	LoginRequestEvent
} from './login-request.event';
import {
	LoginFailureEvent
} from './login-failure.event';

@Component({
	selector: 'brand-magic-auth-login',
	templateUrl: './login.template.html',
	styleUrls: ['./login.style.scss'],
})
export class AuthLoginComponent {
	public emailAddressControl = new FormControl('');
	public passwordControl = new FormControl('');

	@Output()
	public loginRequest: EventEmitter<LoginRequestEvent> = new EventEmitter();

	@Output()
	public signupRequest = new EventEmitter();

	@Output()
	public loginFailure: EventEmitter<LoginFailureEvent> = new EventEmitter();

	@Output()
	public cancel = new EventEmitter();

	public onCancel() {
		this.cancel.emit();
	}

	public onLogin() {
		const helpMessages = []

		const emailAddress = this.emailAddressControl.value as string;
		const password = this.passwordControl.value as string;
		if (StringUtil.nullOrEmpty(emailAddress)) {
			helpMessages.push('Please enter your email address into the \'Email Address\' form field');
		}

		if (StringUtil.nullOrEmpty(password)) {
			helpMessages.push('Please enter your password into the \'Password\' form field');
		}

		if (helpMessages.length > 0) {
			const evt = new LoginFailureEvent(helpMessages);
			this.loginFailure.emit(evt);
			return;
		}

		// Submit
		const evt = new LoginRequestEvent(emailAddress, password);
		this.loginRequest.emit(evt);
	}

	public onSignup() {
		this.signupRequest.emit();
	}
}

import { Component } from '@angular/core';

@Component({
	selector: 'brand-magic-learning-hub',
	templateUrl: './learning-hub.template.html',
	styleUrls: ['./learning-hub.style.scss'],
})
export class LearningHubComponent {

	constructor() {
	}

}

import {
	Component,
	ViewChild,
	AfterViewInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { DateTime } from 'luxon';
import {
	ICoupon,
	IHttpResponse,
	ILicensee,
	ILicenseePersonalisation,
} from '@brand-magic/lib-types';
import { TypeUtil } from '@brand-magic/lib-util';
import { CouponService } from '../../services/coupon.service';
import { LicenseeService } from '../../services/licensee.service';
import { EmailService } from '../../services/email.service';
import { PersonalisationService } from '../../services/personalisation.service';

@Component({
	selector: 'brand-magic-coupons',
	templateUrl: './coupons.template.html',
	styleUrls: ['./coupons.style.scss'],
})
export class CouponsComponent implements AfterViewInit {
	private currentLicensee!: ILicensee;
	private personalisation!: ILicenseePersonalisation;

	public errors: string[] = [];

	public availablePageSizes: number[] = [5, 10, 15, 20];
	public totalItems = 100;
	public pageSize = 10;
	public currentIndex = 0;

	public displayedColumns: string[] = [
		'couponCode', 'playerName', 'email',
		'invited', 'redeemed', 'action'
	];

	public allCoupons: ICoupon[] = [];
	public dataSource = new MatTableDataSource<ICoupon>();

	@ViewChild(MatPaginator)
	private paginator!: MatPaginator;

	public isPaid = false;
	public isLoading = true;

	constructor(
		private router: Router,
		private couponService: CouponService,
		private personalisationService: PersonalisationService,
		private licenseeService: LicenseeService,
	) {
	}

	public ngAfterViewInit() {
		this.loadCoupons();
	}

	private async loadCoupons() {
		this.isLoading = true;

		const requests = [
			this.licenseeService.getCurrentLicensee(),
			this.licenseeService.isPaidSubscriber(),
			this.personalisationService.getCurrentPersonalisation(),
			this.couponService.getCoupons(),
		];

		let responses;
		try {
			responses = await Promise.all(requests);
		} catch (exception: any) {
			this.errors.push('We were not able to load your coupon data: ' + exception.message);
			return;
		}

		const licenseeResponse = responses[0] as IHttpResponse<ILicensee>;
		if (!licenseeResponse.success) {
			this.errors.push(licenseeResponse.helpMessage as string);
		} else {
			this.currentLicensee = licenseeResponse.data as ILicensee;
		}

		const isPaidResponse = responses[1] as IHttpResponse<boolean>;
		if (!isPaidResponse.success) {
			this.errors.push(isPaidResponse.helpMessage as string);
		} else {
			this.isPaid = isPaidResponse.data as boolean;
		}

		const personalisationResponse = responses[2] as IHttpResponse<ILicenseePersonalisation>;
		if (!personalisationResponse.success) {
			this.errors.push(personalisationResponse.helpMessage as string);
		} else {
			this.personalisation = personalisationResponse.data as ILicenseePersonalisation;
		}

		this.allCoupons = responses[3] as ICoupon[];

		this.dataSource.data = this.allCoupons;
		this.dataSource.paginator = this.paginator;
		this.isLoading = false;
	}

	public isSent(coupon: ICoupon) {
		return coupon.invited != null || coupon.played != null;
	}

	public dateToString(dt: any): string {
		if (dt == null) {
			return '';
		}

		let dateTime;

		if (TypeUtil.isString(dt)) {
			dateTime = DateTime.fromISO(dt as string);
		} else if (TypeUtil.isDate(dt)) {
			dateTime = DateTime.fromJSDate(dt as Date);
		} else if (TypeUtil.isLuxon(dt)) {
			dateTime = dt;
		}

		if (dateTime == null) {
			return dt.toString();
		}

		return dateTime.toLocaleString(DateTime.DATETIME_SHORT);
	}

	public async onSend(coupon: ICoupon) {
		this.errors.length = 0;

		this.isLoading = true;
		const sendResponse = await this.couponService.emailCouponToUser(
			coupon,
			this.personalisation.settings.quizHomeUrl,
		);

		if (!sendResponse.success) {
			this.errors.push(sendResponse.helpMessage as string);
		}

		this.isLoading = false;
	}

	public async onPurchaseCuponsClicked() {
		this.router.navigateByUrl('/pages/coupons-payment');
	}

}

<mat-card>
	<mat-card-content>
		<div class="color-container">
			<mat-form-field appearance="fill">
				<mat-label>Background colour</mat-label>
				<input
					matInput
					[(ngModel)]="personalisation.color.primary.backgroundColor"
					[mtxColorpicker]="backgroundColorPicker"
					format="hex">
				<mtx-colorpicker-toggle matSuffix [for]="backgroundColorPicker"></mtx-colorpicker-toggle>
				<mtx-colorpicker #backgroundColorPicker></mtx-colorpicker>
				<mat-hint>Page background colour</mat-hint>
				<mat-error>Background color is <strong>required</strong></mat-error>
			</mat-form-field>

			<mat-form-field appearance="fill">
				<mat-label>Font colour</mat-label>
				<input
					matInput
					[(ngModel)]="personalisation.color.primary.color"
					[mtxColorpicker]="colorPicker"
					format="hex">
				<mtx-colorpicker-toggle matSuffix [for]="colorPicker"></mtx-colorpicker-toggle>
				<mtx-colorpicker #colorPicker></mtx-colorpicker>
				<mat-hint>Main text color</mat-hint>
				<mat-error>Font color is <strong>required</strong></mat-error>
			</mat-form-field>

			<mat-form-field appearance="fill">
				<mat-label>Link colour</mat-label>
				<input
					matInput
					[(ngModel)]="personalisation.color.primary.linkColor"
					[mtxColorpicker]="linkColorPicker"
					format="hex">
				<mtx-colorpicker-toggle matSuffix [for]="linkColorPicker"></mtx-colorpicker-toggle>
				<mtx-colorpicker #linkColorPicker></mtx-colorpicker>
				<mat-hint>Hyperlink colour</mat-hint>
				<mat-error>Link color is <strong>required</strong></mat-error>
			</mat-form-field>

			<mat-form-field appearance="fill">
				<mat-label>Hover background colour</mat-label>
				<input
					matInput
					[(ngModel)]="personalisation.color.primary.hoverBackgroundColor"
					[mtxColorpicker]="hoverBackgroundColorPicker"
					format="hex">
				<mtx-colorpicker-toggle matSuffix [for]="hoverBackgroundColorPicker"></mtx-colorpicker-toggle>
				<mtx-colorpicker #hoverBackgroundColorPicker></mtx-colorpicker>
				<mat-hint>Colour for button backgrounds when the mouse is over the button</mat-hint>
				<mat-error>Hover background color is <strong>required</strong></mat-error>
			</mat-form-field>

			<mat-form-field appearance="fill">
				<mat-label>Hover text colour</mat-label>
				<input
					matInput
					[(ngModel)]="personalisation.color.primary.hoverColor"
					[mtxColorpicker]="hoverColorPicker"
					format="hex">
				<mtx-colorpicker-toggle matSuffix [for]="hoverColorPicker"></mtx-colorpicker-toggle>
				<mtx-colorpicker #hoverColorPicker></mtx-colorpicker>
				<mat-hint>Button text color when mouse is hovering over the button</mat-hint>
				<mat-error>Hover text color is <strong>required</strong></mat-error>
			</mat-form-field>
		</div>
	</mat-card-content>
</mat-card>

<mat-divider class="demonstration-separator"></mat-divider>

<h2>Preview of current colour selections</h2>

<p>Move your mouse over some options to preview thier interactions</p>

<div class="color-preview-container" [ngStyle]="getPageStyle()">

	<div class="question">
		Your internal brand culture focuses on being . . .
	</div>

	<div class="answer-container">
		<div
			class="answer"
			(mouseover)="onButtonOver($event)"
			(mouseout)="onButtonOut($event)">
			Highly supportive and nurturing
		</div>
		<div
			class="answer"
			(mouseover)="onButtonOver($event)"
			(mouseout)="onButtonOut($event)">
			Innovative and collaborative
		</div>
		<div
			class="answer"
			(mouseover)="onButtonOver($event)"
			(mouseout)="onButtonOut($event)">
			Playful and fun
		</div>
		<div
			class="answer"
			(mouseover)="onButtonOver($event)"
			(mouseout)="onButtonOut($event)">
			Independent and self-directed
		</div>
		<div
			class="answer"
			(mouseover)="onButtonOver($event)"
			(mouseout)="onButtonOut($event)">
			Goal focused and achievement orientated
		</div>
		<div
			class="answer"
			(mouseover)="onButtonOver($event)"
			(mouseout)="onButtonOut($event)">
			Open, honest and positive encouragement
		</div>
		<div
			class="answer"
			(mouseover)="onButtonOver($event)"
			(mouseout)="onButtonOut($event)">
			Visionary thinking, inspiring actions and empowering motivators
		</div>
		<div
			class="answer"
			(mouseover)="onButtonOver($event)"
			(mouseout)="onButtonOut($event)">
			No hierarchy, everyone chips in to help each other
		</div>
	</div>

	<div class="link-preview">
		<a href="" [attr.style]="'color:' + personalisation.color.primary.linkColor" onclick="return false;">This is what a link will look like</a> using your colours.
	</div>
</div>

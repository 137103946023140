<mat-card>
	<mat-card-content>
		<div class="accent-container">
			<mat-form-field appearance="fill">
				<mat-label>Accent background colour</mat-label>
				<input
					matInput
					[(ngModel)]="personalisation.color.accent.backgroundColor"
					[mtxColorpicker]="accentBackgroundColorPicker"
					format="hex">
				<mtx-colorpicker-toggle matSuffix [for]="accentBackgroundColorPicker"></mtx-colorpicker-toggle>
				<mtx-colorpicker #accentBackgroundColorPicker></mtx-colorpicker>
				<mat-hint>Background colour for accent pages</mat-hint>
				<mat-error>Accent background color is <strong>required</strong></mat-error>
			</mat-form-field>

			<mat-form-field appearance="fill">
				<mat-label>Accent text colour</mat-label>
				<input
					matInput
					[(ngModel)]="personalisation.color.accent.color"
					[mtxColorpicker]="accentColorPicker"
					format="hex">
				<mtx-colorpicker-toggle matSuffix [for]="accentColorPicker"></mtx-colorpicker-toggle>
				<mtx-colorpicker #accentColorPicker></mtx-colorpicker>
				<mat-hint>Accent text color</mat-hint>
				<mat-error>Accent text color is <strong>required</strong></mat-error>
			</mat-form-field>

			<mat-form-field appearance="fill">
				<mat-label>Accent link colour</mat-label>
				<input
					matInput
					[(ngModel)]="personalisation.color.accent.linkColor"
					[mtxColorpicker]="accentLinkColorPicker"
					format="hex">
				<mtx-colorpicker-toggle matSuffix [for]="accentLinkColorPicker"></mtx-colorpicker-toggle>
				<mtx-colorpicker #accentLinkColorPicker></mtx-colorpicker>
				<mat-hint>Accent hypelink colour</mat-hint>
				<mat-error>Accent link color is <strong>required</strong></mat-error>
			</mat-form-field>

			<mat-form-field appearance="fill">
				<mat-label>Accent hover background colour</mat-label>
				<input
					matInput
					[(ngModel)]="personalisation.color.accent.hoverBackgroundColor"
					[mtxColorpicker]="accentHoverBackgroundColorPicker"
					format="hex">
				<mtx-colorpicker-toggle matSuffix [for]="accentHoverBackgroundColorPicker"></mtx-colorpicker-toggle>
				<mtx-colorpicker #accentHoverBackgroundColorPicker></mtx-colorpicker>
				<mat-hint>Accent hover colour for button backgrounds</mat-hint>
				<mat-error>Accent hover background color is <strong>required</strong></mat-error>
			</mat-form-field>

			<mat-form-field appearance="fill">
				<mat-label>Accent hover text colour</mat-label>
				<input
					matInput
					[(ngModel)]="personalisation.color.accent.hoverColor"
					[mtxColorpicker]="accentHoverColorPicker"
					format="hex">
				<mtx-colorpicker-toggle matSuffix [for]="accentHoverColorPicker"></mtx-colorpicker-toggle>
				<mtx-colorpicker #accentHoverColorPicker></mtx-colorpicker>
				<mat-hint>Accent hover button text color when mouse is hovering over the button</mat-hint>
				<mat-error>Accent hover text color is <strong>required</strong></mat-error>
			</mat-form-field>
		</div>
	</mat-card-content>
</mat-card>

<mat-divider class="demonstration-separator"></mat-divider>

<h2>Preview of current accent colours</h2>

<div class="accent-preview-container" [ngStyle]="getAccentStyle()">
	<div class="accent-text">
		Before submitting your final answers, are you happy with your selections?
		Feel free to go back and review them if you choose.
		Remember you can play the test twice.
		I’m happy with my answers.
	</div>

	<div
		class="accent-button"
		(mouseover)="onAccentOver($event)"
		(mouseout)="onAccentOut($event)">
		Submit
	</div>
</div>

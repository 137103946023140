<div class="container">
	<h1>Congratulations! Your subscription has been updated!</h1>

	<p>
		<mat-icon>warning</mat-icon>
		There can be a delay between making the payment and our system
		being notified by Stripe that the payment has completed. Until
		we receive that confirmation, your profile will not be updated
		to the new subscription level.
	</p>

	<p>
		The easiest way to check your current status is to re-check your
		current subscription level by clicking the button below:
	</p>

	<div>
		<button
			color="primary"
			mat-button
			(click)="onReload()">
			<mat-icon>refresh</mat-icon>
			View current subscription
		</button>
	</div>
</div>

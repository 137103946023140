import { Component } from '@angular/core';

@Component({
	selector: 'brand-magic-support-video-menu',
	templateUrl: './support-video-menu.template.html',
	styleUrls: ['./support-video-menu.style.scss'],
})
export class SupportVideoMenuComponent {

	constructor() {
	}

	public openLink(link: string) {
		window.open(link, '_blank');
	}

}

<mat-sidenav-container
	class="sidenav-container">
	<mat-sidenav
		#drawer
		class="sidenav"
		[attr.role]="atLeast700 ? 'navigation' : 'dialog'"
		[mode]="atLeast700 ? 'side' : 'over'"
		[opened]="menuService.isOpenned">

		<h3 class="header">Administration</h3>
		<mat-nav-list
			class="admin-list">
			<a
				mat-list-item
				routerLink="/admin/commissions"
				routerLinkActive="active">
				Overview
			</a>
			<mat-divider></mat-divider>
			<a
				mat-list-item
				routerLink="/admin/coupons"
				routerLinkActive="active">
				Coupons
			</a>
			<mat-divider></mat-divider>
			<a
				mat-list-item
				routerLink="/admin/licensees"
				routerLinkActive="active">
				Licensees
			</a>
			<mat-divider></mat-divider>
			<a
				mat-list-item
				routerLink="/admin/personalisation"
				routerLinkActive="active">
				Personalisation
			</a>
			<mat-divider></mat-divider>
			<a
				mat-list-item
				routerLink="/admin/subscriptions"
				routerLinkActive="active">
				Subscription
			</a>
			<mat-divider></mat-divider>
			<a
				mat-list-item
				routerLink="/admin/licence"
				routerLinkActive="active">
				End-User Licence
			</a>
			<mat-divider></mat-divider>
			<a
				mat-list-item
				routerLink="/admin/quiz-edit"
				routerLinkActive="active">
				Test admin
			</a>
			<mat-divider></mat-divider>
		</mat-nav-list>
	</mat-sidenav>

	<mat-sidenav-content>
		<div class="content">
			<router-outlet></router-outlet>
		</div>
	</mat-sidenav-content>

</mat-sidenav-container>

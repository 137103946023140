<mat-sidenav-container
	class="sidenav-container">
	<mat-sidenav
		#drawer
		class="sidenav"
		[attr.role]="atLeast700 ? 'navigation' : 'dialog'"
		[mode]="atLeast700 ? 'side' : 'over'"
		[opened]="menuService.isOpenned">

		<ng-container
			*ngIf="isAdmin()">
			<h3 class="header">Administration</h3>
			<mat-nav-list
				class="admin-list">
				<a
					mat-list-item
					routerLink="/admin/commissions"
					routerLinkActive="active">
					Overview
				</a>
				<mat-divider></mat-divider>
				<a
					mat-list-item
					routerLink="/admin/coupons"
					routerLinkActive="active">
					Coupons
				</a>
				<mat-divider></mat-divider>
				<a
					mat-list-item
					routerLink="/admin/licensees"
					routerLinkActive="active">
					Licensees
				</a>
				<mat-divider></mat-divider>
				<a
					mat-list-item
					routerLink="/admin/personalisation"
					routerLinkActive="active">
					Personalisation
				</a>
				<mat-divider></mat-divider>
				<a
					mat-list-item
					routerLink="/admin/subscriptions"
					routerLinkActive="active">
					Subscription
				</a>
				<mat-divider></mat-divider>
				<a
					mat-list-item
					routerLink="/admin/licence"
					routerLinkActive="active">
					End-User Licence
				</a>
				<mat-divider></mat-divider>
				<a
					mat-list-item
					routerLink="/admin/quiz-edit"
					routerLinkActive="active">
					Test admin
				</a>
				<mat-divider></mat-divider>
			</mat-nav-list>
		</ng-container>

		<mat-nav-list
			*ngIf="!isAdmin()">
			<h3 class="header">Brand Magic</h3>
			<a
				mat-list-item
				routerLink="/pages/home"
				routerLinkActive="active">
				Dashboard
			</a>
			<mat-divider></mat-divider>
			<a
				mat-list-item
				routerLink="/pages/subscription"
				routerLinkActive="active">
				Subscription
			</a>
			<mat-divider></mat-divider>
			<a
				mat-list-item
				routerLink="/pages/leads"
				routerLinkActive="active">
				Leads
			</a>
			<mat-divider></mat-divider>
			<a
				mat-list-item
				routerLink="/pages/embedding-codes"
				routerLinkActive="active">
				Embedding codes
			</a>
			<mat-divider></mat-divider>
			<a
				mat-list-item
				routerLink="/pages/personalise"
				routerLinkActive="active">
				Personalise
			</a>
			<mat-divider></mat-divider>
			<a
				mat-list-item
				routerLink="/pages/coupons"
				routerLinkActive="active">
				Coupons
			</a>
			<mat-divider></mat-divider>
			<a
				mat-list-item
				routerLink="/pages/resources"
				routerLinkActive="active">
				Resources
			</a>
			<mat-divider></mat-divider>
			<a
				mat-list-item
				routerLink="/pages/account-details"
				routerLinkActive="active">
				Account details
			</a>
			<mat-divider></mat-divider>
			<a
				mat-list-item
				routerLink="/pages/refer-a-colleague"
				routerLinkActive="active">
				Refer a colleague
			</a>

			<mat-divider
				*ngIf="!atLeast900">
			</mat-divider>
			<a
				*ngIf="!atLeast900"
				mat-list-item
				href="http://facebook.com/brandmagic101"
				target="_new">
				Join us on Facebook
			</a>
			<mat-divider
				*ngIf="!atLeast900">
			</mat-divider>

			<brand-magic-support-video-menu
				*ngIf="!atLeast700">
			</brand-magic-support-video-menu>
		</mat-nav-list>
	</mat-sidenav>

	<mat-sidenav-content>
		<div class="content">
			<router-outlet></router-outlet>
		</div>
	</mat-sidenav-content>

</mat-sidenav-container>

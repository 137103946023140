<div class="personal-details-container">
	<mat-form-field class="editor" class="editor">
		<mat-label>Description</mat-label>
		<textarea
			[(ngModel)]="personalisation.pages.personalDetails.description"
			[froalaEditor]="htmlEditorOptions"
			matInput>{{ personalisation.pages.personalDetails.description }}</textarea>
		<mat-error>Description body is <strong>required</strong></mat-error>
	</mat-form-field>
</div>

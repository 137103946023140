import { Injectable } from '@angular/core';
import {
	ISubscriptionLevel,
	IHttpResponse,
	ILicensee,
} from '@brand-magic/lib-types';
import {
	HttpService,
} from '@brand-magic/lib-http';
import { ICachingService } from './caching-service.interface';

@Injectable({
	providedIn: 'root'
})
export class SubscriptionService implements ICachingService {
	private cachingEnabled = true;
	private subscriptionLevels: ISubscriptionLevel[] | null = null;

	constructor(
		private httpService: HttpService,
	) {
	}

	public async getDefaultSubscriptionLevel(): Promise<IHttpResponse<ISubscriptionLevel>> {
		const response: IHttpResponse<ISubscriptionLevel> = {
			success: false,
			helpMessage: null,
			data: null
		};

		const levelsResponse = await this.getSubscriptionLevels();
		if (!levelsResponse.success) {
			response.helpMessage = levelsResponse.helpMessage;
			return response;
		}

		const levels = levelsResponse.data as ISubscriptionLevel[];
		response.data = levels[0];
		response.success = true;

		return response;
	}

	public async getSubscriptionLevels(): Promise<IHttpResponse<ISubscriptionLevel[]>> {
		if (this.subscriptionLevels != null && this.cachingEnabled) {
			return {
				success: true,
				helpMessage: null,
				data: this.subscriptionLevels,
			};
		}

		const response = await this.httpService
			.get<ISubscriptionLevel[]>('/api/get-subscriptions');

		if (!response.success) {
			console.error(`Unable to retrieve subscription levels: ${ response.helpMessage }`);
		}

		this.subscriptionLevels = response.data;

		return response;
	}

	public async getSubscriptionLevelById(subscriptionLevelId: string): Promise<IHttpResponse<ISubscriptionLevel>> {
		const response = await this.getSubscriptionLevels();
		if (!response.success) {
			return response as unknown as IHttpResponse<ISubscriptionLevel>;
		}

		const levels = response.data as ISubscriptionLevel[];
		const level = levels.find(l => l.subscriptionLevelId === subscriptionLevelId);

		return {
			success: (level != null),
			helpMessage: (level == null) ? 'We could not find the provided subscription level' : null,
			data: level as ISubscriptionLevel
		};
 }

	public async insertSubscriptionLevel(subscriptionLevel: ISubscriptionLevel): Promise<IHttpResponse<string>> {
		const requestData = {
			subscriptionLevel: subscriptionLevel,
		};

		const response = await this.httpService
			.post<any, string>('/api/insert-subscription-level', requestData);

		if (!response.success) {
			console.error(`Unable to insert subscription level: ${ response.helpMessage }`);
		}

		return response;
	}

	public async updateSubscriptionLevel(subscriptionLevel: ISubscriptionLevel): Promise<IHttpResponse<boolean>> {

		const updateData: any = {...subscriptionLevel};
		delete updateData.subscriptionLevelId;

		const requestData = {
			subscriptionLevelId: subscriptionLevel.subscriptionLevelId,
			updateData: subscriptionLevel
		};

		const response = await this.httpService
			.post<any, boolean>('/api/update-subscription-level', requestData);

		if (!response.success) {
			console.error(`Unable to update subscription level: ${ response.helpMessage }`);
		}

		return response;
	}

	public clearCaches() {
		this.subscriptionLevels = null;
	}

	public enableCaching(isEnabled: boolean) {
		this.cachingEnabled = isEnabled;
	}
}

import {
	Component,
	OnInit,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {
	Router,
	ActivatedRoute,
	ParamMap,
} from '@angular/router';
import { LicenseeService } from '../../services/licensee.service';
import { LeadService } from '../../services/lead.service';
import { QuizService } from '../../services/quiz.service';
import { DateTime } from 'luxon';
import type {
	ILead,
	IPersonality,
	IAnnotatedQuizResults,
	IHttpResponse,
} from '@brand-magic/lib-types';
import { TypeUtil } from '@brand-magic/lib-util';

@Component({
	selector: 'brand-magic-lead-display',
	templateUrl: './lead-display.template.html',
	styleUrls: ['./lead-display.style.scss'],
})
export class LeadDisplayComponent implements OnInit {

	public lead: ILead | null = null;
	private personalityCache: IPersonality[] = [];
	public quizLoaded = false;
	public quizResults: IAnnotatedQuizResults[] | null = null;

	public errors: string[] = [];
	public isPaid = false;

	constructor(
		private sanitizer: DomSanitizer,
		private router: Router,
		private route: ActivatedRoute,
		private licenseeService: LicenseeService,
		private leadService: LeadService,
		private quizService: QuizService,
	) {
		this.licenseeService.isPaidSubscriber().then(
			(response: IHttpResponse<boolean>) => {
				if (!response.success) {
					this.errors.push(response.helpMessage as string);
				}
				this.isPaid = response.data as boolean;
			}
		);
	}

	public ngOnInit() {
		let rt = this.route;
		if (this.route.children.length > 0) {
			rt = this.route.children[0];
		}

		rt.paramMap.subscribe(async (params: ParamMap) => {
			const leadId = params.get('leadId');
			if (leadId == null || leadId.length === 0) {
				this.router.navigateByUrl('/pages/home');
				return;
			}

			this.loadLeadById(leadId);
		});
	}

	private async loadLeadById(leadId: string) {
		this.leadService.getLeadById(leadId).then((lead: ILead | null) => {
			if (lead == null) {
				return;
			}
			this.lead = lead;
			this.quizService.getAnnotatedQuizResults(lead.leadId).then((results: IAnnotatedQuizResults[] | null) => {
				this.quizLoaded = true;
				this.quizResults = results;
			});
		});
	}

	private getPersonality(personalityId: string): IPersonality | null {
		return this.personalityCache.find(p => p.personalityId === personalityId) ?? null;
	}

	public hasPersonality(personalityId: string) {
		const personality = this.getPersonality(personalityId);
		return personality != null;
	}

	public getPersonalityName(personalityId: string) {
		const personality = this.getPersonality(personalityId);
		if (personality == null) {
			return '';
		}
		return personality.name;
	}

	public getPersonalitySvg(personalityId: string) {
		const personality = this.getPersonality(personalityId);
		if (personality == null) {
			return '';
		}
		return this.sanitizer.bypassSecurityTrustHtml(personality.svg);
	}

	public getDatePlayed(dt: DateTime) {
		if (TypeUtil.isString(dt)) {
			dt = DateTime.fromISO(dt as unknown as string);
		}
		return dt.toLocaleString(DateTime.DATETIME_SHORT);
	}

}

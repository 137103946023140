import {
	Component,
	Input,
} from '@angular/core';
import { Toolbar } from '../../toolbar';
import {
	ILicenseePersonalisation,
} from '@brand-magic/lib-types';
import {
	StringUtil,
} from '@brand-magic/lib-util';

@Component({
	selector: 'brand-magic-personalisation-instructions-edit',
	templateUrl: './personalisation-instructions-edit.template.html',
	styleUrls: ['./personalisation-instructions-edit.style.scss'],
})
export class PersonalisationInstructionsEditComponent {
	public htmlEditorOptions: any = Toolbar.options;

	@Input()
	public personalisation!: ILicenseePersonalisation;

	constructor(
	) {
	}

	public onIdBlur() {
		const existing: string | null = this.personalisation.pages.instructions.youtubeVideoId;
		if (!StringUtil.nullOrEmpty(existing) && existing.length === 11) {
			// valid ids are 11 chars in length
			return;
		}

		try {
			const url = new URL(existing);
			const params = url.searchParams;
			const vidId = params.get('v');
			if (!StringUtil.nullOrEmpty(vidId)) {
				this.personalisation.pages.instructions.youtubeVideoId = vidId as string;
			}
		} catch (exception) {
			console.error('Unable to parse url: ' + existing);
		}
	}

}

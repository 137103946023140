<div class="container admin">

	<h1>Licence admin</h1>

	<div
		*ngIf="isLoading"
		class="loading">
		<mat-spinner></mat-spinner>
	</div>

	<div
		*ngIf="errors.length > 0"
		class="errors">
		<h1>We encountered an error trying to fulfil your request</h1>

		<ul class="error-list">
			<li
				*ngFor="let error of errors">
				{{ error }}
			</li>
		</ul>
	</div>

	<div
		class="licence-content"
		*ngIf="!isLoading">
		<mat-form-field>
			<mat-label>Licence</mat-label>
			<textarea
				class="licence-text"
				matInput
				[froalaEditor]="htmlEditorOptions"
				[(ngModel)]="licenceContent">{{ licenceContent }}</textarea>
		</mat-form-field>

		<div class="action-buttons">
			<button
				mat-raised-button
				color="primary"
				(click)="onSave()">
				<mat-icon>save</mat-icon>
				Save changes
			</button>
		</div>
	</div>
</div>

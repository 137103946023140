<button
	mat-list-item
	[matMenuTriggerFor]="videoMenu">
	Support videos
</button>
<mat-menu #videoMenu="matMenu">
	<button
		mat-menu-item
		(click)="openLink('https://youtu.be/W8oYYkoG99U')">
		Dashboard
	</button>
	<button
		mat-menu-item
		(click)="openLink('https://youtu.be/wRlH5GoupS4')">
		Subscription
	</button>
	<button
		mat-menu-item
		(click)="openLink('https://youtu.be/kXeEU9xofts')">
		Leads
	</button>
	<button
		mat-menu-item
		[matMenuTriggerFor]="embeddingMenu">
		Embedding codes
	</button>
	<button
		mat-menu-item
		[matMenuTriggerFor]="personalisationMenu">
		Personalisation
	</button>
	<button
		mat-menu-item
		(click)="openLink('https://youtu.be/Nxk-KZHk2bs')">
		Coupons
	</button>
	<button
		mat-menu-item
		(click)="openLink('https://youtu.be/s88gZiOYnNs')">
		Resources
	</button>
	<button
		mat-menu-item
		(click)="openLink('https://youtu.be/Iq6eNky_n6I')">
		Account
	</button>
	<button
		mat-menu-item
		(click)="openLink('https://youtu.be/WjCf0Mnm2s4')">
		Referral
	</button>
</mat-menu>
<mat-menu #embeddingMenu="matMenu">
	<button
		mat-menu-item
		(click)="openLink('https://youtu.be/43u9H1PQeTU')">
		Test Code
	</button>
	<button
		mat-menu-item
		(click)="openLink('https://youtu.be/I_tCg5Cwb-Y')">
		Personality Code
	</button>
</mat-menu>
<mat-menu #personalisationMenu="matMenu">
	<button
		mat-menu-item
		(click)="openLink('https://youtu.be/SXXL-3pEka4')">
		Primary Colours
	</button>
	<button
		mat-menu-item
		(click)="openLink('https://youtu.be/Riap5nj2eQE')">
		Accent Colours
	</button>
	<button
		mat-menu-item
		(click)="openLink('https://youtu.be/Riap5nj2eQE')">
		Settings
	</button>
	<button
		mat-menu-item
		(click)="openLink('https://youtu.be/C9Vnv4s5SvE')">
		Personalities
	</button>
	<button
		mat-menu-item
		(click)="openLink('https://youtu.be/NTrTQtC8yXY')">
		Personal Details
	</button>
	<button
		mat-menu-item
		(click)="openLink('https://youtu.be/UjiwBcPgB4M')">
		Payment Page
	</button>
	<button
		mat-menu-item
		(click)="openLink('https://youtu.be/wuQ7CBWkE5M')">
		Instructions
	</button>
	<button
		mat-menu-item
		(click)="openLink('https://youtu.be/Vm5EUk0ToZY')">
		Results Page
	</button>
	<button
		mat-menu-item
		(click)="openLink('https://youtu.be/BmRhrPRBQD0')">
		Results Page
	</button>
</mat-menu>

<div class="container">
	<brand-magic-error-display
		[header]="errorMessage"
		[additionalInformation]="errorDescription"
		[errors]="errors">
	</brand-magic-error-display>

	<h1>Create a Brand Magic account</h1>

	<div
		*ngIf="isWorking">
		<h3>We are creating your account</h3>

		<div
			class="loading">
			<mat-spinner></mat-spinner>
		</div>

		<p>
			Please wait for a moment while we set up your account.
		</p>
	</div>

	<div
		*ngIf="!isWorking">

		<p>
			Great to see you here. Creating an account is free. This will allow
			you to try this amazing tool before you buy.
		</p>

		<p>
			We look forward to welcoming you to our creative community.
		</p>

		<brand-magic-licensee-edit
			[licensee]="licensee"
			(save)="onSave($event)">
		</brand-magic-licensee-edit>
	</div>
</div>

import { Component } from '@angular/core';

@Component({
	selector: 'brand-magic-upgrade-subscription',
	templateUrl: './upgrade-subscription.template.html',
	styleUrls: ['./upgrade-subscription.style.scss'],
})
export class UpgradeSubscriptionDialog {

	constructor(
	) {
	}

}

<div class="container">
	<ng-container
		*ngIf="!showSuccessMessage">
		<h1>Recover your account</h1>

		<p>
			Did you forget your password? We can email you a link to reset your password.
		</p>

		<p>
			Just enter your email address in the form below and we can get started resetting your account.
		</p>

		<brand-magic-error-display
			[header]="errorMessage"
			[additionalInformation]=[]
			[errors]="errors">
		</brand-magic-error-display>

		<div
			class="working"
			*ngIf="working">
			<mat-spinner></mat-spinner>
		</div>

		<div
			*ngIf="!working">
			<brand-magic-account-recovery
				(request)="onRequest($event)"
				(fail)="onFail($event)"
				(cancel)="onCancel()">
			</brand-magic-account-recovery>
		</div>
	</ng-container>

	<ng-container
		*ngIf="showSuccessMessage">
		<h1>Email sent!</h1>

		<p>
			An email has been sent to <strong>{{ emailAddress }}</strong>
			with instructions on how to reset your password.
		</p>

		<div class="action-buttons">
			<button
				mat-raised-button
				(click)="onCancel()">
				<mat-icon>arrow_back</mat-icon>
				Return to login
			</button>
		</div>
	</ng-container>
</div>

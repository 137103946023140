import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CurrencyPipe } from '@angular/common';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatLuxonDateModule } from '@angular/material-luxon-adapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

import { environment } from '../environments/environment';
import { NgxStripeModule } from 'ngx-stripe';

import { NgChartsModule } from 'ng2-charts';
import { MtxColorpickerModule } from '@ng-matero/extensions/colorpicker';
import { MtxDatetimepickerModule } from '@ng-matero/extensions/datetimepicker';
import { MtxNativeDatetimeModule } from '@ng-matero/extensions/core';
import { MTX_DATETIME_FORMATS } from '@ng-matero/extensions/core';
import { FroalaEditorModule } from 'angular-froala-wysiwyg';
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/link.min.js';
import 'froala-editor/js/plugins/paragraph_format.min.js';
import 'froala-editor/js/plugins/quote.min.js';
import 'froala-editor/js/plugins/lists.min.js';
import 'froala-editor/js/plugins/word_paste.min.js';

import { LibAuthModule } from '@brand-magic/lib-auth';
import { LibHttpModule } from '@brand-magic/lib-http';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { ShellComponent } from './shell/shell.component';
import { LayoutModule } from '@angular/cdk/layout';
import { HomeComponent } from './home/home.component';
import { SubscriptionComponent } from './pages/subscription/subscription.component';
import { EmbeddingCodesComponent } from './pages/embedding-codes/embedding-codes.component';
import { LeadsComponent } from './pages/leads/leads.component';
import { PersonaliseComponent } from './pages/personalise/personalise.component';
import { CouponsComponent } from './pages/coupons/coupons.component';
import { ResourcesComponent } from './pages/resources/resources.component';
import { LearningHubComponent } from './pages/learning-hub/learning-hub.component';
import { AccountDetailsComponent } from './pages/account-details/account-details.component';
import { ReferAColleagueComponent } from './pages/refer-a-colleague/refer-a-colleague.component';
import { TypographyComponent } from './pages/typography/typography.component';
import { SubscriptionUpgradeComponent } from './pages/subscription-upgrade/subscription-upgrade.component';
import { LoginComponent } from './auth/login/login.component';
import { RecoverAccountComponent } from './auth/recover-account/recover-account.component';
import { LeadDisplayComponent } from './pages/lead-display/lead-display.component';
import { BarChartComponent } from './components/bar-chart/bar-chart.component';
import { LineChartComponent } from './components/line-chart/line-chart.component';
import { QuizResultDisplayComponent } from './components/quiz-result-display/quiz-result-display.component';
import { CreateAccountComponent } from './auth/create-account/create-account.component';
import { LicenseeEditComponent } from './components/licensee-edit/licensee-edit.component';
import { ErrorDisplayComponent } from './components/error-display/error-display.component';
import { AdminCommissionsComponent } from './admin/admin-commissions/admin-commissions.component';
import { AdminCouponComponent } from './admin/admin-coupon/admin-coupon.component';
import { AdminLicenseesComponent } from './admin/admin-licensees/admin-licensees.component';
import { AdminPersonalisationComponent } from './admin/admin-personalisation/admin-personalisation.component';
import { AdminSubscriptionComponent } from './admin/admin-subscription/admin-subscription.component';
import { AdminLicenceComponent } from './admin/admin-licence/admin-licence.component';
import { AdminQuizEditComponent } from './admin/admin-quiz-edit/admin-quiz-edit.component';
import { QuizEditComponent } from './components/quiz-edit/quiz-edit.component';
import { PersonalisationPrimaryEditComponent } from './components/personalisation-primary-edit/personalisation-primary-edit.component';
import { PersonalisationAccentEditComponent } from './components/personalisation-accent-edit/personalisation-accent-edit.component';
import { PersonalisationEmailsEditComponent } from './components/personalisation-emails-edit/personalisation-emails-edit.component';
import { PersonalisationInstructionsEditComponent } from './components/personalisation-instructions-edit/personalisation-instructions-edit.component';
import { PersonalisationPaymentEditComponent } from './components/personalisation-payment-edit/personalisation-payment-edit.component';
import { PersonalisationPersonalDetailsEditComponent } from './components/personalisation-personaldetails-edit/personalisation-personaldetails-edit.component';
import { PersonalisationQuizResultsEditComponent } from './components/personalisation-quizresults-edit/personalisation-quizresults-edit.component';
import { PersonalisationResultEmailsEditComponent } from './components/personalisation-resultemails-edit/personalisation-resultemails-edit.component';
import { PersonalisationSettingsEditComponent } from './components/personalisation-settings-edit/personalisation-settings-edit.component';
import { AdminAddCouponDialog } from './admin/admin-add-coupon/admin-add-coupon.dialog';
import { AdminEditSubscriptionLevelComponent } from './admin/admin-edit-subscription-level/admin-edit-subscription-level.component';
import { PreviewPipe } from './pipes/preview.pipe';
import { AccountCreatedComponent } from './auth/account-created/account-created.component';
import { StripeReturnComponent } from './stripe/stripe-return/stripe-return.component';
import { StripeRefreshComponent } from './stripe/stripe-refresh/stripe-refresh.component';

import { CopyResultDialog } from './pages/copy-result-dialog/copy-result.dialog';
import { CompleteAccountDialog } from './components/complete-account/complete-account.dialog';
import { UpgradeSubscriptionDialog } from './components/upgrade-subscription/upgrade-subscription.dialog';
import { SubscriptionUpgradeCompleteComponent } from './pages/subscription-upgrade-complete/subscription-upgrade-complete.component';
import { AdminLicenseeEditComponent } from './admin/admin-licensee-edit/admin-licensee-edit.component';
import { CouponsPaymentComponent } from './pages/coupons-payment/coupons-payment.component';
import { PageShellComponent } from './page-shell/page-shell.component';
import { AuthShellComponent } from './auth-shell/auth-shell.component';
import { AdminShellComponent } from './admin-shell/admin-shell.component';
import { TermsComponent } from './components/terms/terms.component';
import { UpdatePasswordComponent } from './auth/update-password/update-password.component';
import { SupportVideoMenuComponent } from './components/support-video-menu/support-video-menu.component';

@NgModule({
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule.forRoot([], { initialNavigation: 'enabledBlocking' }),
		BrowserAnimationsModule,
		AppRoutingModule,

		NgxStripeModule.forRoot(environment.stripePerishableKey),

		DragDropModule,
		MatAutocompleteModule,
		MatBadgeModule,
		MatButtonModule,
		MatCardModule,
		MatCheckboxModule,
		MatDatepickerModule,
		MatLuxonDateModule,
		MatDialogModule,
		MatExpansionModule,
		MatFormFieldModule,
		MatIconModule,
		MatInputModule,
		MatListModule,
		MatMenuModule,
		MatRadioModule,
		MatPaginatorModule,
		MatProgressSpinnerModule,
		MatSelectModule,
		MatSidenavModule,
		MatSlideToggleModule,
		MatSliderModule,
		MatStepperModule,
		MatTableModule,
		MatTabsModule,
		MatToolbarModule,
		MatTooltipModule,
		LayoutModule,
		NgChartsModule,
		MtxColorpickerModule,
		MtxDatetimepickerModule,
		MtxNativeDatetimeModule,
		FroalaEditorModule.forRoot(),

		LibAuthModule,
		LibHttpModule,
	],
	declarations: [
		AppComponent,
		ShellComponent,
		HomeComponent,
		AdminCommissionsComponent,
		AdminCouponComponent,
		AdminLicenseesComponent,
		AdminPersonalisationComponent,
		AdminSubscriptionComponent,
		AdminLicenceComponent,
		AdminQuizEditComponent,
		AdminQuizEditComponent,
		QuizEditComponent,
		SubscriptionComponent,
		SubscriptionUpgradeCompleteComponent,
		EmbeddingCodesComponent,
		LeadsComponent,
		PersonaliseComponent,
		CouponsComponent,
		ResourcesComponent,
		LearningHubComponent,
		AccountDetailsComponent,
		ReferAColleagueComponent,
		TypographyComponent,
		SubscriptionUpgradeComponent,
		LoginComponent,
		RecoverAccountComponent,
		LeadDisplayComponent,
		CopyResultDialog,
		BarChartComponent,
		LineChartComponent,
		QuizResultDisplayComponent,
		UpgradeSubscriptionDialog,
		CreateAccountComponent,
		LicenseeEditComponent,
		ErrorDisplayComponent,
		PersonalisationPrimaryEditComponent,
		PersonalisationAccentEditComponent,
		PersonalisationPaymentEditComponent,
		PersonalisationPersonalDetailsEditComponent,
		PersonalisationInstructionsEditComponent,
		PersonalisationEmailsEditComponent,
		PersonalisationQuizResultsEditComponent,
		PersonalisationResultEmailsEditComponent,
		PersonalisationSettingsEditComponent,
		AdminAddCouponDialog,
		AdminEditSubscriptionLevelComponent,
		PreviewPipe,
		AccountCreatedComponent,
		StripeReturnComponent,
		StripeRefreshComponent,
		CompleteAccountDialog,
		AdminLicenseeEditComponent,
		CouponsPaymentComponent,
		PageShellComponent,
		AuthShellComponent,
		AdminShellComponent,
		TermsComponent,
		UpdatePasswordComponent,
		SupportVideoMenuComponent,
	],
	providers: [
		CurrencyPipe,
		{
			provide: MTX_DATETIME_FORMATS,
			useValue: {
				parse: {
					dateInput: 'YYYY-MM-DD',
					monthInput: 'MMMM',
					timeInput: 'HH:mm',
					datetimeInput: 'YYYY-MM-DD HH:mm',
				},
				display: {
					dateInput: 'YYYY-MM-DD',
					monthInput: 'MMMM',
					timeInput: 'HH:mm',
					datetimeInput: 'YYYY-MM-DD HH:mm',
					monthYearLabel: 'YYYY MMMM',
					dateA11yLabel: 'LL',
					monthYearA11yLabel: 'MMMM YYYY',
					popupHeaderDateLabel: 'MMM DD, ddd',
				},
			},
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {}

import {
	Component,
	Input,
	Output,
	EventEmitter,
} from '@angular/core';
import {
	ILicenseePersonalisation,
} from '@brand-magic/lib-types';

@Component({
	selector: 'brand-magic-personalisation-primary-edit',
	templateUrl: './personalisation-primary-edit.template.html',
	styleUrls: ['./personalisation-primary-edit.style.scss'],
})
export class PersonalisationPrimaryEditComponent {
	public errors: string[] = [];

	@Input()
	public personalisation!: ILicenseePersonalisation;

	@Output()
	public save = new EventEmitter<null>();

	constructor(
	) {
	}

	public getPageStyle() {
		return {
			backgroundColor: this.personalisation.color.primary.backgroundColor,
			color: this.personalisation.color.primary.color,
		};
	}

	public onButtonOver(evt: any) {
		evt.srcElement.style.backgroundColor = this.personalisation.color.primary.hoverBackgroundColor;
		evt.srcElement.style.color = this.personalisation.color.primary.hoverColor;
	}

	public onButtonOut(evt: any) {
		evt.srcElement.style.backgroundColor = this.personalisation.color.primary.backgroundColor;
		evt.srcElement.style.color = this.personalisation.color.primary.color;
	}

	public onSave() {
		this.save.emit();
	}

}

import {
	Component,
	ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import {
	StripeService,
	StripePaymentElementComponent,
} from 'ngx-stripe';
import {
	ICoupon,
	IHttpResponse,
} from '@brand-magic/lib-types';
import {
  StripeElementsOptions,
  PaymentIntent
} from '@stripe/stripe-js';
import { CouponService } from '../../services/coupon.service';
import { LicenseeService } from '../../services/licensee.service';

@Component({
	selector: 'brand-magic-coupons-payment',
	templateUrl: './coupons-payment.template.html',
	styleUrls: ['./coupons-payment.style.scss'],
})
export class CouponsPaymentComponent {
	public errors: string[] = [];
	public isLoading = false;

	public clientSecret: string | null = null;

	public count = 10;
	public costPerCoupon = 9.9;

	@ViewChild(StripePaymentElementComponent)
	public paymentElement!: StripePaymentElementComponent;

	constructor(
		private router: Router,
		private stripeService: StripeService,
		private couponService: CouponService,
		private licenseeService: LicenseeService,
	) {
		this.initComponent();
	}

	private async initComponent() {
		this.isLoading = true;
		await this.initPayment();
		this.isLoading = false;
	}

	public getClientSecret(): string {
		return this.clientSecret as string;
	}

	private async initPayment() {
		const paymentResponse = await this.couponService.createPaymentIntent();

		if (paymentResponse.success) {
			this.clientSecret = paymentResponse.data as string;
		} else {
			this.errors.push('We were not able to create a coupon payment intent: ' + paymentResponse.helpMessage);
		}
	}

	public onMakePaymentClicked() {
		this.errors.length = 0;
		this.isLoading = true;
		this.stripeService.confirmPayment({
			elements: this.paymentElement.elements,
			redirect: 'if_required',
		}).subscribe(async (result: any) => {
			this.isLoading = false;
			if (result.error) {
				this.errors.push('Your payment was not successful: ' + result.error.message);
			} else {
				await this.addCouponsForLicensee();
				this.router.navigateByUrl('/pages/coupons');
			}
		});
	}

	private async addCouponsForLicensee() {
		const response = await this.couponService.addCoupons(10);
		if (!response.success) {
			this.errors.push(response.helpMessage as string);
		}
	}

	public onCancelClicked() {
		this.router.navigateByUrl('/pages/coupons');
	}
}

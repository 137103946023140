import {
	Component,
	Output,
	EventEmitter,
} from '@angular/core';
import {
	FormControl,
} from '@angular/forms';
import {
	StringUtil
} from '@brand-magic/lib-util';
import {
	Router,
} from '@angular/router';
import {
	AuthService,
	LoginRequestEvent,
	LoginFailureEvent,
} from '@brand-magic/lib-auth';

@Component({
	selector: 'brand-magic-login',
	templateUrl: './login.template.html',
	styleUrls: ['./login.style.scss'],
})
export class LoginComponent {
	public errorMessage = 'We were not able to authenticate you at this time.';
	public errors: string[];

	constructor(
		private router: Router,
		private authService: AuthService
	) {
		this.errors = [];
	}

	public onCancel() {
		this.errors.length = 0;
	}

	public async onLogin(event: any) {
		const loginRequest = event as LoginRequestEvent;
		this.errors.length = 0;
		const response = await this.authService.login(loginRequest);

		if (!response.success) {
			this.errors.push(response.helpMessage as string);
			return;
		}

		let redirectUrl = this.authService.getRedirectUrl();
		if (StringUtil.nullOrEmpty(redirectUrl)) {
			redirectUrl = '/pages/home';
		}

		this.router.navigateByUrl(redirectUrl);
	}

	public onLoginFailure(event: any) {
		const loginFailure = event as LoginFailureEvent;
		this.errors.length = 0;
		loginFailure.helpMessages.forEach(msg => this.errors.push(msg));
	}
}

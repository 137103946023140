<div class="container">
	<h1>Refer a colleague</h1>

	<p>
		We would love for you to share the love. Refer a colleague who
		you would like to see succeed in business, or who you like to
		work with, and as a thank you we will add a free coupon to
		your account.
	</p>

	<div
		*ngIf="isWorking"
		class="isWorking">
		<mat-spinner></mat-spinner>
	</div>

	<mat-card
		*ngIf="!isWorking && !showResponse">
		<mat-card-title>
			I love freebies but I love Brand Magic more!
		</mat-card-title>
		<mat-card-content>

			<div class="fields">
				<mat-form-field appearance="fill">
					<mat-label>Email address</mat-label>
					<input
						matInput
						[(ngModel)]="email">
				</mat-form-field>

				<mat-form-field appearance="fill">
					<mat-label>Subject</mat-label>
					<input
						matInput
						[(ngModel)]="subject">
				</mat-form-field>

				<mat-form-field appearance="fill">
					<mat-label>Message</mat-label>
					<textarea
						matInput
						[(ngModel)]="message"
						[froalaEditor]="htmlEditorOptions">{{ message }}</textarea>
				</mat-form-field>
			</div>

			<mat-card-actions>
				<button
					color="primary"
					mat-raised-button
					(click)="onSendClicked()">
					<mat-icon>send</mat-icon>
					Send
				</button>
			</mat-card-actions>
		</mat-card-content>
	</mat-card>

	<mat-card
		*ngIf="!isWorking && showResponse">
		<mat-card-content>
			<h1
				*ngIf="emailSuccess">
				You have successfully sent a reference request to {{ email }}!
			</h1>

			<ng-container
				*ngIf="!emailSuccess">
				<h1>Unfortunately, we were not able to send your reference at this time</h1>

				<p>The error we received is: {{ emailError }}</p>
			</ng-container>
		</mat-card-content>
	</mat-card>
</div>

<div class="container admin">
	<div class="quiz-container">
		<div class="quiz-menu">
			<mat-selection-list [multiple]="false">
				<mat-list-option
					*ngFor="let quizType of availableQuizTypes"
					[selected]="selectedQuizType === quizType"
					(click)="onMenuItemSelected(quizType)">
					{{ quizType.text }}
				</mat-list-option>
				<mat-list-option
					[selected]="selectedQuizType === quizPrice"
					(click)="onMenuItemSelected(quizPrice)">
					Pricing
				</mat-list-option>
			</mat-selection-list>
		</div>

		<mat-divider vertical="true"></mat-divider>

		<div
			class="quiz-content"
			*ngIf="selectedQuizType === availableQuizTypes[0] || selectedQuizType === availableQuizTypes[1]">
			<h1>Selected: {{ selectedQuizType.text }}</h1>

			<brand-magic-error-display
				header="We encountered an error with your test data"
				[errors]="errors">
			</brand-magic-error-display>

			<div
				*ngIf="selectedQuizType.isLoaded === false">
				Loading the latest test data

				<div class="loading">
					<mat-spinner></mat-spinner>
				</div>

			</div>
			<div
				*ngIf="selectedQuizType.isLoaded && selectedQuizType.quiz == null">
				Could not retreive a suitable test to edit
			</div>

			<brand-magic-quiz-edit
				*ngIf="selectedQuizType.quiz != null"
				[quiz]="selectedQuizType.quiz"
				(save)="onSaveQuiz($event)">
			</brand-magic-quiz-edit>
		</div>


		<div
			class="quiz-price"
			*ngIf="selectedQuizType === quizPrice">

			<ng-container
				*ngIf="!priceIsLoaded">
				Loading the latest test price

				<div class="loading">
					<mat-spinner></mat-spinner>
				</div>
			</ng-container>

			<ng-container
				*ngIf="priceIsLoaded && quizPrice == null">
				We could not retrieve test prices to edit
			</ng-container>

			<ng-container
				*ngIf="priceIsLoaded && quizPrice != null">
				<h1>Test pricing</h1>

				<brand-magic-error-display
					header="We encountered an error with your test prices"
					[errors]="errors">
				</brand-magic-error-display>

				<div class="price-form">
					<div>
						Customer charge for {{ playsPerPurchase }} plays: <em>{{ getTotalCost() }}</em>
					</div>

					<div>
						Licensee fee for {{ playsPerPurchase }} plays: <em>{{ getTotalFees() }}</em>
					</div>

					<mat-form-field
						appearance="fill">
						<mat-label>
							Customer cost per play
						</mat-label>
						<input
							matInput
							placeholder="10.00"
							[(ngModel)]="costPerPlay">
					</mat-form-field>

					<mat-form-field
						appearance="fill">
						<mat-label>
							Brand Magic fee per play
						</mat-label>
						<input
							matInput
							placeholder="10.00"
							[(ngModel)]="feePerPlay">
					</mat-form-field>

					<mat-form-field
						appearance="fill">
						<mat-label>
							Currency
						</mat-label>
						<input
							matInput
							placeholder="aud"
							[(ngModel)]="currency">
					</mat-form-field>

					<mat-form-field
						appearance="fill">
						<mat-label>
							Plays per purchase
						</mat-label>
						<input
							matInput
							placeholder="2"
							[(ngModel)]="playsPerPurchase">
					</mat-form-field>
				</div>

				<div class="action-buttons">
					<button
						mat-raised-button
						color="primary"
						(click)="onSavePrice()">
						Save pricing
					</button>
				</div>

			</ng-container>

		</div>
	</div>
</div>

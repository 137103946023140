
<div class="container">
	<h1>Welcome back!</h1>

	<p>
		It may take a few minutes for Stripe to update us about your account status
		and we may report your integration as incomplete until then.
	</p>

	<p>
		You can visit <a routerLink="/pages/home">your homepage</a> to continue using Brand Magic.
	</p>

	<h3>
		Have a magical day!
	</h3>
</div>


export class TypeUtil {

	public static isArray = Array.isArray;

	public static isObject(obj: any) {
		return obj !== null && typeof obj === 'object';
	}

	public static isNumber(num: any) {
		return typeof num === 'number' && !isNaN(num);
	}

	public static isDate(dt: any) {
		return Object.prototype.toString.call(dt) === '[object Date]';
	}

	public static isLuxon(dt: any) {
		return dt.isValid ?? false;
	}

	// takes either a Date object or date string and returns a Date object
	public static asDate(v: any): Date {
		if (this.isDate(v)) {
			return v;
		}
		let date = null;
		try {
			date = new Date(Date.parse(v));
		} finally {
		}

		return date;
	}

	public static isFunction(fn: any) {
		return typeof fn === 'function';
	}

	public static isBoolean(b: any) {
		return typeof b === 'boolean';
	}

	public static isString(str: any) {
		return typeof str === 'string';
	}

}

import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { StringUtil } from '@brand-magic/lib-util';
import { AuthService } from '@brand-magic/lib-auth';
import { LicenseeService } from '../../services/licensee.service';
import { StripeService } from '../../services/stripe.service';
import {
	ILicensee,
} from '@brand-magic/lib-types';

@Component({
	selector: 'brand-magic-stripe-return',
	templateUrl: './stripe-return.template.html',
	styleUrls: ['./stripe-return.style.scss'],
})
export class StripeReturnComponent {
	public errorMessage = 'We were unable to retrieve your details at this time';
	public errors: string[] = [];

	public isLoading = true;
	public account: any;

	constructor(
		private router: Router,
		private authService: AuthService,
		private licenseeService: LicenseeService,
		private stripeService: StripeService,
	) {
		this.loadStripeAccount();
	}

	private async loadStripeAccount() {
		this.isLoading = true;
		this.errors.length = 0;
		const authResponse = await this.authService.refreshAuthToken();
		if (!authResponse.success) {
			this.router.navigateByUrl('/auth/login');
			return;
		}
		this.licenseeService.clearCaches();
		const licenseeResponse = await this.licenseeService.getCurrentLicensee();
		if (!licenseeResponse.success) {
			this.errors.push(licenseeResponse.helpMessage as string);
		}
		const licensee = licenseeResponse.data as ILicensee;
		if (StringUtil.nullOrEmpty(licensee?.stripe?.accountId)) {
			this.router.navigateByUrl('/auth/login');
			return;
		}
		const accountResponse = await this.stripeService.loadAccount(licensee?.stripe?.accountId ?? '');
		if (!accountResponse.success) {
			return;
		}

		this.account = accountResponse.data;
		this.isLoading = false;
	}

	public dumpAccount() {
		if (this.account == null) {
			return '(account details not loaded)';
		}

		return JSON.stringify(this.account, null, '\t');
	}
}

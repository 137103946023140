
export class Toolbar {

	public static readonly options = {
		attribution: false,
		listAdvancedTypes: false,
		height: 250,
		htmlAllowedAttrs: ['alt', 'class', 'href', 'src', 'style'],
		htmlAllowedEmptyTags: ['hr'],
		htmlAllowedTags: [
			'a', 'b', 'br', 'div', 'p', 'h1', 'h2', 'h3', 'h4',
			'pre', 'hr', 'i', 'li', 'ol', 'string', 'ul', 'u'
		],
		htmlExecuteScripts: false,
		htmlRemoveTags: ['script', 'style', 'base'],
		linkEditButtons: ['linkEdit', 'linkRemove'],
		pastePlain: true,
		toolbarButtons: [
			['bold', 'italic', 'underline'],
			['paragraphFormat'],
			['insertLink', 'align'],
			['formatOL', 'formatUL', 'quote'],
			['undo', 'redo']
		],
		toolbarButtonsMD: [
			['bold', 'italic', 'underline'],
			['paragraphFormat'],
			['insertLink', 'align'],
			['formatOL', 'formatUL' ],
			['undo', 'redo']
		],
		toolbarButtonsSM: [
			['bold', 'italic', 'underline'],
			['paragraphFormat'],
			['insertLink', 'align'],
			['formatOL', 'formatUL' ],
			['undo', 'redo']
		],
		toolbarButtonsXS: [
			['bold', 'italic', 'underline'],
			['paragraphFormat'],
			['insertLink', 'align'],
			['formatOL', 'formatUL' ],
			['undo', 'redo']
		],
		paragraphFormatSelection: true,
		enter: 2,
		paragraphFormat: {
			H1: 'Heading 1',
			H2: 'Heading 2',
			H3: 'Heading 3',
			H4: 'Heading 4',
			N: 'Normal',
		}
	};
}

import {
	Component,
	ViewChild,
	AfterViewInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { DateTime } from 'luxon';
import {
	ILead,
	IHttpResponse,
} from '@brand-magic/lib-types';
import { TypeUtil } from '@brand-magic/lib-util';
import { LicenseeService } from '../../services/licensee.service';
import { LeadService } from '../../services/lead.service';

@Component({
	selector: 'brand-magic-leads',
	templateUrl: './leads.template.html',
	styleUrls: ['./leads.style.scss'],
})
export class LeadsComponent implements AfterViewInit {
	public isLoading = true;
	public availablePageSizes: number[] = [5, 10, 15, 20];
	public totalItems = 100;
	public pageSize = 10;
	public currentIndex = 0;

	public displayedColumns: string[] = [
		'select', 'name', 'email','businessName',
		'interest', 'playsRemaining', 'datePlayed',
	];

	private allLeads: ILead[] = [];

	public lastViewed: DateTime;

	public errors: string[] = [];
	public isPaid = false;
	public dataSource = new MatTableDataSource<ILead>(this.allLeads);

	@ViewChild(MatPaginator)
	public paginator!: MatPaginator;

	private readonly LAST_VISIT_KEY = 'leadsLastVisit';

	constructor(
		private router: Router,
		private licenseeService: LicenseeService,
		private leadService: LeadService,
	) {
		this.isLoading = true;
		this.leadService.getLeads().then((leads: ILead[]) => {
			this.isLoading = false;
			this.allLeads = leads;
			this.dataSource = new MatTableDataSource<ILead>(this.allLeads);
		});
		this.licenseeService.isPaidSubscriber().then(
			(response: IHttpResponse<boolean>) => {
				if (!response.success) {
					this.errors.push(response.helpMessage as string);
					return;
				}
				this.isPaid = response.data as boolean;
			}
		);

		const lastVisitStr = localStorage.getItem(this.LAST_VISIT_KEY);
		this.lastViewed = lastVisitStr == null ? DateTime.fromMillis(0) : DateTime.fromISO(lastVisitStr);
		localStorage.setItem(this.LAST_VISIT_KEY, DateTime.now().toISODate());
	}

	public ngAfterViewInit() {
		this.dataSource.paginator = this.paginator;
	}

	public hasLeads() {
		return this.allLeads.length > 0;
	}

	public isNewLead(lead: ILead) {
		return lead.creationDate > this.lastViewed;
	}

	public onLeadClicked(lead: any) {
		this.router.navigate(['/pages/leads', lead.leadId]);
	}

	public dateToString(dt: DateTime) {
		if (dt == null) {
			return '';
		}
		if (TypeUtil.isString(dt)) {
			dt = DateTime.fromISO(dt as unknown as string);
		}
		if (TypeUtil.isDate(dt)) {
			dt = DateTime.fromJSDate(dt as unknown as Date);
		}
		return dt.toISODate();
	}

	public onUpgradeClicked() {
	}

	public getSelectedCount() {
		return this.allLeads.reduce((prev, cur: any) => {
		return prev + (cur.isSelected ? 1 : 0);
		}, 0);
	}

	public onSelectVisible() {
		 // to also deselect all but the current page
		for (let i = 0; i < this.allLeads.length; i++) {
			const item: any = this.allLeads[i];
			item.isSelected =
				i >= (this.currentIndex * this.pageSize) &&
				i < (this.currentIndex + 1 * this.pageSize);
		}
	}

	public onSelectAll() {
		this.allLeads.forEach((l: any) => {
			l.isSelected = true;
		});
	}

	public onSelectNone() {
		this.allLeads.forEach((l: any) => {
			l.isSelected = false;
		});
	}


	// CSV generation

	private escape(s: string) {
		if (s == null || s.length === 0) {
			return '';
		}
		if (s.indexOf('"') < 0) {
			return s;
		}

		let str = '';
		for (let i = 0; i < s.length; i++) {
			if (s[i] === '"') {
				str += '""';
			} else {
				str += s[i];
			}
		}

		return str;
	}

	public onExportClicked() {
		if (!this.isPaid) {
			this.licenseeService.showPaidSubscriberWarning();
			return;
		}

		let csvData = '"Firstname","Email","Business name","Interest","Date played"\n';
		this.allLeads.filter((l: any) => l.isSelected).forEach((l: any) => {
			csvData += '"' + this.escape(l.givenName) + '","' +
				this.escape(l.email) + '","' +
				this.escape(l.businessName) + '","' +
				this.escape(l.interest) + '","' +
				this.dateToString(l.creationDate) + '"\n';
		});

		const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
		const link = document.createElement("a");
		link.setAttribute("href", URL.createObjectURL(blob));
		link.setAttribute("download", "BrandMagic_leads.csv");
		link.style.visibility = 'hidden';
		document.body.appendChild(link); // Required for FF
		link.click();
		document.body.removeChild(link);
	}

}

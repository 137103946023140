import {
	Component,
	Input,
	Output,
	EventEmitter,
} from '@angular/core';
import {
	ILicenseePersonalisation,
} from '@brand-magic/lib-types';

@Component({
	selector: 'brand-magic-personalisation-accent-edit',
	templateUrl: './personalisation-accent-edit.template.html',
	styleUrls: ['./personalisation-accent-edit.style.scss'],
})
export class PersonalisationAccentEditComponent {
	public errors: string[] = [];

	@Input()
	public personalisation!: ILicenseePersonalisation;

	@Output()
	public save = new EventEmitter<null>();

	constructor() {
	}

	public getAccentStyle() {
		return {
			backgroundColor: this.personalisation.color.accent.backgroundColor,
			color: this.personalisation.color.accent.color
		}
	}

	public onAccentOver(evt: any) {
		evt.srcElement.style.backgroundColor = this.personalisation.color.accent.hoverBackgroundColor;
		evt.srcElement.style.color = this.personalisation.color.accent.hoverColor;
	}

	public onAccentOut(evt: any) {
		evt.srcElement.style.backgroundColor = this.personalisation.color.accent.backgroundColor;
		evt.srcElement.style.color = this.personalisation.color.accent.color;
	}

	public onSave() {
		this.save.emit();
	}

}

import {
	Component,
} from '@angular/core';
import {
	Router,
} from '@angular/router';
import { DateTime } from 'luxon';

import { AuthService } from '@brand-magic/lib-auth';
import { HttpService } from '@brand-magic/lib-http';
import {
	IHttpResponse,
	ILicensee,
	ISubscriptionLevel,
} from '@brand-magic/lib-types';

import {
	GraphService,
	IStats,
} from '../services/graph.service';
import { LicenseeService } from '../services/licensee.service';
import { SubscriptionService } from '../services/subscription.service';
import { IGroupedData } from '../components/bar-chart/grouped-data.interface';

@Component({
	selector: 'brand-magic-home',
	templateUrl: './home.template.html',
	styleUrls: ['./home.style.scss'],
})
export class HomeComponent {
	private readonly months = [
		'', // luxon's date is 1-based
		'Jan', 'Feb', 'Mar', 'Apr',
		'May', 'June', 'July', 'Aug',
		'Sept', 'Oct', 'Nov', 'Dec',
	];

	public output = '';
	public headerText = 'Welcome Back!';
	public errors: string[] = [];
	public subscriptionLevel: ISubscriptionLevel | null = null;
	public isPaidSubscriber = false;
	public arePayoutsAvailable = false;
	public loading = true;

	public data: IGroupedData[] = [];

	public dataThisMonth: IGroupedData[] = [];
	public dataByMonth: IGroupedData[] = []

	private currentMonth = 0;
	private currentYear = 0;

	public stats: IStats = {
		thisMonth: {
			plays: 0,
			earnings: 0,
			data: [],
		},
		all: {
			plays: 0,
			earnings: 0,
			data: [],
		},
	};
	public playsThisMonth = 0;
	public earningsThisMonth = 0;
	public playsAllTime = 0;
	public earningsAllTime = 0;

	private readonly animationDuration = 800;
	private animationStart: any;

	public thisMonthDatasets: any = [{
		label: 'plays by day',
		data: null,
		parsing: {
			xAxisKey: 'label',
			yAxisKey: 'plays',
		},
		borderWidth: 1.5,
		backgroundColor: 'rgba(126,87,194,0.2)',
		borderColor: 'rgba(126,87,194,0.6)',
		hoverBackgroundColor: 'rgba(126,87,194,0.6)',
		hoverBorderColor: 'rgba(126,87,194,1)',
	}];
	public thisMonthLabels: string[] = [];

	public allDatasets: any = [{
		label: 'all to date',
		data: null,
		parsing: {
			xAxisKey: 'label',
			yAxisKey: 'plays',
		},
		borderWidth: 1.5,
		backgroundColor: 'rgba(8,60,169,0.2)',
		borderColor: 'rgba(8,60,169,0.6)',
		hoverBackgroundColor: 'rgba(8,60,182,0.4)',
		hoverBorderColor: 'rgba(8,60,182,0.6)',
	}];
	public allLabels: string[] = [];

	constructor(
		private router: Router,
		private authService: AuthService,
		private httpService: HttpService,
		private graphService: GraphService,
		private licenseeService: LicenseeService,
		private subscriptionService: SubscriptionService,
	) {
		this.initData();
	}

	private initData() {
		const dt = DateTime.now();
		this.currentMonth = dt.month;
		this.currentYear = dt.year;

		this.licenseeService.getCurrentLicensee().then(async (licenseeResponse: IHttpResponse<ILicensee>) => {
			if (!licenseeResponse.success) {
				this.errors.push('We were unable to load your profile data: ' + licenseeResponse.helpMessage);
				return;
			}

			const licensee = licenseeResponse.data as ILicensee;

			this.headerText = 'Welcome back, ' + licensee.givenName;

			this.licenseeService.getCurrentSubscriptionLevel().then(
				(response: IHttpResponse<ISubscriptionLevel>) => {
					if (!response.success) {
						this.errors.push(response.helpMessage as string);
						return;
					}
					this.subscriptionLevel = response.data as ISubscriptionLevel;
				}
			);

			this.licenseeService.isPaidSubscriber().then(
				(response: IHttpResponse<boolean>) => {
					if (!response.success) {
						this.errors.push(response.helpMessage as string);
						return;
					}
					this.isPaidSubscriber = response.data as boolean;
				}
			);

			this.licenseeService.arePayoutsAvailable().then(
				(response: IHttpResponse<boolean>) => {
					if (!response.success) {
						this.errors.push(response.helpMessage as string);
						return;
					}
					this.arePayoutsAvailable = response.data as boolean;
				}
			);

			this.graphService.getStats().then((results: IStats | null) => {
				if (results == null) {
					return;
				}
				this.stats = results;

				this.thisMonthLabels = this.stats.thisMonth.data.map(d => d.label);
				this.allLabels = this.stats.all.data.map(d => d.label);
				this.thisMonthDatasets[0].data = this.stats.thisMonth.data;
				this.allDatasets[0].data = this.stats.all.data

				this.doInitialAnimation();
			});
		});
	}


	// Animate the intiial display of plays per month and all time
	private doInitialAnimation() {
		const current = Date.now();
		if (this.animationStart == null) {
			this.animationStart = current;
		}
		const timeElapsed = current - this.animationStart;

		if (timeElapsed >= this.animationDuration) {
			this.playsThisMonth = this.stats.thisMonth.plays;
			this.earningsThisMonth = this.stats.thisMonth.earnings;
			this.playsAllTime = this.stats.all.plays;
			this.earningsAllTime = this.stats.all.earnings;

			return;
		}

		requestAnimationFrame(() => {
			this.doInitialAnimation();
		});

		const percentComplete = timeElapsed / this.animationDuration;
		const easedProgress = this.easeOutCirc(percentComplete);

		// ease out the values
		this.playsThisMonth = Math.round(this.stats.thisMonth.plays * easedProgress);
		this.earningsThisMonth = Math.round(this.stats.thisMonth.earnings * easedProgress);
		this.playsAllTime = Math.round(this.stats.all.plays * easedProgress);
		this.earningsAllTime = Math.round(this.stats.all.earnings * easedProgress);
	}

	private easeOutCirc(x: number): number {
		return Math.sqrt(1 - Math.pow(x - 1, 2));
	}


	public onReceiveCommissionsClicked() {
		this.router.navigateByUrl('/pages/subscription');
	}


	public getSubscriptionLevel() {
		if (this.subscriptionLevel == null) {
			return '. . .';
		}
		return this.subscriptionLevel.name;
	}

	public isLoggedIn() {
		return this.authService.isLoggedIn();
	}

	public login() {
		// return this.authService.loginCodeInPopup();
	}

	public logout() {
		this.authService.logout();
	}

	public getMonthAndYear() {
		return this.months[this.currentMonth] + ' ' + this.currentYear;
	}

}

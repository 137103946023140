<div
	*ngIf="quizResult != null"
	class="result-container">

	<div class="selections">
		<div class="select-options">
			<button
				mat-stroked-button
				color="primary"
				(click)="onSelectNone()">
				<mat-icon>check_box_outline_blank</mat-icon>
				Select none
			</button>
			<button
				color="primary"
				mat-stroked-button
				(click)="onSelectAll()">
				<mat-icon>library_add_check</mat-icon>
				Select all
			</button>
		</div>
		<mat-selection-list
			[(ngModel)]="selectedOptions"
			(selectionChange)="onSelectionChanged($event)">
			<mat-list-option
				checkboxPosition="before"
				*ngFor="let answer of quizResult.answers"
				[value]="answer">
				{{ answer.answer }}
			</mat-list-option>
		</mat-selection-list>
	</div>

	<div class="results">
		<h2 class="header">{{ getHeaderText() }}</h2>

		<div class="hero-container">
			<div
				*ngIf="hasPrimary()"
				class="hero-display">
				<span class="svg" [innerHTML]="getPrimarySvg()"></span>
				<h3>{{ getPrimaryName() }}</h3>
			</div>
			<div
				*ngIf="hasSecondary()"
				class="hero-display">
				<span class="svg" [innerHTML]="getSecondarySvg()"></span>
				<h3>{{ getSecondaryName() }}</h3>
			</div>
		</div>

		<canvas
			baseChart
			height="200px"
			width="400px"
			[data]="data"
			[options]="options"
			[type]="'bar'">
		</canvas>
	</div>

</div>

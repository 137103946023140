import { Injectable } from '@angular/core';
import { IPersonality } from '@brand-magic/lib-types';
import { Personality } from '@brand-magic/lib-personality';

@Injectable({
	providedIn: 'root'
})
export class PersonalityService {

	constructor(
	) {
	}

	public getPersonalities() {
		return Personality.AllPersonalities;
	}

	public getPersonalityById(personalityId: string): (IPersonality | null) {
		return Personality.getPersonalityById(personalityId);
	}
}

<div class="container">

	<brand-magic-error-display
		[header]="errorHeader"
		[additionalInformation]="errorDescription"
		[errors]="errors">
	</brand-magic-error-display>

	<h2>Contact information</h2>

	<mat-card>
		<mat-card-content>
			<div class="contact-fields">
				<mat-form-field appearance="fill">
					<mat-label>Given name</mat-label>
					<input
						matInput
						[formControl]="givenName">
					<mat-error *ngIf="givenName.hasError('required')">
						Given name is <strong>required</strong>
					</mat-error>
				</mat-form-field>

				<mat-form-field appearance="fill">
					<mat-label>Family name</mat-label>
					<input
						matInput
						[formControl]="familyName">
					<mat-error *ngIf="familyName.hasError('required')">
						Family name is <strong>required</strong>
					</mat-error>
				</mat-form-field>


				<mat-form-field class="password" appearance="fill" *ngIf="isPasswordRequired()">
					<mat-label>Password</mat-label>
					<input
						matInput
						type="password"
						[formControl]="password">
					<mat-hint align="start">
						Passwords must be at least 8 characters long,
						have lowercase and uppercase characters,
						punctuation marks and numbers.
					</mat-hint>
					<mat-error *ngIf="password.hasError('required')">
						Password is <strong>required</strong>
					</mat-error>
				</mat-form-field>

				<mat-form-field clas="password" appearance="fill" *ngIf="isPasswordRequired()">
					<mat-label>Password confirmation</mat-label>
					<input
						matInput
						type="password"
						[formControl]="passwordConfirm">
					<mat-hint align="start">
						Please retype your password to ensure you have not made
						any typos
					</mat-hint>
					<mat-error *ngIf="passwordConfirm.hasError('required')">
						Password confirmation is <strong>required</strong>
					</mat-error>
				</mat-form-field>

				<mat-form-field appearance="fill">
					<mat-label>Email</mat-label>
					<input
						matInput
						[formControl]="email">
					<mat-error *ngIf="email.hasError('required')">
						Email Address is <strong>required</strong>
					</mat-error>
				</mat-form-field>


				<mat-form-field appearance="fill">
					<mat-label>Phone</mat-label>
					<input
						matInput
						[formControl]="phone">
					<mat-hint align="start">
						e.g. (+61) 1234 5678
					</mat-hint>
					<mat-error *ngIf="phone.hasError('required')">
						Phone is <strong>required</strong>
					</mat-error>
				</mat-form-field>
			</div>
		</mat-card-content>
	</mat-card>

	<h2>Company information</h2>

	<mat-card>
		<mat-card-content>
			<div class="contact-fields">
				<mat-form-field appearance="fill">
					<mat-label>Company name</mat-label>
					<input
						matInput
						[formControl]="companyName">
					<mat-error *ngIf="companyName.hasError('required')">
						Company name is <strong>required</strong>
					</mat-error>
				</mat-form-field>

				<mat-form-field appearance="fill">
					<mat-label>Australian Business Number (ABN)</mat-label>
					<input
						matInput
						[formControl]="abn">
				</mat-form-field>

				<mat-form-field appearance="fill">
					<mat-label>Website</mat-label>
					<input
						matInput
						[formControl]="website">
					<mat-error *ngIf="website.hasError('required')">
						Website is <strong>required</strong>
					</mat-error>
				</mat-form-field>
			</div>
		</mat-card-content>
	</mat-card>

	<h2>Address</h2>

	<mat-card>
		<mat-card-content>
			<div class="contact-fields">
				<mat-form-field appearance="fill">
					<mat-label>Street address 1</mat-label>
					<input
						matInput
						[formControl]="street1">
					<mat-error *ngIf="street1.hasError('required')">
						Street address is <strong>required</strong>
					</mat-error>
				</mat-form-field>

				<mat-form-field appearance="fill">
					<mat-label>Street address 2</mat-label>
					<input
						matInput
						[formControl]="street2">
				</mat-form-field>

				<mat-form-field appearance="fill">
					<mat-label>Suburb</mat-label>
					<input
						matInput
						[formControl]="city">
					<mat-error *ngIf="city.hasError('required')">
						Suburb is <strong>required</strong>
					</mat-error>
				</mat-form-field>

				<mat-form-field appearance="fill">
					<mat-label>State</mat-label>
					<input
						matInput
						[formControl]="state">
					<mat-error *ngIf="state.hasError('required')">
						State is <strong>required</strong>
					</mat-error>
				</mat-form-field>

				<mat-form-field appearance="fill">
					<mat-label>Postcode</mat-label>
					<input
						matInput
						[formControl]="postcode">
					<mat-error *ngIf="state.hasError('required')">
						Postcode is <strong>required</strong>
					</mat-error>
				</mat-form-field>

				<mat-form-field appearance="fill">
					<mat-label>Country</mat-label>
					<input
						type="text"
						aria-label="Country"
						matInput
						[formControl]="countryCode"
						[matAutocomplete]="autoCountry">
					<mat-autocomplete
						#autoCountry="matAutocomplete"
						[displayWith]="getName">
						<mat-option
							*ngFor="let country of filteredCountries | async"
							[value]="country">
							{{ country.name }}
						</mat-option>
					</mat-autocomplete>
					<mat-error *ngIf="countryCode.hasError('required')">
						Country is <strong>required</strong>
					</mat-error>
				</mat-form-field>
			</div>
		</mat-card-content>
	</mat-card>

	<div class="terms">
		<mat-checkbox
			class="check"
			[formControl]="agreedToTerms">
			Agreed to terms?
		</mat-checkbox>

		<button
			mat-stroked-button
			color="primary"
			(click)="onViewTerms()">
			<mat-icon>open_in_new_window</mat-icon>
			View Terms and Conditions
		</button>
	</div>

	<mat-card-actions>
		<button
			mat-raised-button
			[disabled]="!hasChangedValues()"
			color="primary"
			(click)="onSave()">
			<mat-icon>save</mat-icon>
			Save changes
		</button>
	</mat-card-actions>
</div>

import { Component, ViewChild, } from '@angular/core';
import { FormBuilder, Validators, } from '@angular/forms';
import {
	Router,
	ActivatedRoute,
	ParamMap,
} from '@angular/router';
import { HttpClient } from '@angular/common/http'

import { environment } from '../../../environments/environment';

import {
	StripeService,
	StripePaymentElementComponent
} from 'ngx-stripe';
import {
	StripeElementsOptions,
} from '@stripe/stripe-js';

import {
	ISubscriptionLevel,
	ILicensee,
} from '@brand-magic/lib-types';
import {
	StringUtil,
} from '@brand-magic/lib-util';
import { StripeService as BrandMagicStripeService } from '../../services/stripe.service';
import { SubscriptionService } from '../../services/subscription.service';
import { LicenseeService } from '../../services/licensee.service';

enum SubscriptionStatus {
	UNKNOWN,
	UPGRADING,
	DOWNGRADING,
	UNSUBSCRIBING,
	PAID_COMPLETE,
	FREE_COMPLETE,
};

enum StripeStatus {
	UNKNOWN,
	NO_ACCOUNT,
	INCOMPLETE,
	COMPLETE,
};

@Component({
	selector: 'brand-magic-subscription-upgrade',
	templateUrl: './subscription-upgrade.template.html',
	styleUrls: ['./subscription-upgrade.style.scss'],
})
export class SubscriptionUpgradeComponent {
	@ViewChild(StripePaymentElementComponent)
	public paymentElement!: StripePaymentElementComponent;
	public elementsOptions: StripeElementsOptions = {
		locale: 'en'
	};

	public isLoading = true;
	public isTransferringToStripe = false;

	public hasPaidSubscription = false;
	public arePayoutsAvailable = false;

	public selectedSubscriptionLevel: ISubscriptionLevel | null = null;
	public isNewLevelFree = false;
	public isSubscribedToDesiredLevel = false;

	public StripeStatus = StripeStatus;
	public stripeStatus = StripeStatus.UNKNOWN;

	private currentLicensee: ILicensee | null = null;
	private currentSubscription: ISubscriptionLevel | null = null;
	public SubscriptionStatus = SubscriptionStatus;
	public subscriptionStatus = SubscriptionStatus.UNKNOWN;

	public errorMessage = 'We were not able to upgrade your subscription level at this time.';
	public errors: string[] = [];

	constructor(
		private formBuilder: FormBuilder,
		private router: Router,
		private route: ActivatedRoute,
		private http: HttpClient,
		private subscriptionService: SubscriptionService,
		private licenseeService: LicenseeService,
		private stripeService: StripeService,
		private brandMagicStripeService: BrandMagicStripeService,
	) {
	}

	public async ngOnInit() {
		this.isLoading = true;
		// We want to know:
		// have they subscribed to a paid level account?
		// does this person have a stripe account id?
		// Is the stripe account id valid?
		const promises = [
			this.licenseeService.getCurrentLicensee(),
			this.licenseeService.getCurrentSubscriptionLevel(),
			this.licenseeService.arePayoutsAvailable(),
		];

		const responses: any = await Promise.all(promises);
		this.isLoading = false;
		if (!responses[0].success) {
			this.errors.push('We were not able to load your data. Please refresh your browser and try again.');
			return;
		}
		this.currentLicensee = responses[0].data;

		if (!responses[1].success) {
			this.errors.push('We were unable to determine your current subscription level. Please refresh your browser and try again.');
			return;
		}

		this.currentSubscription = responses[1].data;
		this.hasPaidSubscription = !this.currentSubscription?.isFree;

		if (!responses[2].success) {
			this.errors.push('We were unable to determine if you have completed your stripe integration');
			return;
		}
		const arePayoutsAvailable = responses[2].data;

		// Determine level of Stripe completedness
		if (arePayoutsAvailable) {
			this.stripeStatus = StripeStatus.COMPLETE;
		} else if (!StringUtil.nullOrEmpty(this.currentLicensee?.stripe?.accountId)) {
			this.stripeStatus = StripeStatus.INCOMPLETE;
		} else {
			this.stripeStatus = StripeStatus.NO_ACCOUNT;
		}

		// Determine the desired subscription level
		let rt = this.route;
		if (this.route.children.length > 0) {
			rt = this.route.children[0];
		}

		rt.paramMap.subscribe(async (params: ParamMap) => {
			this.isLoading = true;
			const subscriptionLevelId = params.get('subscriptionLevelId');
			if (subscriptionLevelId == null || subscriptionLevelId.length === 0) {
				this.router.navigateByUrl('/pages/subscription');
				return;
			}

			await this.loadSubscriptionLevelById(subscriptionLevelId);

			this.isLoading = false;

			if (this.selectedSubscriptionLevel == null) {
				this.router.navigateByUrl('/pages/subscription');
				return;
			}

			this.isNewLevelFree = this.selectedSubscriptionLevel.isFree;
			this.isSubscribedToDesiredLevel = (this.currentSubscription?.subscriptionLevelId === subscriptionLevelId);

			if (this.isSubscribedToDesiredLevel) {
				this.subscriptionStatus = this.isNewLevelFree ? SubscriptionStatus.FREE_COMPLETE : SubscriptionStatus.PAID_COMPLETE;
			} else {
				if (this.isNewLevelFree) {
					this.subscriptionStatus = SubscriptionStatus.UNSUBSCRIBING;
				} else if (this.currentSubscription?.isFree) {
					this.subscriptionStatus = SubscriptionStatus.UPGRADING;
				} else {
					const currentCost = this.currentSubscription?.dollarsPerMonth ?? 0;
					let diff = this.selectedSubscriptionLevel.dollarsPerMonth - currentCost;
					this.subscriptionStatus = diff > 0 ? SubscriptionStatus.UPGRADING : SubscriptionStatus.DOWNGRADING;
				}
			}

			if (!this.isNewLevelFree && !this.isSubscribedToDesiredLevel) {
				// allow users to pay to upgrade subscription if they desire
				await this.createPaymentIntent();
			}
		});
	}

	public async onCreateConnectedAccount() {
		this.errors.length = 0;
		this.isLoading = true;
		this.isTransferringToStripe = true;

		const subscriptionLevelId = this.selectedSubscriptionLevel?.subscriptionLevelId ?? StringUtil.empty;
		localStorage.setItem('subscriptionLevelId', subscriptionLevelId);

		const response = await this.brandMagicStripeService.createConnectedAccount();
		if (!response.success) {
			this.isLoading = false;
			this.isTransferringToStripe = false;
			this.errors.push(response.helpMessage as string);
			return;
		}

		const accountLink = response.data;
		document.location.href = accountLink.url;
	}

	public async onCompleteAccount() {
		this.errors.length = 0;

		if (this.currentLicensee == null) {
			this.errors.push('We were unable to load your account details. Please refresh your browser and try again');
			return;
		}

		// this should be checked on load and the option to invoke this method should not be
		// presented to the user. But we check just to be sure.
		if (this.currentLicensee?.stripe?.accountId == null) {
			this.errors.push('Your account does not appear to have a stripe account id assocated with it.');
			return;
		}

		this.isLoading = true;
		this.isTransferringToStripe = true;

		const createResponse = await this.brandMagicStripeService.createAccountLink(
			this.currentLicensee.stripe.accountId,
		);

		if (createResponse.success) {
			// There is a delay (possibly microtasks) between this line being hit and when it is executed.
			// We therefore don't hide the isLoading before the navigation
			document.location.href = createResponse.data.url;
		} else {
			this.isLoading = false;
			this.isTransferringToStripe = false;
			this.errors.push('We were unable to redirect you to stripe at this time.');
			this.errors.push('The error we received is: ' + createResponse.helpMessage);
		}
	}


	private async loadSubscriptionLevelById(newSubscriptionLevelId: string) {
		const response = await this.subscriptionService.getSubscriptionLevelById(newSubscriptionLevelId);
		if (!response.success) {
			this.errors.push(response.helpMessage as string);
			return;
		}
		this.selectedSubscriptionLevel = response.data as ISubscriptionLevel;
	}

	private async createPaymentIntent() {
		this.errors.length = 0;

		const subscriptionId = this.selectedSubscriptionLevel?.subscriptionLevelId;
		const subscriptionResponse = await this.licenseeService.getSubscriptionIntent(subscriptionId as string);

		if (subscriptionResponse.success) {
			this.elementsOptions.clientSecret = subscriptionResponse.data.clientSecret;
		} else {
			this.errors.push(subscriptionResponse.helpMessage as string);
		}
	}

	public async onMakePaymentClicked() {
		this.errors.length = 0;
		this.isLoading = true;

		const confirmRequest: any = {
			elements: this.paymentElement.elements,
			confirmParams: {
				return_url: environment.stripeReturnUrl,
			},
			redirect: 'if_required'
		}
		try {
			this.stripeService.confirmPayment(confirmRequest).subscribe(async (result: any) => {

			if (result.error) {
				this.errors.push(result.error.message);
				this.isLoading = false;
			} else {
				if (result.paymentIntent.status === 'succeeded') {
					const updateData = {
						subscriptionId: this.selectedSubscriptionLevel?.subscriptionLevelId,
					}
					const upgradeResponse = await this.licenseeService.updateLicenseeDetails(updateData);
					if (!upgradeResponse.success) {
						this.errors.push('We created your subscription in Stripe, but were not able to update your data. Please contact Debbie on DebbieO@whiteriverdesign.com and we will update your data');
						this.errors.push('The error we receieved is:');
						this.errors.push(upgradeResponse.helpMessage as string);
					}
					this.isLoading = false;
					this.router.navigateByUrl('/pages/subscription-upgrade-complete');
				} else {
					this.errors.push('Unfortunately your subscription did not succeed. Please double check your credit card details and try again');
					this.isLoading = false;
				}
			}
		});
		} catch (exception) {
			console.log(exception);
		}
	}

	public async onDowngradeClicked() {
		this.errors.length = 0;
		const subscriptionId: string = this.selectedSubscriptionLevel?.subscriptionLevelId ?? '';
		if (subscriptionId.length === 0) {
			this.errors.push('We encountered an error attempting to update your subscription level. Please refresh your browser and try again.');
			return;
		}
		const updateResponse = await this.licenseeService.updateSubscription(subscriptionId);
		if (!updateResponse.success) {
			this.errors.push(updateResponse.helpMessage as string);
			return;
		}

		this.router.navigateByUrl('/pages/subscription');
	}

}

<div class="container admin">
	<h1>Admin coupons</h1>

	<button
		mat-raised-button
		(click)="onOpenAddCoupon()">
		<mat-icon>add</mat-icon>
		Add coupons
	</button>

	<div
		*ngIf="errors.length > 0"
		class="errors">
		<h1>We encountered some errors related to your coupons</h1>

		<div>
			Unfortunately we encountered some errors. The details are:
		</div>

		<brand-magic-error-display
			[errors]="errors">
		</brand-magic-error-display>
	</div>

	<div
		class="no-coupons"
		*ngIf="allCoupons.length === 0">

		<h2>No admin coupons are loaded yet</h2>

		<p>
			There are not any coupons loaded yet.
		</p>
	</div>

	<div
		class="loading"
		*ngIf="isLoading">
		<mat-spinner></mat-spinner>
	</div>

	<div
		[ngClass]="{'hidden': isLoading}">
		<table
			mat-table
			[dataSource]="dataSource"
			class="mat-elevation-z6 coupon-table">

			<ng-container matColumnDef="couponCode">
				<th mat-header-cell *matHeaderCellDef>
					Coupon Code
				</th>
				<td mat-cell *matCellDef="let coupon">
					{{ coupon.couponCode }}
				</td>
			</ng-container>

			<ng-container matColumnDef="playerName">
				<th mat-header-cell *matHeaderCellDef>
					Player Name
				</th>
				<td mat-cell *matCellDef="let coupon">
					<span
						*ngIf="isSent(coupon)">
						{{ coupon.playerName }}
					</span>
					<mat-form-field
						*ngIf="!isSent(coupon)"
						appearance="fill">
						<mat-label>Player name</mat-label>
						<input
							matInput
							[(ngModel)]="coupon.playerName">
					</mat-form-field>
				</td>
			</ng-container>

			<ng-container matColumnDef="email">
				<th mat-header-cell *matHeaderCellDef>
					Email address
				</th>
				<td mat-cell *matCellDef="let coupon">
					<span
						*ngIf="isSent(coupon)">
						{{ coupon.email }}
					</span>
					<mat-form-field
						*ngIf="!isSent(coupon)"
						appearance="fill">
						<mat-label>Email address</mat-label>
						<input
							matInput
							[(ngModel)]="coupon.email">
					</mat-form-field>
				</td>
			</ng-container>

			<ng-container matColumnDef="invited">
				<th mat-header-cell *matHeaderCellDef class="center">
					Invited
				</th>
				<td mat-cell *matCellDef="let coupon" class="small">
					{{ dateToString(coupon.invited) }}
				</td>
			</ng-container>

			<ng-container matColumnDef="redeemed">
				<th mat-header-cell *matHeaderCellDef class="center">
					Redeemed
				</th>
				<td mat-cell *matCellDef="let coupon" class="small">
					{{ dateToString(coupon.played) }}
				</td>
			</ng-container>

			<ng-container matColumnDef="isUnlimited">
				<th mat-header-cell *matHeaderCellDef class="center">
					Unlimited
				</th>
				<td mat-cell *matCellDef="let coupon" class="center">
					<mat-checkbox
						class="example-margin"
						disabled
						[checked]="coupon.isUnlimitedPlay">
					</mat-checkbox>
				</td>
			</ng-container>

			<ng-container matColumnDef="expiryDate">
				<th mat-header-cell *matHeaderCellDef class="center">
					Expiry
				</th>
				<td mat-cell *matCellDef="let coupon" class="small">
					{{ dateToString(coupon.unlimitedExpiryDate) }}
				</td>
			</ng-container>

			<ng-container matColumnDef="isLimited">
				<th mat-header-cell *matHeaderCellDef class="center">
					Limited
				</th>
				<td mat-cell *matCellDef="let coupon" class="center">
					<mat-checkbox
						class="example-margin"
						disabled
						[checked]="coupon.isLimitedPlay">
					</mat-checkbox>
				</td>
			</ng-container>

			<ng-container matColumnDef="limitedPlayMax">
				<th mat-header-cell *matHeaderCellDef>
					Max Plays
				</th>
				<td mat-cell *matCellDef="let coupon">
					{{ coupon.limitedPlayMax }}
				</td>
			</ng-container>

			<ng-container matColumnDef="send">
				<th mat-header-cell *matHeaderCellDef>
				</th>
				<td mat-cell *matCellDef="let coupon">
					<button
						mat-raised-button
						disabled
						*ngIf="isSent(coupon)">
						<mat-icon>mark_email_read</mat-icon>
						Sent
					</button>
					<button
						mat-raised-button
						(click)="onSend(coupon)"
						*ngIf="!isSent(coupon)"
						color="primary">
						<mat-icon>mail</mat-icon>
						Send
					</button>
				</td>
			</ng-container>

			<ng-container matColumnDef="delete">
				<th mat-header-cell *matHeaderCellDef>
				</th>
				<td mat-cell *matCellDef="let coupon">
					<button
						mat-raised-button
						(click)="onDelete(coupon)"
						[disabled]="isSent(coupon)">
						<mat-icon>close</mat-icon>
						Delete
					</button>
				</td>
			</ng-container>

			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr class="coupon-row" mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
		</table>

		<mat-paginator
			class="mat-elevation-z6"
			[pageSizeOptions]="availablePageSizes"
			[pageSize]="pageSize"
			showFirstLastButtons
			aria-label="Select page of coupons">
		</mat-paginator>
	</div>



</div>

<div class="contaienr">
	<mat-form-field
		class="full-width">
		<mat-label>
			Email
		</mat-label>
		<input
			type="email"
			matInput
			[formControl]="emailAddressControl"
			placeholder="Email Address">
	</mat-form-field>

	<div class="action-buttons">
		<button
			mat-raised-button
			color="primary"
			(click)="onSend()">
			<mat-icon>mail</mat-icon>
			Email me a reset code
		</button>

		<button
			mat-raised-button
			(click)="onCancel()">
			<mat-icon>arrow_back</mat-icon>
			Return to login
		</button>
	</div>
</div>


<h1 mat-dialog-title>This feature is only available to Magician and Wizard level subscriptions</h1>

<div mat-dialog-content>
	<h2>Please consider upgrading your subscription.</h2>
</div>

<div mat-dialog-actions>
	<button
		mat-raised-button
		mat-dialog-close>
		Close
	</button>
</div>

import { Component } from '@angular/core';
import {
	Router,
} from '@angular/router';

import { BreakpointObserver, } from '@angular/cdk/layout';
import { distinctUntilChanged } from 'rxjs/operators';
import { MenuService } from '../services/menu.service';
import { CacheManagementService } from '../services/cache-management.service';

@Component({
	selector: 'brand-magic-shell',
	templateUrl: './shell.template.html',
	styleUrls: ['./shell.style.scss']
})
export class ShellComponent {

	public atLeast900 = false;
	public atLeast700 = false;

	private isAdminUser = false;

	readonly breakpoint$ = this.breakpointObserver
		.observe(['(min-width: 900px)', '(min-width: 700px)'])
		.pipe(
			distinctUntilChanged()
		);

	constructor(
		private router: Router,
		private breakpointObserver: BreakpointObserver,
		public menuService: MenuService,
		public cacheService: CacheManagementService
	) {
	}

	ngOnInit(): void {
		this.breakpoint$.subscribe(() =>
			this.breakpointChanged()
		);
	}

	private breakpointChanged() {
		this.atLeast900 = true;
		this.atLeast700 = true;

		if (this.breakpointObserver.isMatched('(min-width: 900px)')) {
		} else if (this.breakpointObserver.isMatched('(min-width: 700px)')) {
			this.atLeast900 = false;
		} else  {
			this.atLeast900 = false;
			this.atLeast700 = false;
		}
	}

	public onLogin() {
		this.router.navigateByUrl('/auth/login');
	}

	public onLogout() {
		this.cacheService.clearCaches();
		this.router.navigateByUrl('/auth/login');
	}

}

import {
	StringUtil
} from '@brand-magic/lib-util';
import {
	IAuthToken
} from '@brand-magic/lib-types';

export class JwtDecoder {

	// Note that this method only reads the contents of the token
	// but it DOES NOT VERIFY its contents. This is for convenience,
	// not security.
	public decode(tokenStr: string | null): IAuthToken | null {
		if (StringUtil.nullOrEmpty(tokenStr)) {
			return null;
		}
		const parts = (tokenStr as string).split('.');
		if (parts.length != 3) {
			console.log('Malformed token. Expected three parts, received ' + parts.length);
			return null;
		}
		try {
			const bStr = parts[1];
			const bodyStr = this.decodeBinary(bStr);
			const obj = JSON.parse(bodyStr);
			return obj;
		} catch (exception) {
			console.log('Unable to decode auth token: ' + parts[1]);
		}

		return null;
	}

	// is the supplied token formatted correctly (not verified) and not expired?
	public seemsFine(tokenStr: string | null): boolean {
		const token = this.decode(tokenStr);
		if (token == null) {
			return false;
		}
		const currentTime = (Date.now() / 1000);
		return token.exp != null && token.exp > currentTime;
	}

	// This was copied off Stack Overflow to decode UTF-8
	private decodeBinary(str: string): string {
		var base64 = str.replace(/-/g, '+').replace(/_/g, '/');
		var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
			return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
		}).join(''));

		return jsonPayload;
	}

}

import {
	Component,
	Input,
} from '@angular/core';
import { Toolbar } from '../../toolbar';
import {
	ILicenseePersonalisation,
} from '@brand-magic/lib-types';

@Component({
	selector: 'brand-magic-personalisation-settings-edit',
	templateUrl: './personalisation-settings-edit.template.html',
	styleUrls: ['./personalisation-settings-edit.style.scss'],
})
export class PersonalisationSettingsEditComponent {
	public htmlEditorOptions: any = Toolbar.options;

	@Input()
	public personalisation!: ILicenseePersonalisation;

	constructor(
	) {
	}

}

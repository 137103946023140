import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

import { HttpClientModule } from '@angular/common/http';

import { AuthService } from './services/auth.service';
import { AuthGuard } from './guards/auth-guard';
import { AuthLoginComponent } from './components/login/login.component';
import { LoginRequestEvent } from './components/login/login-request.event';
import { LoginFailureEvent } from './components/login/login-failure.event';
import { LoginDisplayComponent } from './components/login-display/login-display.component';
import { AccountRecoveryComponent } from './components/account-recovery/account-recovery.component';
import { RecoveryRequestEvent } from './components/account-recovery/recovery-request.event';
import { RecoveryFailureEvent } from './components/account-recovery/recovery-failure.event';
import { RecoveryCancelEvent } from './components/account-recovery/recovery-cancel.event';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { PasswordRequestEvent } from './components/change-password/password-request.event';
import { PasswordFailureEvent } from './components/change-password/password-failure.event';
import { PasswordCancelEvent } from './components/change-password/password-cancel.event';

export {
	AuthService,
	AuthGuard,
	LoginRequestEvent,
	LoginFailureEvent,
	RecoveryRequestEvent,
	RecoveryFailureEvent,
	RecoveryCancelEvent,
	PasswordRequestEvent,
	PasswordFailureEvent,
	PasswordCancelEvent,
};

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		MatButtonModule,
		MatFormFieldModule,
		MatIconModule,
		MatInputModule,
	],
	exports: [
		AuthLoginComponent,
		LoginDisplayComponent,
		AccountRecoveryComponent,
		ChangePasswordComponent,
	],
	declarations: [
		AuthLoginComponent,
		LoginDisplayComponent,
		AccountRecoveryComponent,
		ChangePasswordComponent,
	],
	providers: [AuthService],
})
export class LibAuthModule {}

import {
	Component,
	Input,
} from '@angular/core';

@Component({
	selector: 'brand-magic-error-display',
	templateUrl: './error-display.template.html',
	styleUrls: ['./error-display.style.scss'],
})
export class ErrorDisplayComponent {

	@Input()
	public header: string = 'We encountered an unexpected error trying to fulfill your request';

	@Input()
	public additionalInformation: string[] = ['You may need to try back later after we have fixed the error.'];

	@Input()
	public errors: string[] = []

	constructor() {
	}

	public hasErrors() {
		return this.errors.length > 0;
	}
}


export interface IPersonalityWeighting {
	personalityId: string;
	weight: number;
}

export interface IAnswer {
	answer: string;
	weightings: IPersonalityWeighting[];
}

export interface IQuestion {
	question: string;
	hint: string;
	options: IAnswer[];
}

export const QuizType = {
	Personal: 'personal',
	Business: 'business',
	Price: 'price',
} as const;

export interface IQuizData {
	quizId: string;
	quizType: string;
	questions: IQuestion[];
}

<div class="container admin">

	<h1>Licensees</h1>

	<div
		*ngIf="!isLoaded">
		Loading licensee data
	</div>


	<ng-container
		*ngIf="isLoaded && allLicensees.length === 0">
		<h2>We could not load any licensees at this time</h2>
	</ng-container>

	<ng-container
		*ngIf="isLoaded && allLicensees.length > 0">

		<table
			mat-table
			[dataSource]="dataPage"
			class="mat-elevation-z6">

			<ng-container matColumnDef="givenName">
				<th mat-header-cell *matHeaderCellDef> Given name </th>
				<td
					class="selectable"
					mat-cell
					*matCellDef="let licensee">
					{{ licensee.givenName }}
				</td>
			</ng-container>

			<ng-container matColumnDef="familyName">
				<th mat-header-cell *matHeaderCellDef> Family name </th>
				<td
					class="selectable"
					mat-cell
					*matCellDef="let licensee">
					{{ licensee.familyName }}
				</td>
			</ng-container>

			<ng-container matColumnDef="email">
				<th mat-header-cell *matHeaderCellDef> Email </th>
				<td
					class="selectable"
					mat-cell
					*matCellDef="let licensee">
					{{ licensee.email }}
				</td>
			</ng-container>

			<ng-container matColumnDef="companyName">
				<th mat-header-cell *matHeaderCellDef> Company name </th>
				<td
					class="selectable"
					mat-cell
					*matCellDef="let licensee">
					{{ licensee.companyName }}
				</td>
			</ng-container>

			<ng-container matColumnDef="isActive">
				<th mat-header-cell *matHeaderCellDef> Is active </th>
				<td
					mat-cell
					*matCellDef="let licensee">
					<mat-checkbox
						[checked]="licensee.isActive"
						[disabled]="true">
					</mat-checkbox>
				</td>
			</ng-container>

			<tr
				mat-header-row
				*matHeaderRowDef="displayedColumns">
			</tr>
			<tr
				mat-row
				*matRowDef="let row; columns: displayedColumns;"
				(click)="onLicenseeClicked(row)">
			</tr>
		</table>
		<mat-paginator
			*ngIf="allLicensees.length > 0"
			[length]="allLicensees.length"
			[pageSizeOptions]="availablePageSizes"
			[pageSize]="pageSize"
			[pageIndex]="currentPage"
			(page)="onPage($event)"
			showFirstLastButtons
			aria-label="Select page of licensees"
			class="mat-elevation-z6">
		</mat-paginator>

	</ng-container>
</div>

import { Injectable } from '@angular/core';
import {
	HttpService,
} from '@brand-magic/lib-http';
import {
	IQuizData,
	IQuizPrice,
	IHttpResponse,
	QuizType,
} from '@brand-magic/lib-types';
import { StringUtil } from '@brand-magic/lib-util';
import { IAnnotatedQuizResults } from '@brand-magic/lib-types';
import { DateTime } from 'luxon';

@Injectable({
	providedIn: 'root'
})
export class QuizService {

	constructor(
		public httpService: HttpService,
	) {
	}

	public async getLatestQuiz(quizType: string): Promise<IHttpResponse<IQuizData>> {
		const requestData = {
			quizType: quizType,
		};

		const response = await this.httpService
			.post<any, IQuizData>('/api/get-latest-quiz', requestData);

		if (response == null || !response.success) {
			return response;
		}

		if (
			response.data != null &&
				(response.data.quizType === QuizType.Personal || response.data.quizType === QuizType.Business)
		) {
			const quiz = response.data;
			for (let question of quiz.questions) {
				if (question.hint == null) {
					question.hint = '';
				}
			}
		}

		return response;
	}

	public async getLatestPrices(): Promise<IHttpResponse<IQuizPrice>> {
		return this.getLatestQuiz(QuizType.Price) as unknown as Promise<IHttpResponse<IQuizPrice>>;
	}

	public async saveQuiz(quiz: IQuizData): Promise<string | null> {
		const requestData = {
			quiz: quiz,
		};

		const response = await this.httpService
			.post<any, string>('/api/save-quiz-data', requestData);

		if (!response.success) {
			console.error(response.helpMessage);
			return null;
		}

		return response.data;
	}

	public async getLatestPrice(): Promise<IHttpResponse<IQuizPrice>> {
		const requestData = {
		};

		const response = await this.httpService
			.post<any, IQuizPrice>('/api/get-latest-price', requestData);

		if (!response.success) {
			console.log('Unable to load price: ' + response.helpMessage);
		}

		return response;
	}

	public async savePrice(price: IQuizPrice): Promise<IHttpResponse<string>> {
		const requestData = {
			quizPrice: price,
		};

		const response = await this.httpService
			.post<any, string>('/api/save-quiz-price', requestData);

		if (!response.success) {
			console.error(response.helpMessage);
		}

		return response;
	}

	public async getAnnotatedQuizResults(leadId: string): Promise<IAnnotatedQuizResults[] | null> {
		const requestData = {
			leadId: leadId,
		};

		const response = await this.httpService
			.post<any, IAnnotatedQuizResults[]>('/api/get-annotated-quiz-results', requestData);

		if (!response.success) {
			console.error(response.helpMessage);
			return null;
		}

		return response.data;
	}

}

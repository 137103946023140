import {
	Component,
	Output,
	EventEmitter,
} from '@angular/core';
import {
	FormControl,
} from '@angular/forms';
import {
	StringUtil
} from '@brand-magic/lib-util';
import { PasswordRequestEvent } from './password-request.event';
import { PasswordFailureEvent } from './password-failure.event';
import { PasswordCancelEvent } from './password-cancel.event';

@Component({
	selector: 'brand-magic-change-password',
	templateUrl: './change-password.template.html',
	styleUrls: ['./change-password.style.scss'],
})
export class ChangePasswordComponent {
	public passwordControl = new FormControl('');
	public passwordConfirmControl = new FormControl('');

	@Output()
	public request: EventEmitter<PasswordRequestEvent> = new EventEmitter();

	@Output()
	public fail: EventEmitter<PasswordFailureEvent> = new EventEmitter();

	@Output()
	public cancel: EventEmitter<PasswordCancelEvent> = new EventEmitter();

	constructor(
	) {
	}

	public onCancel() {
		this.cancel.emit();
	}

	public onSubmit() {
		const helpMessages = [];

		const pw = this.passwordControl.value as string;
		const pwConfirm = this.passwordConfirmControl.value as string;
		if (StringUtil.nullOrEmpty(pw)) {
			helpMessages.push('Password is required');
		} else if (StringUtil.nullOrEmpty(pwConfirm)) {
			helpMessages.push('Password confirmation is required');
		} else if (pw != pwConfirm) {
			helpMessages.push('Password and Password confimation do not match');
		}

		if (helpMessages.length > 0) {
			const evt = new PasswordFailureEvent(helpMessages);
			this.fail.emit(evt);
			return;
		}

		const evt = new PasswordRequestEvent(pw);
		this.request.emit(evt);
	}

}

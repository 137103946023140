
<div class="container">
	<div class="resources-menu">
		<mat-selection-list [multiple]="false">
			<mat-list-option
				*ngFor="let personality of availablePersonalities"
				[selected]="selectedPersonality === personality"
				(click)="onPersonalitySelected(personality)">
				<div class="personality-select">
					<span class="svg" [innerHTML]="getSvg(personality)"></span>
					<span>The {{ personality.name }}</span>
				</div>
			</mat-list-option>
		</mat-selection-list>
	</div>

	<mat-divider vertical="true"></mat-divider>

	<div class="resources-content">
		<h1>Resources</h1>

		<h2
			*ngIf="!isPaid">
			This feature is only available for Magician and Wizard subscribers.
		</h2>

		<h3
			*ngIf="!isPaid">
			Please consider upgrading your subscription.
		</h3>

		<p
			class="paid-only"
			*ngIf="!isPaid">
			<a
				mat-raised-button
				routerLink="/pages/subscription">
				Upgrade your subscription
			</a>
		</p>

		<p
			[ngClass]="{'unavailable': !isPaid}">
			We have provided a number of resources to help you market
			your Brand Personalities test. Remember, this system is more than just
			the engaging test. It is an entire strategy to help you grow your
			business. <strong>These elements are allowed to be used as long as you are the
			correct level subscriber. If you choose to end or downgrade your
			subscription you will not be allowed to use any of our material in any
			form - even if you edit it.</strong> Please be respectful of the time and
			effort it has taken to collate this content over the years.
			Plagerising of our content will result in litigation as per the
			license agreement you have signed.
		</p>

		<h2
			*ngIf="selectedPersonality != null"
			[ngClass]="{'unavailable': !isPaid}">
			Resources for The {{ selectedPersonality.name }} personality type
		</h2>


		<div
			class="loading"
			*ngIf="isLoading">
			<mat-spinner></mat-spinner>
		</div>

		<div
			*ngIf="!isLoading && resources == null">
			<h2>Unable to load resources</h2>

			<p>
				Unfortunately we were not able to load any resources. Please try again later
				and we will try to fix this issue as soon as possible.
			</p>
		</div>

		<mat-card
			*ngIf="!isLoading && resources != null"
			[ngClass]="{'unavailable': !isPaid}">
			<mat-card-content class="resources">
				<mat-tab-group animationDuration="0ms">
					<mat-tab label="Icons">
						<div class="tab-content">
							<mat-list role="list">
								<mat-list-item
									*ngFor="let icon of resources.icons"
									role="listitem">
									<div class="item-display">
										<span>{{ icon }}</span>
										<button
											mat-stroked-button
											color="primary"
											(click)="onDownloadImage('icons', icon)">
											Download
										</button>
									</div>
								</mat-list-item>
							</mat-list>
						</div>
					</mat-tab>
					<mat-tab label="Attributes">
						<div class="tab-content">
							<mat-list role="list">
								<mat-list-item
									*ngFor="let attribute of resources.attributes"
									role="listitem">
									<div class="item-display">
										<span>{{ attribute }}</span>
										<button
											mat-stroked-button
											color="primary"
											(click)="onDownloadImage('attributes', attribute)">
											Download
										</button>
									</div>
								</mat-list-item>
							</mat-list>
						</div>
					</mat-tab>
					<mat-tab label="Colours">
						<div class="tab-content">
							<mat-list role="list">
								<mat-list-item
									*ngFor="let colour of resources.colours"
									role="listitem">
									<div class="item-display">
										<span>{{ colour }}</span>
										<button
											mat-stroked-button
											color="primary"
											(click)="onDownloadImage('colours', colour)">
											Download
										</button>
									</div>
								</mat-list-item>
							</mat-list>
						</div>
					</mat-tab>
					<mat-tab label="Information">
						<div class="tab-content">
							<mat-list role="list">
								<mat-list-item
									*ngFor="let inf of resources.information"
									role="listitem">
									<div class="item-display">
										<span>{{ inf }}</span>
										<button
											mat-stroked-button
											color="primary"
											(click)="onDownloadImage('information', inf)">
											Download
										</button>
									</div>
								</mat-list-item>
							</mat-list>
						</div>
					</mat-tab>
					<mat-tab label="Layouts">
						<div class="tab-content">
							<mat-list role="list">
								<mat-list-item
									*ngFor="let layout of resources.layouts"
									role="listitem">
									<div class="item-display">
										<span>{{ layout }}</span>
										<button
											mat-stroked-button
											color="primary"
											(click)="onDownloadImage('layouts', layout)">
											Download
										</button>
									</div>
								</mat-list-item>
							</mat-list>
						</div>
					</mat-tab>
					<mat-tab label="Quotes">
						<div class="tab-content">
							<mat-list role="list">
								<mat-list-item
									*ngFor="let quote of resources.quotes"
									role="listitem">
									<div class="item-display">
										<span>{{ quote }}</span>
										<button
											mat-stroked-button
											color="primary"
											(click)="onDownloadImage('quotes', quote)">
											Download
										</button>
									</div>
								</mat-list-item>
							</mat-list>
						</div>
					</mat-tab>
				</mat-tab-group>
			</mat-card-content>
		</mat-card>
	</div>
</div>

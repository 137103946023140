import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { IEmail } from '@brand-magic/lib-types';
import { EmailService } from '../../services/email.service';
import { Toolbar } from '../../toolbar';

@Component({
	selector: 'brand-magic-refer-a-colleague',
	templateUrl: './refer-a-colleague.template.html',
	styleUrls: ['./refer-a-colleague.style.scss'],
})
export class ReferAColleagueComponent {
	public htmlEditorOptions: any = Toolbar.options;

	public email = '';
	public subject = '';
	public message = `<p>
I have been using the Brand Magic license to help me grow my business.
I think you would love it as it has really helped me offer a unique strategy
for my clients. It uses Carl Jung's 12 archetypes to help determine the
personality of a client's brand. It is interactive, intuitive and
intriguing. It is a fantastic way to engage clients and to help them keep
focused and on brand.
</p>

<p>
<a href="https://brandmagic.com.au/licensee/">Take a look</a> and let me know what you think.
</p>`;

	public isWorking = false;
	public showResponse = false;
	public emailSuccess = false;
	public emailError: string | null = null;

	constructor(
		private router: Router,
		private emailService: EmailService,
	) {
	}

	public async onSendClicked() {
		const fromAddressee = {
			name: 'Brand Magic',
			email: 'info@brandmagic.com.au'
		};
		const toAddressee = {
			email: this.email
		};
		const email: IEmail = {
			from: fromAddressee,
			to: [toAddressee],
			subject: this.subject,
			html: this.message
		};

		this.isWorking = true;
		let response;
		try {
			response = await this.emailService.sendEmail(email);
			this.isWorking = false;
			this.emailSuccess = response.success;
			this.emailError = response.helpMessage;
		} catch (exception: any) {
			this.emailSuccess = false;
			this.emailError = 'Unfortunately we were not able to send your reference at this time: ' + exception.toString();
		}
		this.isWorking = false;
		this.showResponse = true;
	}

	public onHomeClicked() {
		this.router.navigateByUrl('/pages/home');
	}
}

<mat-form-field class="editor" class="editor">
	<mat-label>Test results body</mat-label>
	<textarea
		[(ngModel)]="personalisation.pages.quizResults.body"
		[froalaEditor]="htmlEditorOptions"
		matInput>{{ personalisation.pages.quizResults.body }}</textarea>
	<mat-error>Test results body is <strong>required</strong></mat-error>
</mat-form-field>

<div class="preview-container">
	<h3>Test results preview</h3>

	<div class="preview-options">
		<mat-form-field appearance="fill">
			<mat-label>Selected personality</mat-label>
			<mat-select
				[ngModel]="selectedPersonalityGroup"
				(selectionChange)="onSelectionChanged($event)">
				<mat-option *ngFor="let group of availablePersonalityControlGroups" [value]="group">
					{{ group.personality.name }}
				</mat-option>
			</mat-select>
		</mat-form-field>
	</div>

	<div
		class="preview"
		[ngStyle]="{
			'background-color': personalisation.color.primary.backgroundColor,
			'color': personalisation.color.primary.color
		}">
		<div
			*ngIf="buttonCss != null"
			[innerHTML]="buttonCss">
		</div>
		<div class='submit-container'>
			<div [innerHTML]="getLinkStyle()">
			</div>
			<div
				class='personality-display'
				[ngStyle]="{ 'background-color': getHeroBackground() }">
				<div class="personality-hero-img">
					<div class="img"
						 [innerHtml]="getSelectedSvg()">
					</div>
					<div class="hero primary" [ngStyle]="{'color': getHeroContrast() }">
						The {{ selectedPersonalityGroup.personality.name }}
					</div>
				</div>
			</div>
			<div
				class="hero-text">
				<h1
					[ngStyle]="{ 'background-color': getHeroStripBackground(), 'color': getHeroStripContrast() }">
					Congratulations, {{ replacementValues.PLAYER_NAME }}! You are The {{ selectedPersonalityGroup.personality.name }}!
				</h1>
			</div>
			<div class="personality-text">
				<div
					class="licensee-content">
					<span [innerHtml]="personalisation.pages.quizResults.body | preview:replacementValues">
					</span>

					<div class="action-buttons">
						<a class="cta-button cta1" href="#">Play again</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

import {
	Component,
	Input,
} from '@angular/core';
import { Toolbar } from '../../toolbar';
import {
	ILicenseePersonalisation,
} from '@brand-magic/lib-types';

@Component({
	selector: 'brand-magic-personalisation-personaldetails-edit',
	templateUrl: './personalisation-personaldetails-edit.template.html',
	styleUrls: ['./personalisation-personaldetails-edit.style.scss'],
})
export class PersonalisationPersonalDetailsEditComponent {
	public htmlEditorOptions: any = Toolbar.options;

	@Input()
	public personalisation!: ILicenseePersonalisation;

	constructor(
	) {
	}

}

import { Injectable } from '@angular/core';
import {
	ActivatedRoute,
	Router,
} from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import {
	AuthService,
} from '@brand-magic/lib-auth';
import { StringUtil } from '@brand-magic/lib-util';
import {
	HttpService,
} from '@brand-magic/lib-http';
import {
	EmailService,
} from './email.service';
import {
	IEmail,
	ILicensee,
	ICreateLicenseeRequest,
	IUpdateLicenseeRequest,
	IUpdateLicenceRequest,
	ISubscriptionLevel,
	IHttpResponse,
} from '@brand-magic/lib-types';
import { UpgradeSubscriptionDialog } from '../components/upgrade-subscription/upgrade-subscription.dialog';
import { CompleteAccountDialog } from '../components/complete-account/complete-account.dialog';

import { SubscriptionService } from './subscription.service';
import { StripeService } from './stripe.service';
import { ICachingService } from './caching-service.interface';

@Injectable({
	providedIn: 'root'
})
export class LicenseeService implements ICachingService {
	private cachingEnabled = true;
	private currentLicensee: ILicensee | null = null;
	private currentAccount: any = null;

	private subscriptionQueried = false;
	private isPaidResponse: IHttpResponse<boolean> = {
		success: false,
		helpMessage: null,
		data: false
	};

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		public dialog: MatDialog,
		private authService: AuthService,
		public httpService: HttpService,
		private subscriptionService: SubscriptionService,
		private stripeService: StripeService,
		private emailService: EmailService,
	) {
	}

	public async isPaidSubscriber(
	): Promise<IHttpResponse<boolean>> {
		if (this.subscriptionQueried && this.cachingEnabled) {
			return this.isPaidResponse;
		}
		const subscriptionLevelResponse = await this.getCurrentSubscriptionLevel();
		if (!subscriptionLevelResponse.success) {
			return subscriptionLevelResponse as unknown as IHttpResponse<boolean>;
		}
		const subscriptionLevel = subscriptionLevelResponse.data as ISubscriptionLevel;
		this.isPaidResponse = {
			success: true,
			helpMessage: null,
			data: !subscriptionLevel.isFree
		};
		this.subscriptionQueried = true;
		return this.isPaidResponse;
	}

	public async arePayoutsAvailable(): Promise<IHttpResponse<boolean>> {
		const response: IHttpResponse<boolean> = {
			success: false,
			helpMessage: null,
			data: null
		};
		const accountResponse = await this.getCurrentAccount();
		if (accountResponse.success && accountResponse.data == null) {
			response.success = true;
			response.data = false;
			return response;
		}

		if (!accountResponse.success) {
			return accountResponse;
		}

		response.data = accountResponse?.data?.charges_enabled ?? false;
		response.success = true;
		return response;
	}

	public showPaidSubscriberWarning() {
		this.dialog.open(UpgradeSubscriptionDialog, {
			width: '800px',
			height: '250px',
			data: {},
		});
	}

	public showAccountRequiredWarning() {
		this.dialog.open(CompleteAccountDialog, {
			width: '800px',
			height: '400px',
			data: {},
		});
	}

	public async insertAccount(
		licensee: ILicensee,
		password: string
	): Promise<IHttpResponse<ILicensee>> {
		if (StringUtil.nullOrEmpty(licensee.subscriptionId)) {
			const defaultLevelResponse = await this.subscriptionService.getDefaultSubscriptionLevel();
			if (!defaultLevelResponse.success) {
				return defaultLevelResponse as unknown as IHttpResponse<ILicensee>;
			}
			const lvl = defaultLevelResponse.data as ISubscriptionLevel;
			if (this.cachingEnabled) {
				licensee.subscriptionId = lvl.subscriptionLevelId;
			}
		}

		const requestData = {
			licensee: licensee,
			password: password
		};

		const response = await this.httpService
			.post<ICreateLicenseeRequest, ILicensee>('/api/create-account', requestData);

		if (response.success) {
			const licensee = response.data as ILicensee;
			await this.sendNewLicenseeEmail(licensee);
			await this.sendLicenseeEmailToAdmin(licensee);

			const loginRequest = {
				email: licensee.email,
				password: password
			};

			const loginResponse = await this.authService.login(loginRequest);
			if (loginResponse.success) {
				const licenseeResponse = await this.getLicenseeFromAuth();
				if (!licenseeResponse.success) {
					console.log('Unable to load licensee from auth: ' + licenseeResponse.helpMessage);
				}
				this.currentLicensee = licenseeResponse.data as ILicensee;
			} else {
				console.log(`Unable to login using newly created account information`);
			}


		}

		return response;
	}

	private async sendLicenseeEmailToAdmin(licensee: ILicensee): Promise<IHttpResponse<any>> {
		console.log('Licensee: ' + JSON.stringify(licensee, null, '\t'));
		const subject = 'A new licensee has signed up!';
		const body = `<p>A new licensee has joined Brand Magic.</p>

<p><a href="https://admin.brandmagic.com.au/admin/licensee/${licensee.licenseeId}">Click here</a> to view their full details</p>

<p>Licensee overview:</p>

<table border="0">
	<tr>
		<td align="right">Name: </td>
		<td>${licensee.givenName} ${licensee.familyName}</td>
	</tr>
	<tr>
		<td align="right">Email: </td>
		<td>${licensee.email}</td>
	</tr>
</table>
`;

		const fromAddressee = {
			name: 'Brand Magic',
			email: 'info@brandmagic.com.au',
		};

		const toAddressee = {
			name: 'Brand Magic',
			email: 'magic@brandmagic.com.au',
		};
		const email: IEmail = {
			from: fromAddressee,
			to: [toAddressee],
			subject: subject,
			html: body
		};

		let emailResponse;
		try {
			emailResponse = await this.emailService.sendEmail(email);
		} catch (exception: any) {
			console.error('Unable to send email to ' + licensee.email);
		}

		if (emailResponse == null) {
			emailResponse = {
				success: false,
				helpMessage: 'Unable to send admin email',
				data: null,
			};
		}

		return emailResponse;
	}

	private async sendNewLicenseeEmail(licensee: ILicensee): Promise<IHttpResponse<any>> {
		const subject = 'Welcome to Brand Personalities';
		const body = `Hi ${licensee.givenName},

<h2>CONGRATULATIONS!</h2>

<p>
	You are now the proud (fully legal) licensee of
	<b>Brand Personalities</b> which is a Brand Magic&reg; system
</p>

<p>
	With Brand Personalities, you will be able to:
</p>

<ul>
	<li>access the code to embed the test onto your website</li>
	<li>view how many leads you have generated</li>
	<li>personalise email results (Magician and Wizard levels)</li>
	<li>personalise on screen results messages (Magician and Wizard levels)</li>
	<li>download your leads contact details (Magician and Wizard levels)</li>
	<li>view how much revenue you have generated (Magician and Wizard levels)</li>
	<li>update your accounting details (to receive your commission)</li>
	<li>update your profile</li>
	<li>view the license terms and conditions</li>
</ul>

<p>
	Don't forget to join our <a href="https://www.facebook.com/groups/358647925206323/">Facebook community</a>
	of like-minded creatives to connect to collaborate with.
</p>

<p>
	To get you set up and with some knowledge around Brand Personalities I have a FREE
	[5 Day Ready to Launch Program] https://learn.brandmagic.com.au/ready-to-launch for you.
</p>

<p>
	In this online course you will learn:<br />
	DAY 1 - Understanding Brand Personalities - learn more about the origins of the system and how to talk about it.
		Get an overview of the 12 personalities and what that means for you and your client.<br />
	DAY 2 - Define your perfect customer - knowing exactly who you want to work with will ensure that
		you target the right people - remember, not everyone is your client and neither should they be.<br />
	DAY 3 - Landing page creation - today is our ‘doing-day’. We will come up with a layout and
		customer journey to get your landing page up and we will add the quiz code to your website. Get ready - this is a big one!<br />
	DAY 4 - Customer nurturing - lets talk about what happens after your lead has played the quiz.
		What next? Today we will look at email sequences and how to nurture your customer.<br />
	DAY 5 - Create a rock solid marketing plan - how to now market your Brand Personalities
		quiz and service offering to your current and potential customers.<br />
</p>

<p>
	By signing up to Brand Magic you are joining a creative community designed to support,
	educate and collaborate with. The more you put in, the more you will get out.
</p>

<p>
	We want to see you enjoying your work,
	getting paid your worth and having a thriving business.
</p>

<p>
	The time has come to transform your business.
</p>

<h3>
	Let's create some Brand Magic!
</h3>
`;

		const fromAddressee = {
			name: 'Brand Magic',
			email: 'info@brandmagic.com.au',
		};
		const toAddressee = {
			name: licensee.givenName,
			email: licensee.email
		};
		const email: IEmail = {
			from: fromAddressee,
			to: [toAddressee],
			subject: subject,
			html: body
		};

		let emailResponse;
		try {
			emailResponse = await this.emailService.sendEmail(email);
		} catch (exception: any) {
			console.error('Unable to send email to ' + licensee.email);
		}

		if (emailResponse == null) {
			emailResponse = {
				success: false,
				helpMessage: 'Unable to send admin email',
				data: null,
			};
		}

		return emailResponse;
	}

	public async getCurrentSubscriptionLevel(
	): Promise<IHttpResponse<ISubscriptionLevel>> {
		const licenseeResponse = await this.getCurrentLicensee();
		if (!licenseeResponse.success) {
			return {
				success: false,
				helpMessage: 'We could not load your details at this time: ' + licenseeResponse.helpMessage,
				data: null,
			};
		}

		const subscriptionId = licenseeResponse.data?.subscriptionId as string;
		return await this.subscriptionService.getSubscriptionLevelById(subscriptionId)
	}

	public async getSubscriptionIntent(
		subscriptionId: string,
	): Promise<IHttpResponse<any>> {
		if (StringUtil.nullOrEmpty(subscriptionId)) {
			return {
				success: false,
				helpMessage: 'No subscription id was provided for update',
				data: null
			};
		}

		const requestData = {
			subscriptionId: subscriptionId
		};

		const response = await this.httpService
			.post<any, any>('/api/get-subscription-intent', requestData);

		if (response.success) {
			this.currentLicensee = null;
		} else {
			console.log('Unable to get subscription intent: ' + response.helpMessage);
		}

		return response;
	}

	// Changes the subscription level in our database and in Stripe
	public async updateSubscription(
		subscriptionId: string
	): Promise<IHttpResponse<any>> {
		const request: any = {
			subscriptionId: subscriptionId,
		};

		const updateResponse = await this.httpService
			.post<IUpdateLicenseeRequest, null>('/api/update-licensee-subscription', request);

		this.clearCaches();

		return updateResponse;
	}

	public async updateLicenseeDetails(
		licenseeData: any
	): Promise<IHttpResponse<any>> {
		const licenseeResponse = await this.getCurrentLicensee();
		if (!licenseeResponse.success) {
			return {
				success: false,
				helpMessage: 'Your details could not be loaded: ' + licenseeResponse.helpMessage,
				data: null,
			};
		}

		const licensee = licenseeResponse.data as ILicensee;
		return this.updateLicenseeById(licensee.licenseeId, licenseeData);
	}

	public async updateLicenseeById(
		licenseeId: string,
		licenseeData: any,
	): Promise<IHttpResponse<null>> {
		const request: IUpdateLicenseeRequest = {
			licenseeId: licenseeId,
			updateData: licenseeData
		};

		const response = await this.httpService
			.post<IUpdateLicenseeRequest, null>('/api/update-licensee', request);

		if (!response.success) {
			return response;
		}

		this.subscriptionQueried = false;

		this.clearCaches();

		return response;
	}

	public async getCurrentLicensee(): Promise<IHttpResponse<ILicensee>> {
		const response: IHttpResponse<ILicensee> = {
			success: false,
			helpMessage: null,
			data: null
		}

		if (!this.authService.isLoggedIn()) {
			this.authService.setRedirectUrl(this.route.snapshot.url.join(''));
			this.router.navigateByUrl('/auth/login');
			response.helpMessage = 'You need to be logged in to perform this action';
			return response;
		}

		if (this.currentLicensee == null || !this.cachingEnabled) {
			const licenseeResponse = await this.getLicenseeFromAuth();
			if (!licenseeResponse.success) {
				console.log('Unable to load licensee: ' + licenseeResponse.helpMessage);
			}
			this.currentLicensee = licenseeResponse.data;
		}

		if (this.currentLicensee == null) {
			this.router.navigateByUrl('/auth/create-account');
			response.helpMessage = 'You need to be logged in to perform this action';
			return response;
		}

		response.data = this.currentLicensee;
		response.success = response.data != null;
		return response;
	}

	public async getCurrentAccount(): Promise<IHttpResponse<any>> {
		if (this.currentAccount != null && this.cachingEnabled) {
			return {
				success: true,
				helpMessage: null,
				data: this.currentAccount
			};
		}

		const licenseeResponse = await this.getCurrentLicensee();
		if (!licenseeResponse.success) {
			console.log('Unable to load account details: ' + licenseeResponse.helpMessage);
			return licenseeResponse;
		}
		const licensee = licenseeResponse.data as ILicensee

		if (StringUtil.nullOrEmpty(licensee?.stripe?.accountId)) {
			return {
				success: true,
				helpMessage: 'No account has been created yet',
				data: null
			};
		}

		const accountResponse = await this.stripeService.loadAccount(licensee?.stripe?.accountId as string);

		if (!accountResponse.success) {
			console.log('Unable to load account from stripe: ' + accountResponse.helpMessage as string);
			return accountResponse;
		}

		this.currentAccount = accountResponse.data;
		return accountResponse;
	}

	public async cancelSubscription(): Promise<IHttpResponse<any>> {
		const licenseeResponse = await this.getCurrentLicensee();

		if (!licenseeResponse.success) {
			return {
				success: false,
				helpMessage: 'We were not able to load your current subscription information: ' + licenseeResponse.helpMessage,
				data: null
			}
		}

		const licensee = licenseeResponse.data as ILicensee;
		const request: any = {
			subscriptionId: licensee.stripe.subscriptionId,
		};

		const response = await this.httpService
			.post<IUpdateLicenseeRequest, null>('/api/cancel-subscription', request);

		if (response.success) {
			const defaultLevelResponse = await this.subscriptionService.getDefaultSubscriptionLevel();
			const lvl = defaultLevelResponse.data;
			const updateResponse = await this.updateLicenseeDetails({
				subscriptionId: lvl?.subscriptionLevelId
			});

			if (!updateResponse.success) {
				response.success = false;
				response.data = null;
				response.helpMessage = updateResponse.helpMessage;
			}
		} else {
			console.error('Unable to cancel subscription: ' + response.helpMessage);
		}

		this.clearCaches();

		return response;
	}

	private async getLicenseeFromAuth(): Promise<IHttpResponse<ILicensee>> {
		const response = await this.httpService
			.get<ILicensee>('/api/get-licensee-from-auth');

		if (!response.success) {
			console.error(response.helpMessage as string);
		}

		return response;
	}

	public async getAllLicensees(): Promise<IHttpResponse<ILicensee[]>> {
		const licenseeResponse = await this.getCurrentLicensee();
		if (!licenseeResponse.success) {
			return {
				success: false,
				helpMessage: 'Unable to load current licensee: ' + licenseeResponse.helpMessage,
				data: null
			};
		}
		const currentLicensee = licenseeResponse.data as ILicensee;
		if (!currentLicensee.isAdmin) {
			return {
				success: false,
				helpMessage: 'You don\'t have permission to perform this operation',
				data: null
			};
		}

		const response = await this.httpService
			.get<ILicensee[]>('/api/get-all-licensees');

		if (!response.success) {
			console.error(response.helpMessage);
		}

		return response;
	}

	public async getLicenseeById(licenseeId: string): Promise<IHttpResponse<ILicensee>> {
		const request = {
			licenseeId: licenseeId
		};

		const response = await this.httpService
			.post<any, ILicensee>('/api/get-licensee-by-id', request);

		if (!response.success) {
			console.error(response.helpMessage);
		}

		return response;
	}

	public async updateLicenceAgreement(
		licenceContent: string
	): Promise<null | string> {
		const request: IUpdateLicenceRequest = {
			licence: licenceContent
		};

		const response = await this.httpService
			.post<any, null>('/api/update-licence', request);

		if (!response.success) {
			console.error(response.helpMessage);
		}

		return null;
	}

	public clearCaches() {
		this.currentLicensee = null;
		this.currentAccount = null;
		this.subscriptionQueried = false;
	}

	public enableCaching(isEnabled: boolean) {
		this.cachingEnabled = isEnabled;
	}
}

<div class="container">

	<div [ngClass]="isLoading ? 'visible' : 'hidden'">
		<h1 *ngIf="isTransferringToStripe">
			Transferring you to Stripe!
		</h1>

		<h1 *ngIf="!isTransferringToStripe">
			Loading subscription details
		</h1>

		<div class="loading">
			<mat-spinner></mat-spinner>
		</div>
	</div>

	<div [ngClass]="isLoading ? 'hidden' : 'visible'">

		<div [ngClass]="subscriptionStatus !== SubscriptionStatus.PAID_COMPLETE && subscriptionStatus !== SubscriptionStatus.FREE_COMPLETE ? 'visible' : 'hidden'">
			<ng-container *ngIf="subscriptionStatus === SubscriptionStatus.UNKNOWN">
				<h1>We were unable to determine your current subscription</h1>

				<brand-magic-error-display
					[header]="errorMessage"
					[errors]="errors">
				</brand-magic-error-display>

				<p>
					Unfortunately, we were unable to determine your account subscription level.
				</p>

				<p>
					Please try to <a routerLink="/auth/login">login again</a> and see if the problem persists.
				</p>
			</ng-container>


			<div [ngClass]="subscriptionStatus === SubscriptionStatus.UPGRADING || subscriptionStatus === SubscriptionStatus.DOWNGRADING ? 'visible' : 'hidden'">
					<h1>Become a Brand Magic {{ selectedSubscriptionLevel?.name }}</h1>

					<brand-magic-error-display
						[header]="errorMessage"
						[errors]="errors">
					</brand-magic-error-display>

					<div
						class="payment"
						*ngIf="!selectedSubscriptionLevel?.isFree && elementsOptions?.clientSecret as clientSecret">
						<h3
							class="selected-cost">
							Cost:
							<span
								*ngIf="selectedSubscriptionLevel?.dollarsPerMonth === 0">
								Free
							</span>
							<span
								*ngIf="selectedSubscriptionLevel?.dollarsPerMonth !== 0">
								${{ selectedSubscriptionLevel?.dollarsPerMonth }} per month
							</span>
						</h3>

						<ng-container>
							<ngx-stripe-payment [clientSecret]="elementsOptions?.clientSecret ?? ''">
							</ngx-stripe-payment>
						</ng-container>

						<div class="action-buttons">
							<button
								color="primary"
								mat-raised-button
								(click)="onMakePaymentClicked()">
								<mat-icon>shopping_cart</mat-icon>
								Make payment
							</button>
						</div>
					</div>
			</div>

			<ng-container
				*ngIf="subscriptionStatus === SubscriptionStatus.UNSUBSCRIBING">
				<h3>Downgrade your subscription</h3>

				<brand-magic-error-display
					[header]="errorMessage"
					[errors]="errors">
				</brand-magic-error-display>

				<p>
					Are you sure you wish to cancel your current subscription? If you do so,
					you will no longer be receive commissions on test plays or be able to
					access your lead data.
				</p>

				<div class="action-buttons">
					<button
						color="primary"
						mat-raised-button
						(click)="onDowngradeClicked()">
						<mat-icon>cancel</mat-icon>
						Cancel your current subscription
					</button>
				</div>
			</ng-container>
		</div>


		<ng-container *ngIf="subscriptionStatus === SubscriptionStatus.PAID_COMPLETE">
			<!-- Subscription is paid - need to check for Stripe account -->

			<ng-container *ngIf="stripeStatus === StripeStatus.UNKNOWN">

				<h1>We were unable to determine your Stripe account status</h1>

				<brand-magic-error-display
					[header]="errorMessage"
					[errors]="errors">
				</brand-magic-error-display>

				<p>
					Unfortunately, we were unable to determine your account status in stripe.
					This is most likely a result of your login timing out.
				</p>

				<p>
					Please try to <a routerLink="/auth/login">login again</a> and see if the problem persists.
				</p>
			</ng-container>

			<ng-container
				*ngIf="stripeStatus === StripeStatus.NO_ACCOUNT">

				<h1>Create an account to start earning commissions</h1>

				<brand-magic-error-display
					[header]="errorMessage"
					[errors]="errors">
				</brand-magic-error-display>

				<p>
					We use <a href="https://stripe.com/en-au/connect" target="_blank">Stripe</a> to reconcile our accounts
					and pay commissions to our licensees.
				</p>

				<p>
					Before we are able to pay your commissions, we need for you to create an account with Stripe.
					You will be asked to provide your banking details, but don't worry - your details a securely
					protected by Stripe, and we will not have access to any of your banking details.
				</p>

				<div class="action-buttons">
					<button
						color="primary"
						mat-raised-button
						(click)="onCreateConnectedAccount()">
						<mat-icon>open_in_browser</mat-icon>
						Create a Stripe account
					</button>
				</div>
			</ng-container>

			<ng-container
				*ngIf="stripeStatus === StripeStatus.INCOMPLETE">

				<h1>Add your banking details to Stripe to start earning commissions!</h1>

				<brand-magic-error-display
					[header]="errorMessage"
					[errors]="errors">
				</brand-magic-error-display>

				<p>
					You can now access your leads, but before we can pay you
					commissions, we need you to create an account
					in Stripe.
				</p>

				<p>
					Please note that you will need to provide documentation to
					verify your identity and business details.
				</p>

				<div class="action-buttons">
					<button
						mat-raised-button
						color="primary"
						(click)="onCompleteAccount()">
						<mat-icon>open_in_browser</mat-icon>
						Complete Stripe integration
					</button>
				</div>
			</ng-container>


			<ng-container
				*ngIf="stripeStatus === StripeStatus.COMPLETE">

				<brand-magic-error-display
					[header]="errorMessage"
					[errors]="errors">
				</brand-magic-error-display>

				<h1>Congratulations!</h1>

				<p>
					You are now setup to receive commissions when you customers play your test!
				</p>

				<p>
					To get started, simply
					<a
						routerLink="/pages/embedding-codes">
						go to the Ebedding Codes page
					</a>,
					follow the instructions and start receiving your commissions.
				</p>
			</ng-container>
		</ng-container>

	</div>
</div>

<div class="container">

	<h1>Your leads</h1>

	<h2
		*ngIf="!isPaid">
		This feature is only available for Magician and Wizard subscribers.
	</h2>

	<h3
		*ngIf="!isPaid">
		Please consider upgrading your subscription.
	</h3>

	<p
		class="paid-only"
		*ngIf="!isPaid">
		<a
			mat-raised-button
			routerLink="/pages/subscription">
			Upgrade your subscription
		</a>
	</p>


	<div
		*ngIf="!hasLeads()"
		[ngClass]="{'unavailable': !isPaid}">
		<h2>
			You don't have any leads yet :(
		</h2>

		<p>
			Don't worry, we are sure they are on their way!
		</p>

		<p style="margin: 40px 0;">
			<img src="/assets/mr-bean-waiting.gif" />
		</p>

		<p>
			In the mean time, be sure to advertise your test on your socials!
		</p>
	</div>

	<ng-container
		*ngIf="hasLeads()">

		<div [ngClass]="{'unavailable': !isPaid, 'header-row': true}">
			<div class="selection-display">
				{{ getSelectedCount() }} leads selected
			</div>
			<div
				class="button-row">
				<button
					color="primary"
					mat-stroked-button
					(click)="onExportClicked()">
					Export
				</button>

			</div>
		</div>

		<table
			mat-table
			[dataSource]="dataSource"
			[ngClass]="{'unavailable': !isPaid, 'mat-elevation-z6': true}">

			<ng-container matColumnDef="select">
				<th mat-header-cell *matHeaderCellDef>
					<button
						mat-icon-button
						[matMenuTriggerFor]="menu"
						aria-label="expand to select visible or select all">
						<mat-icon>indeterminate_check_box</mat-icon>
					</button>
					<mat-menu
						#menu="matMenu">
						<button
							mat-menu-item
							color="primary"
							(click)="onSelectAll()">
							<mat-icon>library_add_check</mat-icon>
							<span>Select all</span>
						</button>
						<button
							mat-menu-item
							color="primary"
							(click)="onSelectVisible()">
							<mat-icon>check_box</mat-icon>
							<span>Select visible</span>
						</button>
						<button
							mat-menu-item
							color="primary"
							(click)="onSelectNone()">
							<mat-icon>check_box_outline_blank</mat-icon>
							<span>Select none</span>
						</button>
					</mat-menu>
				</th>
				<td mat-cell *matCellDef="let lead">
					<mat-checkbox
						[(ngModel)]="lead.isSelected">
					</mat-checkbox>
				</td>
			</ng-container>

			<ng-container matColumnDef="name">
				<th mat-header-cell *matHeaderCellDef> Name </th>
				<td
					(click)="onLeadClicked(lead)"
					class="selectable"
					mat-cell
					*matCellDef="let lead">
					{{ lead.givenName }} {{ lead.familyName }}
				</td>
			</ng-container>

			<ng-container matColumnDef="email">
				<th mat-header-cell *matHeaderCellDef> Email </th>
				<td
					(click)="onLeadClicked(lead)"
					class="selectable"
					mat-cell
					*matCellDef="let lead">
					{{ lead.email }}
				</td>
			</ng-container>

			<ng-container matColumnDef="businessName">
				<th mat-header-cell *matHeaderCellDef> Business name </th>
				<td
					(click)="onLeadClicked(lead)"
					class="selectable"
					mat-cell
					*matCellDef="let lead">
					{{ lead.businessName }}
				</td>
			</ng-container>

			<ng-container matColumnDef="interest">
				<th mat-header-cell *matHeaderCellDef> Interest </th>
				<td
					(click)="onLeadClicked(lead)"
					class="selectable"
					mat-cell
					*matCellDef="let lead">
					{{ lead.interest }}
				</td>
			</ng-container>

			<ng-container matColumnDef="playsRemaining">
				<th mat-header-cell *matHeaderCellDef> Plays remaining </th>
				<td
					(click)="onLeadClicked(lead)"
					class="selectable"
					mat-cell
					*matCellDef="let lead">
					{{ lead.playsRemaining }}
				</td>
			</ng-container>

			<ng-container matColumnDef="datePlayed">
				<th mat-header-cell *matHeaderCellDef> Date played </th>
				<td
					(click)="onLeadClicked(lead)"
					class="selectable"
					mat-cell
					*matCellDef="let lead">
					{{ dateToString(lead.creationDate) }}
				</td>
			</ng-container>

			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr [ngClass]="{'new-lead': isNewLead(row), 'lead-row': true }" mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
		</table>

		<mat-paginator
			[pageSizeOptions]="availablePageSizes"
			pageSize="10"
			showFirstLastButtons
			aria-label="Select page of leads"
			[ngClass]="{'unavailable': !isPaid, 'mat-elevation-z6': true}">
		</mat-paginator>

		<div
			[ngClass]="{'unavailable': !isPaid}"
			class="button-row bottom">
			<button
				color="primary"
				mat-stroked-button
				(click)="onExportClicked()">
				Export
			</button>
		</div>

	</ng-container>

</div>

import { Component } from '@angular/core';
import {
	Router,
	ActivatedRoute,
	ActivatedRouteSnapshot,
	NavigationEnd,
} from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { BreakpointObserver, } from '@angular/cdk/layout';
import { distinctUntilChanged } from 'rxjs/operators';
import { AuthService } from '@brand-magic/lib-auth';
import { ILicensee } from '@brand-magic/lib-types';
import { LicenseeService } from '../services/licensee.service';
import { MenuService } from '../services/menu.service';

@Component({
	selector: 'brand-magic-admin-shell',
	templateUrl: './admin-shell.template.html',
	styleUrls: ['./admin-shell.style.scss'],
})
export class AdminShellComponent {
	public atLeast900 = false;
	public atLeast700 = false;

	private licenseeLoaded = false;

	readonly breakpoint$ = this.breakpointObserver
		.observe(['(min-width: 900px)', '(min-width: 700px)'])
		.pipe(
			distinctUntilChanged()
		);

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private breakpointObserver: BreakpointObserver,
		private authService: AuthService,
		private licenseeService: LicenseeService,
		public menuService: MenuService,
	) {
		this.authService.onLogout(() => {
			this.licenseeLoaded = false;
		});
	}

	ngOnInit(): void {
		this.breakpoint$.subscribe(() =>
			this.breakpointChanged()
		);
	}

	private breakpointChanged() {
		this.atLeast900 = true;
		this.atLeast700 = true;

		if (this.breakpointObserver.isMatched('(min-width: 900px)')) {
		} else if (this.breakpointObserver.isMatched('(min-width: 700px)')) {
			this.atLeast900 = false;
		} else  {
			this.atLeast900 = false;
			this.atLeast700 = false;
		}

		this.menuService.isOpenned = this.atLeast700;
	}

}

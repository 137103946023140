
<div class="container">
	<div class="details-menu">
		<mat-selection-list [multiple]="false">
			<mat-list-option
				*ngFor="let option of availableOptions"
				[selected]="selectedOption === option"
				(click)="onMenuItemSelected(option)">
				{{ option.text }}
			</mat-list-option>
		</mat-selection-list>
	</div>

	<mat-divider vertical="true"></mat-divider>

	<div class="details-content">
		<ng-container
			*ngIf="selectedOption.code === 'contact'">

			<ng-container
				*ngIf="licensee == null">
				<h1>Retrieving your details</h1>

				<p>
					We are still loading your account details.
				</p>

				<div
					class="loading">
					<mat-spinner></mat-spinner>
				</div>
			</ng-container>

			<brand-magic-licensee-edit
				*ngIf="licensee != null"
				[licensee]="licensee"
				(save)="onUpdateLicensee($event)">
			</brand-magic-licensee-edit>
		</ng-container>

		<ng-container
			*ngIf="selectedOption.code === 'payment'">
			<h1>Payment details</h1>

			<a
				href="https://dashboard.stripe.com/{{ licensee.stripe.accountId  }}/dashboard"
				target="_blank">
				View your account details
			</a>
		</ng-container>

		<ng-container
			*ngIf="selectedOption.code === 'licence'">

			<div
				*ngIf="licenceIsLoading"
				class="loading">
				<mat-spinner></mat-spinner>
			</div>

			<mat-card
				*ngIf="!licenceIsLoading">
				<mat-card-content
					class="licence"
					[innerHTML]="licenceHtml">
				</mat-card-content>
			</mat-card>
		</ng-container>
	</div>
</div>

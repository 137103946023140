import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {
	IPersonality,
	IPersonalityResources,
	IHttpResponse,
} from '@brand-magic/lib-types';
import { Personality } from '@brand-magic/lib-personality';
import { ResourceService } from '../../services/resource.service';
import { LicenseeService } from '../../services/licensee.service';

@Component({
	selector: 'brand-magic-resources',
	templateUrl: './resources.template.html',
	styleUrls: ['./resources.style.scss'],
})
export class ResourcesComponent {
	public availablePersonalities: IPersonality[];
	public selectedPersonality!: IPersonality;
	public resources: IPersonalityResources | null = null;

	public isLoading = false;

	public errors: string[] = [];
	public isPaid = false;

	constructor(
		private sanitizer: DomSanitizer,
		private resourceService: ResourceService,
		private licenseeService: LicenseeService,
	) {
		this.availablePersonalities = Personality.AllPersonalities;
		this.onPersonalitySelected(this.availablePersonalities[0]);
		this.licenseeService.isPaidSubscriber().then(
			(response: IHttpResponse<boolean>) => {
				if (!response.success) {
					this.errors.push(response.helpMessage as string);
					return;
				}
				this.isPaid = response.data as boolean;
			}
		);
	}

	public onPersonalitySelected(personality: IPersonality) {
		this.selectedPersonality = personality;
		this.loadResources();
	}

	private async loadResources() {
		this.errors.length = 0;
		this.resources = null;
		this.isLoading = true;
		const response = await this.resourceService.getResources(
			this.selectedPersonality.personalityId
		);

		this.isLoading = false;
		if (!response.success) {
			this.errors.push(response.helpMessage as string);
		}
		this.resources = response.data;
	}

	public onDownloadImage(assetType: string, img: any) {
		if (!this.isPaid) {
			this.licenseeService.showPaidSubscriberWarning();
			return;
		}

		this.resourceService.downloadFile(
			this.selectedPersonality.personalityId,
			assetType,
			img
		);
	}

	public getSvg(personality: IPersonality) {
		return this.sanitizer.bypassSecurityTrustHtml(personality.svg);
	}

}

import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import {
	ILead,
	IQuizPrice,
} from '@brand-magic/lib-types';
import { LeadService } from './lead.service';
import { QuizService } from './quiz.service';
import { ICachingService } from './caching-service.interface';

import type { IGroupedData } from '../components/bar-chart/grouped-data.interface';

export interface IStats {
	thisMonth: {
		plays: number;
		earnings: number;
		data: IGroupedData[];
	},
	all: {
		plays: number;
		earnings: number;
		data: IGroupedData[];
	},
}

@Injectable({
	providedIn: 'root'
})
export class GraphService implements ICachingService {
	private cachingEnabled = true;
	private readonly months = [
		'', // luxon's date is 1-based
		'Jan', 'Feb', 'Mar', 'Apr',
		'May', 'June', 'July', 'Aug',
		'Sept', 'Oct', 'Nov', 'Dec',
	];

	private stats: IStats | null = null;

	constructor(
		private leadService: LeadService,
		private quizService: QuizService,
	) {
	}

	public async getStats(): Promise<(IStats | null)> {
		if (this.stats != null && this.cachingEnabled) {
			return this.stats;
		}

		let leads: ILead[] = await this.leadService.getLeads();
		if (leads == null || leads.length === 0) {
			return null;
		}

		leads = JSON.parse(JSON.stringify(leads));
		leads = leads.map((l: any) => {
			l.creationDate = DateTime.fromISO(l.creationDate);
			return l as ILead;
		});
		leads.sort((a: any, b: any) => {
			return a.creationDate.toMillis() - b.creationDate.toMillis();
		});

		const priceResponse = await this.quizService.getLatestPrice();
		if (!priceResponse.success) {
			return null;
		}
		let price: IQuizPrice = priceResponse.data as IQuizPrice;

		const earningsPerPlay = (price.costPerPlay - price.feePerPlay) / 100;

		let currentByMonth: any = {
			month: leads[0].creationDate.month,
			year: leads[0].creationDate.year,
			data: {
				label: this.months[leads[0].creationDate.month] + ' ' + leads[0].creationDate.year,
				plays: 0,
				earnings: 0
			},
		};
		let currentMonthByDay: any = {
			day: 1,
			data: {
				label: DateTime.now().set({ day: 1 }).toISODate(),
				plays: 0,
				earnings: 0
			},
		};

		const dt = DateTime.now();
		const thisMonth = dt.month;
		const thisYear = dt.year;

		const stats: IStats = {
			all: {
				plays: 0,
				earnings: 0,
				data: [currentByMonth.data],
			},
			thisMonth: {
				plays: 0,
				earnings: 0,
				data: [currentMonthByDay.data],
			},
		};
		leads.forEach((lead: ILead) => {
			const dt = lead.creationDate;

			// update all
			while (dt.month !== currentByMonth.month || dt.year <= currentByMonth.year) {
				if (dt.month === currentByMonth.month && dt.year === currentByMonth.year) {
					break;
				}
				let month = currentByMonth.month + 1;
				let year = currentByMonth.year;
				if (month >= 13) {
					month = 1;
					year = year + 1;
				}
				if (year > thisYear) {
					break;
				}
				currentByMonth = {
					month: month,
					year: year,
					data: {
						label: this.months[dt.month] + ' ' + dt.year,
						plays: 0,
						earnings: 0
					}
				};
				stats.all.data.push(currentByMonth.data);
			}
			stats.all.plays++;
			stats.all.earnings += earningsPerPlay;
			currentByMonth.data.plays++;
			currentByMonth.data.earnings += earningsPerPlay;

			// update this month
			if (dt.month === thisMonth && dt.year === thisYear) {
				while (currentMonthByDay.day < dt.day) {
					const day = currentMonthByDay.day + 1;
					if (day > 31) {
						break;
					}
					currentMonthByDay = {
						day: day,
						data: {
							label: dt.set({ day: day }).toISODate(),
							plays: 0,
							earnings: 0
						}
					}
					stats.thisMonth.data.push(currentMonthByDay.data);
				}
				stats.thisMonth.plays++;
				stats.thisMonth.earnings += earningsPerPlay;
				currentMonthByDay.data.plays++;
				currentMonthByDay.data.earnings += earningsPerPlay;
			}
		});

		this.stats = stats;
		return stats;
	}

	public clearCaches() {
		this.stats = null;
	}

	public enableCaching(isEnabled: boolean) {
		this.cachingEnabled = isEnabled;
	}
}

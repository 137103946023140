import {
	Injectable,
} from '@angular/core';
import { StringUtil } from '@brand-magic/lib-util';
import {
	IHttpResponse,
	IPersonalityResources,
} from '@brand-magic/lib-types';
import {
	HttpService,
} from '@brand-magic/lib-http';

@Injectable({
	providedIn: 'root'
})
export class ResourceService {

	constructor(
		public httpService: HttpService,
	) {
	}

	public async getResources(
		personalityId: string
	): Promise<IHttpResponse<IPersonalityResources>> {
		const requestData = {
			personality: personalityId,
		};

		const response = await this.httpService
			.post<any, IPersonalityResources>(
				'/api/get-resources-list',
				requestData
			);

		if (!response.success) {
			console.error(response.helpMessage);
		}

		return response;
	}

	public async downloadFile(
		personality: string,
		resourceType: string,
		fileName: string
	) {
		const requestData = {
			personality: personality,
			resourceType: resourceType,
			fileName: fileName,
		};

		await this.httpService
			.postAndDownload('/api/download-resource', requestData);
	}
}


<div class="container">
	<h1>Your stripe session has expired</h1>

	<p>
		If you are seeing this page, your Stripe session has expired
		and you will need to try again.
	</p>

	<p>
		<a routerLink="/pages/subscription">Click here</a> to navigate to
		the subscription page.
	</p>
</div>

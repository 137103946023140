<div class="container">
	<mat-form-field
		class="full-width">
		<mat-label>
			Email
		</mat-label>
		<input
			type="email"
			matInput
			[formControl]="emailAddressControl"
			placeholder="Email Address">
	</mat-form-field>

	<mat-form-field
		class="full-width">
		<mat-label>
			Password
		</mat-label>
		<input
			type="password"
			matInput
			[formControl]="passwordControl"
			placeholder="Password">
	</mat-form-field>

	<div class="action-buttons">
		<button
			mat-raised-button
			color="primary"
			(click)="onLogin()">
			Login
		</button>
		<button
			mat-raised-button
			(click)="onCancel()">
			Cancel
		</button>
	</div>
</div>

<div class="quiztips-container">

	<h2>Side panel</h2>

	<p>
		You can create your own instruction video for your players to personalise
		the experience. Once you have uploaded your video to Youtube, please
		include the video id below.
	</p>

	<p>
		Please note that this is not the entire video URL, just the id.
	</p>

	<mat-form-field appearance="fill" class="video-id">
		<mat-label>Youtube Video Id</mat-label>
		<input
			matInput
			placeholder="Example: UjCdB5p2v0Y"
			[(ngModel)]="personalisation.pages.instructions.youtubeVideoId"
			(blur)="onIdBlur()">
		<mat-hint align="start">This value should be id from a YouTube URL following <em>https://www.youtube.com/watch?v=</em></mat-hint>
	</mat-form-field>

	<mat-form-field class="editor" class="editor">
		<mat-label>Alternate description (to display in place of video)</mat-label>
		<textarea
			[(ngModel)]="personalisation.pages.instructions.alternateDescription"
			[froalaEditor]="htmlEditorOptions"
			matInput>{{ personalisation.pages.instructions.alternateDescription }}</textarea>
	</mat-form-field>

	<h2>Main content area</h2>

	<mat-form-field class="editor" class="editor">
		<mat-label>Instructions (for the main area)</mat-label>
		<textarea
			[(ngModel)]="personalisation.pages.instructions.instructionText"
			[froalaEditor]="htmlEditorOptions"
			matInput>{{ personalisation.pages.instructions.instructionText }}</textarea>
	</mat-form-field>

	<mat-form-field appearance="fill">
		<mat-label>'Play test' button text</mat-label>
		<input
			matInput
			[(ngModel)]="personalisation.pages.instructions.playButtonText">
	</mat-form-field>
</div>

import { Injectable } from '@angular/core';
import {
	Router,
	ActivatedRouteSnapshot,
	CanActivate,
	CanActivateChild,
	RouterStateSnapshot,
	UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';

import {
	AuthService
} from '@brand-magic/lib-auth';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
	private static readonly LOGIN_PATH = '/auth/login';
	private static readonly CREATE_PATH = '/auth/create-account';

	constructor(
		private router: Router,
		private authService: AuthService,
	) {
	}

	public canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		if (state.url === AuthGuard.LOGIN_PATH || state.url === AuthGuard.CREATE_PATH) {
			return true;
		}

		if (this.authService.isLoggedIn()) {
			return true;
		}

		this.authService.setRedirectUrl(state.url);

		return this.router.parseUrl(AuthGuard.LOGIN_PATH);
	}

	public canActivateChild(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return this.canActivate(route, state);
	}

}

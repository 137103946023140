import {
	Component,
	Input,
	Output,
	EventEmitter,
} from '@angular/core';
import {
	ILicenseePersonalisation,
	IPersonality,
} from '@brand-magic/lib-types';
import {
	Toolbar
} from '../../toolbar';

@Component({
	selector: 'brand-magic-personalisation-emails-edit',
	templateUrl: './personalisation-emails-edit.template.html',
	styleUrls: ['./personalisation-emails-edit.style.scss'],
})
export class PersonalisationEmailsEditComponent {
	private _personality!: IPersonality;

	@Input()
	public personalisation!: ILicenseePersonalisation;

	@Input()
	public get personality() {
		return this._personality;
	}
	public set personality(value: IPersonality) {
		this._personality = value;
		this.personalityKey = value.personalityId as keyof typeof this.personalisation.personality;
	}

	public personalityKey!: keyof typeof this.personalisation.personality;

	public htmlEditorOptions: any = Toolbar.options;

	public errors: string[] = [];

	@Input()
	public personalityName!: string;

	@Input()
	public emailContent!: string;

	constructor(
	) {
	}

}

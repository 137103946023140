import {
	Component,
} from '@angular/core';
import {
	StringUtil,
} from '@brand-magic/lib-util';
import { DateTime } from 'luxon';
import {
	ISubscriptionLevel,
	IHttpResponse,
} from '@brand-magic/lib-types';
import { SubscriptionService } from '../../services/subscription.service';

@Component({
	selector: 'brand-magic-admin-subscription',
	templateUrl: './admin-subscription.template.html',
	styleUrls: ['./admin-subscription.style.scss'],
})
export class AdminSubscriptionComponent {
	public isLoading = true;
	public errors: string[] = [];

	public subscriptions: ISubscriptionLevel[] | null = null;

	constructor(
		private subscriptionService: SubscriptionService,
	) {
		this.loadSubscriptionLevels();
	}

	private async loadSubscriptionLevels() {
		this.isLoading = true;
		this.errors.length = 0;
		const subscriptionResponse = await this.subscriptionService.getSubscriptionLevels();
		if (subscriptionResponse.success) {
			this.subscriptions = subscriptionResponse.data;
		} else {
			this.errors.push(subscriptionResponse.helpMessage as string);
		}
		this.isLoading = false;
	}

	public async onCreateSubscriptionLevel() {
		if (this.subscriptions == null) {
			this.subscriptions = [];
		}
		this.subscriptions.push({
			subscriptionLevelId: '',
			stripe: {
				productId: '',
				priceId: '',
			},
			name: '',
			ordering: this.subscriptions.length,
			isFree: true,
			dollarsPerMonth: 0,
			benefits: [],
			receivesFreeCoupon: false,
			freeCouponCount: 0,
		});
	}

	public async onSave() {
		this.errors.length = 0;

		if (this.subscriptions == null || this.subscriptions.length === 0) {
			return;
		}

		const promises: Promise<IHttpResponse<any>>[] = [];

		try {
			this.subscriptions.forEach((level: ISubscriptionLevel) => {
				const isNew = StringUtil.nullOrEmpty(level.subscriptionLevelId);
				if (isNew) {
					promises.push(this.subscriptionService.insertSubscriptionLevel(level));
				} else {
					promises.push(this.subscriptionService.updateSubscriptionLevel(level));
				}
			});
		} catch (exception: any) {
			this.errors.push(exception.toString());
		}

		const responses = await Promise.all(promises);

		responses.forEach((promise: IHttpResponse<any>) => {
			if (!promise.success) {
				this.errors.push(promise.helpMessage as string);
			}
		});
	}
}

<div class="container admin">

	<brand-magic-error-display
		[header]="errorMessage"
		[errors]="errors">
	</brand-magic-error-display>

	<div
		*ngIf="selectedLicensee == null"
		class="loading">
		<h1>Loading payment details</h1>

		<mat-spinner></mat-spinner>
	</div>


	<ng-container
		*ngIf="selectedLicensee != null">

		<h1>
			Licensee edit -
			{{ selectedLicensee.givenName }} {{ selectedLicensee.familyName }}
		</h1>

		<div class="stripe-fields">
			<h3>Stripe ids</h3>

			<div class="stripe-field">
				<mat-form-field class="field-value" appearance="outline">
					<mat-label>Customer (licensee subscription)</mat-label>
					<input
						matInput
						[(ngModel)]="selectedLicensee.stripe.customerId"
						[disabled]="!editStripeFields">
				</mat-form-field>

				<a
					href="https://dashboard.stripe.com/customers/{{ selectedLicensee.stripe.customerId }}"
					target="_blank">
					<mat-icon>open_in_new</mat-icon>
					Open customer in Stripe
				</a>
			</div>

			<div class="stripe-field">
				<mat-form-field class="field-value" appearance="outline">
					<mat-label>Account (quiz provider)</mat-label>
					<input
						matInput
						[(ngModel)]="selectedLicensee.stripe.accountId"
						[disabled]="!editStripeFields">
				</mat-form-field>

				<a
					href="https://dashboard.stripe.com/connect/accounts/{{ selectedLicensee.stripe.accountId }}"
					target="_blank">
					<mat-icon>open_in_new</mat-icon>
					Open account in Stripe
				</a>
			</div>

			<div class="stripe-buttons">
				<button
					(click)="onToggleEditStripe()"
					mat-button
					matTooltip="This should be used with caution, only if the Stripe Ids have be changed"
					matTooltipPosition="above"
					color="warn">
					<mat-icon>warning</mat-icon>
					Edit stripe ids
				</button>
			</div>
		</div>

		<div class="subscription-field">
			<h3>Brand Magic subscription level</h3>

			<p [ngClass]="{'disabled': !editSubscriptionFields}">
				This should only be used to re-synch a customer with the value in Stripe
			</p>

			<mat-radio-group
				id="subscriptionLevels"
				[disabled]="!editSubscriptionFields"
				[(ngModel)]="selectedLicensee.subscriptionId"
				aria-label="Subscription levels">
				<mat-radio-button
					*ngFor="let subscription of allSubscriptionLevels"
					[value]="subscription.subscriptionLevelId">
					{{ subscription.name }}
				</mat-radio-button>
			</mat-radio-group>

			<div class="subscription-buttons">
				<button
					(click)="onToggleEditSubscription()"
					mat-button
					matTooltip="This should be used with caution, only if the customer is out-of-sync with Stripe"
					matTooltipPosition="above"
					color="warn">
					<mat-icon>warning</mat-icon>
					Edit subscription value
				</button>
			</div>
		</div>

		<div class="subscription-field">
			<h3>Admin user</h3>

			<p [ngClass]="{'disabled': !editSubscriptionFields}">
				This toggle will allow a user to access the admin section of the website
			</p>

			<mat-slide-toggle
				[disabled]="!editAdminFields"
				[(ngModel)]="selectedLicensee.isAdmin">
				is Administrator
			</mat-slide-toggle>


			<div class="subscription-buttons">
				<button
					(click)="onToggleEditAdmin()"
					mat-button
					matTooltip="This should be used with caution as it provides administrator access to Brand Magic"
					matTooltipPosition="above"
					color="warn">
					<mat-icon>warning</mat-icon>
					Edit is administrator value
				</button>
			</div>
		</div>

		<div class="subscription-field">
			<h3>Is enabled</h3>

			<p [ngClass]="{'disabled': !editSubscriptionFields}">
				This toggle will activate and de-activate a user on the site
			</p>

			<mat-slide-toggle
				[disabled]="!editEnabledFields"
				[(ngModel)]="selectedLicensee.isActive">
				is Activated
			</mat-slide-toggle>


			<div class="subscription-buttons">
				<button
					(click)="onToggleEditActive()"
					mat-button
					matTooltip="This should be used with caution as it provides administrator access to Brand Magic"
					matTooltipPosition="above"
					color="warn">
					<mat-icon>warning</mat-icon>
					Edit is enabled value
				</button>
			</div>
		</div>

		<div class="action-buttons">
			<button
				(click)="onSaveLicensee()"
				[disabled]="!enableSave"
				mat-raised-button
				color="primary">
				Update licensee
			</button>

			<button
				(click)="onCancel()"
				mat-raised-button>
				Cancel updates
			</button>
		</div>

	</ng-container>
</div>

import {
	Component,
	Output,
	EventEmitter,
} from '@angular/core';
import {
	FormControl,
} from '@angular/forms';
import {
	StringUtil
} from '@brand-magic/lib-util';
import { RecoveryRequestEvent } from './recovery-request.event';
import { RecoveryFailureEvent } from './recovery-failure.event';
import { RecoveryCancelEvent } from './recovery-cancel.event';

@Component({
	selector: 'brand-magic-account-recovery',
	templateUrl: './account-recovery.template.html',
	styleUrls: ['./account-recovery.style.scss'],
})
export class AccountRecoveryComponent {
	public emailAddressControl = new FormControl('');

	@Output()
	public request: EventEmitter<RecoveryRequestEvent> = new EventEmitter();

	@Output()
	public fail: EventEmitter<RecoveryFailureEvent> = new EventEmitter();

	@Output()
	public cancel: EventEmitter<RecoveryCancelEvent> = new EventEmitter();

	constructor(
	) {
	}

	public onCancel() {
		this.cancel.emit();
	}

	public onSend() {
		const helpMessages = [];

		const emailAddress = this.emailAddressControl.value as string;
		if (StringUtil.nullOrEmpty(emailAddress)) {
			helpMessages.push('Please enter your email address into the \'Email Address\' form field');
		} else {
			const hasAt = emailAddress.indexOf('@') > 0;
			const hasFullStop = emailAddress.indexOf('.') > 0;
			if (!hasAt || !hasFullStop) {
				helpMessages.push('Your email address does not appear to be a valid email.');
				helpMessages.push('Please double-check your email and try again.');
			}
		}

		if (helpMessages.length > 0) {
			const evt = new RecoveryFailureEvent(helpMessages);
			this.fail.emit(evt);
			return;
		}

		const evt = new RecoveryRequestEvent(emailAddress);
		this.request.emit(evt);
	}


}

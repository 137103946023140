<div class="quiztips-container">

	<h3>Your test homepage</h3>

	<p>
		The test home URL is the address on your website where
		a user can visit to play the test. It should be the
		address to the pages with the embedded test.
	</p>

	<mat-form-field class="home-url" appearance="fill">
		<mat-label>Test Home URL</mat-label>
		<input
			matInput
			[(ngModel)]="personalisation.settings.quizHomeUrl">
		<mat-hint>E.g. https://yourdomain.com.au/quiz</mat-hint>
	</mat-form-field>

	<h3>Call to Action buttons</h3>

	<p>
		These two call to action URLs can be used in your result pages
		to create buttons that link to these URLs.
	</p>

	<p>
		You can customise the text on the buttons when you add
		them to your result page and email text.
	</p>

	<mat-form-field appearance="fill">
		<mat-label>Call to action 1 URL</mat-label>
		<input
			matInput
			[(ngModel)]="personalisation.settings.cta1Url">
	</mat-form-field>

	<mat-form-field appearance="fill">
		<mat-label>Call to action 2 URL</mat-label>
		<input
			matInput
			[(ngModel)]="personalisation.settings.cta2Url">
	</mat-form-field>

</div>

import { Component } from '@angular/core';
import {
	Router,
} from '@angular/router';
import {
	ISubscriptionLevel,
	IHttpResponse,
} from '@brand-magic/lib-types';
import { SubscriptionService } from '../../services/subscription.service';
import { LicenseeService } from '../../services/licensee.service';

@Component({
	selector: 'brand-magic-subscription',
	templateUrl: './subscription.template.html',
	styleUrls: ['./subscription.style.scss'],
})
export class SubscriptionComponent {
	public errors: string[] = [];
	public subscriptionLevels: ISubscriptionLevel[] = [];
	public isPaidSubscriber: boolean | null = null;
	public payoutsAvailable = false;
	public currentSubscription: ISubscriptionLevel | null = null;

	constructor(
		private router: Router,
		private subscriptionService: SubscriptionService,
		private licenseeService: LicenseeService,
	) {
		this.loadSubscriptions();
	}

	private async loadSubscriptions() {
		const response = await this.subscriptionService.getSubscriptionLevels();
		if (!response.success) {
			this.errors.push(response.helpMessage as string);
			return;
		}
		this.subscriptionLevels = response.data as ISubscriptionLevel[];
		const currentResponse = await this.licenseeService.getCurrentSubscriptionLevel();
		if (!currentResponse.success) {
			this.errors.push(currentResponse.helpMessage as string);
			return;
		}
		this.currentSubscription = currentResponse.data as ISubscriptionLevel;

		this.licenseeService.isPaidSubscriber().then(
			(response: IHttpResponse<boolean>) => {
				if (!response.success) {
					this.errors.push(response.helpMessage as string);
					return;
				}
				this.isPaidSubscriber = response.data as boolean;
			}
		);

		const payoutResponse = await this.licenseeService.arePayoutsAvailable();
		if (!payoutResponse.success) {
			this.errors.push(payoutResponse.helpMessage as string);
			return;
		}

		this.payoutsAvailable = payoutResponse.data as boolean;
	}

	public getUpgradeLink(lvl: ISubscriptionLevel) {
		return '/pages/subscription-upgrade/' + lvl.subscriptionLevelId;
	}

	public onCompleteIntegrationClicked() {
		const link = this.getUpgradeLink(this.currentSubscription as ISubscriptionLevel);
		this.router.navigateByUrl(link);
	}

}

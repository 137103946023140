import {
	Component,
	Input,
} from '@angular/core';
import {
	ILicenseePersonalisation,
} from '@brand-magic/lib-types';
import {
	Toolbar
} from '../../toolbar';

@Component({
	selector: 'brand-magic-personalisation-payment-edit',
	templateUrl: './personalisation-payment-edit.template.html',
	styleUrls: ['./personalisation-payment-edit.style.scss'],
})
export class PersonalisationPaymentEditComponent {
	public htmlEditorOptions: any = Toolbar.options;

	@Input()
	public personalisation!: ILicenseePersonalisation;

	constructor(
	) {
	}

}

import { Injectable } from '@angular/core';
import {
	HttpClient,
	HttpErrorResponse,
	HttpHeaders,
} from '@angular/common/http';

import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from '@brand-magic/lib-auth';
import {
	IHttpResponse,
} from '@brand-magic/lib-types';

@Injectable({
	providedIn: 'root'
})
export class HttpService {
	private readonly emptyHeaders = new HttpHeaders();

	constructor(
		private http: HttpClient,
		private authService: AuthService,
	) {
	}

	public get<respT>(
		path: string,
	): Promise<IHttpResponse<respT>> {
		return new Promise((resolve, err) => {
			const opts = {
				responseType: 'json' as const,
				headers: this.emptyHeaders,
			};

			const authToken = this.authService.getAuthToken();
			if (authToken != null && authToken.length > 0) {
				opts.headers = new HttpHeaders({
					Authorization: `Bearer ${authToken}`,
				});
			}

			try {
				this.http
					.get<any>(path, opts)
					.subscribe({
						next: resp => {
							resolve(resp);
						},
						error: error => {
							const httpResponse: IHttpResponse<respT> = {
								success: false,
								helpMessage: error.message,
								data: null,
							};
							resolve(httpResponse);
						},
					});
			} catch (exception: any) {
				const httpResponse: IHttpResponse<respT> = {
					success: false,
					helpMessage: exception.err,
					data: null,
				};
				console.error('Error posting to: ' + path, exception);
				resolve(httpResponse);
			}
		});
	}


	public post<reqT, respT>(
		path: string,
		req: reqT
	): Promise<IHttpResponse<respT>> {
		return new Promise((resolve, err) => {
			const opts = {
				responseType: 'json' as const,
				headers: this.emptyHeaders,
			};

			const authToken = this.authService.getAuthToken();
			if (authToken != null && authToken.length > 0) {
				opts.headers = new HttpHeaders({
					Authorization: `Bearer ${authToken}`,
				});
			}

			try {
				this.http
					.post<any>(path, req, opts)
					//.pipe(catchError(this.handleError))
					.subscribe({
						next: resp => {
							resolve(resp);
						},
						error: error => {
							const httpResponse: IHttpResponse<respT> = {
								success: false,
								helpMessage: error.message,
								data: null,
							};
							resolve(httpResponse);
						},
					});
			} catch (exception: any) {
				console.error('Error posting to: ' + path, exception.err);
				const httpResponse: IHttpResponse<respT> = {
					success: false,
					helpMessage: exception.message,
					data: null,
				};
				resolve(httpResponse);
			}
		});
	}

	public postAndDownload(
		path: string,
		req: any
	) {
		return new Promise(async (resolve, err) => {
			const headers = new Headers();
			headers.append('Content-Type', 'application/json');

			const authToken = this.authService.getAuthToken();
			if (authToken != null && authToken.length > 0) {
				headers.append('Authorization', `Bearer ${authToken}`);
			}

			try {
				const requestBody = JSON.stringify(req);
				console.log('Sending', requestBody);
				const response = await fetch(path, {
					method: 'POST',
					headers: headers,
					body: requestBody,
				});

				if (response == null) {
					console.error('null response trying to download file');
					resolve(false);
					return;
				}

				if (response.status != 200) {
					console.log('Unable to download: ', response.status);
					resolve(false);
					return;
				}

				const filename = req.fileName;
				const blob = await response.blob();

				const blobURL = window.URL.createObjectURL(blob);
				const tempLink = document.createElement('a');
				tempLink.style.display = 'none';
				tempLink.href = blobURL;
				tempLink.setAttribute('download', filename);

				// Safari thinks _blank anchor are pop ups. We only want to set _blank
				// target if the browser does not support the HTML5 download attribute.
				// This allows you to download files in desktop safari if pop up blocking
				// is enabled.
				if (typeof tempLink.download === 'undefined') {
					tempLink.setAttribute('target', '_blank');
				}

				document.body.appendChild(tempLink);
				tempLink.click();
				document.body.removeChild(tempLink);
				setTimeout(() => {
					// For Firefox it is necessary to delay revoking the ObjectURL
					window.URL.revokeObjectURL(blobURL);
				}, 100);

				resolve(true);
			} catch (exception: any) {
				console.error('Error downloading from: ' + path, exception.err);
				resolve(false);
			}
		});
	}


	private handleError(error: any) {
		let errMsg: string | null;

		if (error instanceof ErrorEvent) {
			// client-side error
			errMsg = 'Error caught executing request: ' + error.message;
		} else if (error.status != null &&
				   error.error != null) {
			// backend returned unsuccessful response
			errMsg = `Backend returned code ${error.status}: ${error.error}`;
		} else {
			errMsg = 'Error making http request: ' + error.toString();
		}

		return throwError(() => new Error(errMsg as string));
	}

}

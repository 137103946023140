<div class="container">
	<h1>Subscription</h1>

	<h2
		*ngIf="!isPaidSubscriber">
		If you want to unlock the magic of a paid subscription, you can do so at any time.
	</h2>

	<h2
		*ngIf="isPaidSubscriber && !payoutsAvailable">
		Complete your integration to receive commissions
	</h2>

	<div
		class="payout"
		*ngIf="isPaidSubscriber && !payoutsAvailable">
			<button
				mat-raised-button
				color="primary"
				(click)="onCompleteIntegrationClicked()">
				<mat-icon>monetization_on</mat-icon>
				Start receiving commissions
			</button>
	</div>

	<brand-magic-error-display
		[errors]="errors">
	</brand-magic-error-display>

	<div
		class="subscription-container">

		<mat-card
			*ngFor="let lvl of subscriptionLevels">
			<mat-card-header>
				<mat-card-title>
					{{ lvl.name }}
				</mat-card-title>
				<mat-card-subtitle>
					<span
						*ngIf="lvl.dollarsPerMonth === 0">
						Free
					</span>
					<span
						*ngIf="lvl.dollarsPerMonth !== 0">
						${{ lvl.dollarsPerMonth }} per month
					</span>
				</mat-card-subtitle>
			</mat-card-header>
			<mat-card-content>
				<mat-list role="list">
					<mat-list-item
						class="benefit"
						role="listitem"
						*ngFor="let benefit of lvl.benefits">
						<mat-icon>check</mat-icon>
						{{ benefit }}
					</mat-list-item>
				</mat-list>
			</mat-card-content>
			<mat-card-actions>
				<a
					*ngIf="currentSubscription?.subscriptionLevelId === lvl.subscriptionLevelId"
					mat-raised-button
					disabled>
					Your current subscription
				</a>
				<a
					*ngIf="currentSubscription?.subscriptionLevelId !== lvl.subscriptionLevelId"
					color="primary"
					[routerLink]="getUpgradeLink(lvl)"
					mat-stroked-button>
					Change subscription
				</a>
			</mat-card-actions>
		</mat-card>

	</div>
</div>

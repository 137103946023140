import { Component } from '@angular/core';

@Component({
	selector: 'brand-magic-stripe-refresh',
	templateUrl: './stripe-refresh.template.html',
	styleUrls: ['./stripe-refresh.style.scss'],
})
export class StripeRefreshComponent {

	constructor(
	) {
	}
}

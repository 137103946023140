import {
	Component,
	Input,
	Output,
	EventEmitter,
	OnInit,
} from '@angular/core';
import {
	FormControl,
} from '@angular/forms';
import {
	DomSanitizer,
	SafeHtml,
} from '@angular/platform-browser';

import {
	IQuizData,
	IQuestion,
	IAnswer,
	IPersonalityWeighting,
} from '@brand-magic/lib-types';

import {
	Personality,
} from '@brand-magic/lib-personality';

interface INumberedQuestion {
	questionNumber: string;
	question: IQuestion;
}

interface INumberedAnswer {
	answerNumber: string;
	answer: IAnswer;
}

interface IAnnotatedWeighting {
	icon: SafeHtml;
	name: string;
	personalityId: string;
	weight: number;
}

@Component({
	selector: 'brand-magic-quiz-edit',
	templateUrl: './quiz-edit.template.html',
	styleUrls: ['./quiz-edit.style.scss'],
})
export class QuizEditComponent implements OnInit {

	@Input()
	public quiz!: IQuizData;

	@Output()
	public save = new EventEmitter<IQuizData>();

	public currentQuestion: IQuestion | null = null;

	public header: string = 'We found some errors with the quiz data your provided';
	public errorDescription: string[] = [
		'You did not provide values for some required fields.',
		'Please double-check the form and try again.',
	];
	public errors: string[] = [];

	public question!: FormControl;

	public doShuffle = true;

	constructor(
		private sanitizer: DomSanitizer,
	) {
	}

	public ngOnInit() {
		if (this.quiz == null) {
			this.errors.push('No quiz was provided to edit');
			return;
		}
		if (this.quiz.questions == null || this.quiz.questions.length === 0) {
			this.errors.push('The provided quiz does not have any questions');
			return;
		}
		this.currentQuestion = this.quiz.questions[0];
	}

	public onSave() {
		const q = JSON.parse(JSON.stringify(this.quiz));
		for (const question of q.questions) {
			if (this.doShuffle) {
				this.shuffleArray(question.options);
			}

			for (const opt of question.options) {
				for (let i = 0; i < opt.weightings.length; i++) {
					delete opt.weightings[i].icon;
					delete opt.weightings[i].name;

					if (opt.weightings[i].weight == 0) {
						opt.weightings.splice(i, 1);
						i--;
					}
				}
			}
		}

		this.save.emit(q);
	}

	public shuffleArray(array: any[]) {
		for (let i = array.length - 1; i > 0; i--) {
			const j = Math.floor(Math.random() * (i + 1));
			[array[i], array[j]] = [array[j], array[i]];
		}
	}

	public getAnswerDescription(answer: IAnswer) {
		const descriptions = [];
		const weighted = this.getWeightings(answer);
		const copy = weighted.slice();
		copy.sort((a: IPersonalityWeighting, b: IPersonalityWeighting) => {
			return b.weight - a.weight;
		});
		for (const weighting of copy) {
			const w = weighting as IAnnotatedWeighting;
			if (w.weight > 0) {
				descriptions.push(w.name + ': ' + w.weight);
			}
		}
		return descriptions.join(', ');
	}

	public getWeightings(answer: IAnswer): IAnnotatedWeighting[] {
		const existingWeightings = answer.weightings;
		if (existingWeightings != null && existingWeightings.length === 12) {
			return existingWeightings as IAnnotatedWeighting[];
		}

		const weightings: IAnnotatedWeighting[] = [];

		for (const personality of Personality.AllPersonalities) {
			const existing = existingWeightings.find(w => w.personalityId === personality.personalityId);
			const weight = existing == null ? 0 : existing.weight;

			const weighting: IAnnotatedWeighting = {
				icon: this.sanitizer.bypassSecurityTrustHtml(personality.svg),
				name: personality.name,
				personalityId: personality.personalityId,
				weight: weight,
			};
			weightings.push(weighting);
		}

		answer.weightings = weightings;

		return answer.weightings as IAnnotatedWeighting[];
	}

}

import { Component } from '@angular/core';
import {
	Router,
	ActivatedRoute,
	ActivatedRouteSnapshot,
	NavigationEnd,
} from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { BreakpointObserver, } from '@angular/cdk/layout';
import { distinctUntilChanged } from 'rxjs/operators';
import { AuthService } from '@brand-magic/lib-auth';
import {
	ILicensee,
} from '@brand-magic/lib-types';
import { LicenseeService } from '../services/licensee.service';
import { MenuService } from '../services/menu.service';

@Component({
	selector: 'brand-magic-page-shell',
	templateUrl: './page-shell.template.html',
	styleUrls: ['./page-shell.style.scss'],
})
export class PageShellComponent {
	public atLeast900 = false;
	public atLeast700 = false;

	private isAdminUser = false;
	private isAuthPage = false;
	private licenseeLoaded = false;

	readonly breakpoint$ = this.breakpointObserver
		.observe(['(min-width: 900px)', '(min-width: 700px)'])
		.pipe(
			distinctUntilChanged()
		);

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private breakpointObserver: BreakpointObserver,
		private authService: AuthService,
		private licenseeService: LicenseeService,
		public menuService: MenuService,
	) {
		// We need to track if the current page is an authentication page
		// to avoid calling getCurrentLicensee before the user has
		// logged in.
		this.router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd),
				map(() => this.route.snapshot),
				map((route: ActivatedRouteSnapshot) => {
					while (route.firstChild) {
						route = route.firstChild;
					}
					return route;
				})
			)
			.subscribe((route: ActivatedRouteSnapshot) => {
				this.isAuthPage = route.url.length > 0 && route.url[0].path === 'auth';
			});

		this.authService.onLogout(() => {
			this.isAdminUser = false;
			this.licenseeLoaded = false;
		});
	}

	ngOnInit(): void {
		this.breakpoint$.subscribe(() =>
			this.breakpointChanged()
		);
	}

	public isAdmin(): boolean {
		if (!this.licenseeLoaded) {
			if (this.isAuthPage) {
				return false;
			}

			this.licenseeLoaded = true;
			this.licenseeService.getCurrentLicensee().then(licenseeResponse => {
				if (!licenseeResponse?.success) {
					setTimeout(() => {
						this.licenseeLoaded = false;
					}, 2000);
				} else {
					const licensee = licenseeResponse.data as ILicensee;
					this.isAdminUser = licensee.isAdmin;
				}
			});
		}

		return this.isAdminUser;
	}

	private breakpointChanged() {
		this.atLeast900 = true;
		this.atLeast700 = true;

		if (this.breakpointObserver.isMatched('(min-width: 900px)')) {
		} else if (this.breakpointObserver.isMatched('(min-width: 700px)')) {
			this.atLeast900 = false;
		} else  {
			this.atLeast900 = false;
			this.atLeast700 = false;
		}
	}
}

<div class="container admin">
	<h1>Subscription options</h1>

	<p>
		Edit and modify licensee subscriber levels. Note that you can re-order the benefits
		by dragging on the drag-handles (the <mat-icon class="demo">drag_indicator</mat-icon> icon)
		and moving the items within the list of benefits.
	</p>

	<div class="action-buttons">
		<button
			mat-raised-button
			(click)="onCreateSubscriptionLevel()">
			<mat-icon>add</mat-icon>
			Add new subscription level
		</button>
	</div>

	<div
		*ngIf="isLoading"
		class="loading">
		<mat-spinner></mat-spinner>
	</div>

	<div
		*ngIf="errors.length > 0"
		class="errors">
		<h1>We encountered an error trying to fulfil your request</h1>

		<ul class="error-list">
			<li
				*ngFor="let error of errors">
				{{ error }}
			</li>
		</ul>
	</div>

	<div
		class="subscriptions"
		*ngIf="!isLoading">
		<mat-card
			class="subscription-container"
			*ngFor="let subscription of subscriptions">
			<mat-card-content>
				<brand-magic-admin-edit-subscription-level
					[subscriptionLevel]="subscription">
				</brand-magic-admin-edit-subscription-level>
			</mat-card-content>
		</mat-card>
	</div>

	<div
		class="action-buttons">
		<button
			mat-raised-button
			color="primary"
			[disabled]="isLoading"
			(click)="onSave()">
			<mat-icon>save</mat-icon>
			Save changes
		</button>
	</div>

</div>

<div
	*ngIf="personalisation == null"
	class="loading-container">
	<h1>Loading personalisation content</h1>

	<ng-container
		*ngIf="isLoaded">
		<h3>
			<mat-icon color="warn">warning</mat-icon>
			We could not load any personalisation values
		</h3>

		<p>
			Most likely this means that your login has expired,
			but we haven't noticed and redirected you to the login
			page.
		</p>

		<p>
			You might try reloading this webpage to see if that helps.
			In the mean time, we will look into what caused this issue
			and try to fix it as soon as possible.
		</p>
	</ng-container>
</div>


<div>
	<div
		*ngIf="isLoaded && personalisation != null"
		class="container">

		<div class="personalise-menu">
			<mat-selection-list [multiple]="false">
				<mat-list-option
					*ngFor="let option of availableOptions"
					[selected]="selectedOption === option"
					(click)="onMenuItemSelected(option)">
					{{ option.text }}
				</mat-list-option>
			</mat-selection-list>
		</div>

		<mat-divider vertical="true"></mat-divider>

		<div class="personalise-content">
			<ng-container
				class="not-paid"
				*ngIf="!isPaid">
				<h1>Personalisation settings</h1>

				<h2>This feature is only available for Magician and Wizard subscribers.</h2>

				<h3>Please consider upgrading your subscription.</h3>

				<p>
					<a
						mat-raised-button
						routerLink="/pages/subscription">
						Upgrade your subscription
					</a>
				</p>
			</ng-container>

			<div
				[ngClass]="{'unavailable': !isPaid, 'personalise-container': true}">

				<div
					*ngIf="errors.length > 0"
					class="errors">
					<h1>We found some errors with the information you provided</h1>

					<div>
						You did not provide values for some required fields.
					</div>

					<div>
						Please double-check the form and try again.
					</div>

					<brand-magic-error-display
						[errors]="errors">
					</brand-magic-error-display>
				</div>

				<ng-container
					*ngIf="selectedOption.code === 'primary'">
					<h1>Primary colour settings</h1>

					<p>
						These are the primary colours that will control how the test is
						displayed on your website.
					</p>

					<p>
						You can use the colour pickers to convert from different
						colour representations into HEX colours
					</p>

					<brand-magic-personalisation-primary-edit
						*ngIf="personalisation != null"
						[personalisation]="personalisation">
					</brand-magic-personalisation-primary-edit>

				</ng-container>


				<ng-container
					*ngIf="selectedOption.code === 'accent'">
					<h1>Accent colour settings</h1>

					<p>
						These colours are used to provide contrast and highlight specific areas
						of a page.
					</p>

					<brand-magic-personalisation-accent-edit
						*ngIf="personalisation != null"
						[personalisation]="personalisation">
					</brand-magic-personalisation-accent-edit>
				</ng-container>

				<ng-container
					*ngIf="selectedOption.code === 'settings'">
					<div class="settings-container">
						<h1>Settings</h1>

						<brand-magic-personalisation-settings-edit
							[personalisation]="personalisation">
						</brand-magic-personalisation-settings-edit>
					</div>
				</ng-container>

				<ng-container
					*ngIf="selectedOption.code === 'personal-details'">
					<div class="personal-details-container">
						<h1>Personal Details page</h1>

						<brand-magic-personalisation-personaldetails-edit
							[personalisation]="personalisation">
						</brand-magic-personalisation-personaldetails-edit>
					</div>
				</ng-container>

				<ng-container
					*ngIf="selectedOption.code === 'instructions'">
					<div class="instructions-container">
						<h1>Instructions page</h1>

						<brand-magic-personalisation-instructions-edit
							[personalisation]="personalisation">
						</brand-magic-personalisation-instructions-edit>

					</div>
				</ng-container>

				<ng-container
					*ngIf="selectedOption.code === 'payment'">
					<div class="payment-container">
						<h1>Payment page</h1>

						<brand-magic-personalisation-payment-edit
							[personalisation]="personalisation">
						</brand-magic-personalisation-payment-edit>
					</div>
				</ng-container>

				<ng-container
					*ngIf="selectedOption.code === 'quiz-results'">
					<h1>Test results page</h1>

					<p>
						We encourage you to use this opportunity to get the lead to take further action.
						Ideas include:
					</p>

					<ul>
						<li>download a white paper</li>
						<li>book in for a free 20min call</li>
						<li>direct them to an online program</li>
					</ul>

					<p>
						To personalise your message, use these codes:
					</p>

					<dl>
						<dt>{{ "{{{ PLAYER_NAME }}}" }}</dt>
						<dd>The name of the person playing the test</dd>
						<dt>{{ "{{{ PERSONALITY }}}" }}</dt>
						<dd>The name of the selected personality</dd>
						<dt>{{ "{{{ PERSONALITY_DETAIL_LINK }}}" }}</dt>
						<dd>
							This produces a link back to the full description for the user's
							chosen personality
						</dd>
						<dt>{{ "{{{ RESULT }}}" }}</dt>
						<dd>
							This the content block defined in the personality
							configuration block. We would expect RESULT to
							be used in the email (instead of the test response page),
							but it is included here as an option
						</dd>
						<dt>{{ "{{{ LINK_TO_QUIZ_HOME \"Test home\" }}}" }}</dt>
						<dd>
							This produces a link back to the homepage of your test.
							You can change the text inside of the quotes ("Test home")
							if you want to change the text displayed
						</dd>
						<dt>{{ "{{{ CTA1_BUTTON \"Button text 1\" }}}" }}</dt>
						<dd>Create a button that links to the CTA 1 URL in your personalisation</dd>
						<dt>{{ "{{{ CTA2_BUTTON \"Button text 2\" }}}" }}</dt>
						<dd>Create a button that links to the CTA 2 URL in your personalisation</dd>
					</dl>

					<brand-magic-personalisation-quizresults-edit
						[personalisation]="personalisation"
						[replacementValues]="replacementValues"
						[availablePersonalityControlGroups]="availablePersonalityControlGroups"
						[(selectedPersonalityGroup)]="selectedPersonalityGroup">
					</brand-magic-personalisation-quizresults-edit>
				</ng-container>

				<ng-container
					*ngIf="selectedOption.code === 'personalities'">
					<div class="personalities-container">
						<div class="personalities-menu">
							<mat-selection-list [multiple]="false">
								<mat-list-option
									*ngFor="let grp of availablePersonalityControlGroups"
									[selected]="selectedPersonalityGroup === grp"
									(click)="onPersonalitySelected(grp)">
									<div class="personality-select">
										<span class="svg" [innerHTML]="getSvg(grp.personality)"></span>
										<span
											*ngIf="grp.hasErrors"
											class="warning">*</span>
										<span
											[ngClass]="{ 'warning': grp.hasErrors }">
											{{ grp.personality.name }}
										</span>
									</div>
								</mat-list-option>
							</mat-selection-list>
						</div>

						<mat-divider vertical="true"></mat-divider>

						<div class="personalities-edit">
							<h1>Brand personality content</h1>

							<h2
								*ngIf="selectedPersonalityGroup != null">
								Content for <i>The {{ selectedPersonalityGroup.personality.name }}</i>
							</h2>

							<p>
								This copy is pulled into the result emails (see email template).
								It explains to your test player (lead) in more detail what their
								Brand Personality is all about.
							</p>

							<p class="warning">
								We recommend that you do not edit the main body of copy unless you
								know the personalities extremely well.
							</p>

							<brand-magic-personalisation-emails-edit
								[personalisation]="personalisation"
								[personality]="selectedPersonalityGroup.personality">
							</brand-magic-personalisation-emails-edit>
						</div>
					</div>
				</ng-container>

				<ng-container
					*ngIf="selectedOption.code === 'emails'">
					<h1>Email templates</h1>

					<p>
						This template provides the content for the test results emails that are sent to
						your leads.
					</p>

					<p class="warning">
						We strongly advise against removing the <code>{{ "{{ RESULT }}" }}</code> code,
						as it should provide the bulk of the email content pertaining to the
						selected personality.
					</p>

					<p>
						To personalise the email message, use these codes:
					</p>

					<dl>
						<dt>{{ "{{{ PLAYER_NAME }}}" }}</dt>
						<dd>The name of the person playing the test</dd>
						<dt>{{ "{{{ RESULT }}}" }}</dt>
						<dd>
							This the content block defined in the personality
							configuration block.
						</dd>
						<dt>{{ "{{{ LINK_TO_QUIZ_HOME \"Link text goes here\" }}}" }}</dt>
						<dd>This produces a link back to the homepage of your test</dd>
						<dt>{{ "{{{ CTA1_BUTTON \"Button text 1\" }}}" }}</dt>
						<dd>Create a button that links to the CTA 1 URL in your personalisation</dd>
						<dt>{{ "{{{ CTA2_BUTTON \"Button text 2\" }}}" }}</dt>
						<dd>Create a button that links to the CTA 2 URL in your personalisation</dd>
					</dl>

					<brand-magic-personalisation-resultemails-edit
						[personalisation]="personalisation"
						[replacementValues]="replacementValues">
					</brand-magic-personalisation-resultemails-edit>

				</ng-container>

				<div class="action-buttons">
					<button
						mat-raised-button
						color="primary"
						(click)="onSave()">
						<mat-icon>save</mat-icon>
						Save changes
					</button>
				</div>
			</div>

		</div>
	</div>
</div>

import { Component } from '@angular/core';
import {
	Router,
	ActivatedRoute,
	ParamMap,
} from '@angular/router';
import { environment } from '../../../environments/environment';
import { StringUtil } from '@brand-magic/lib-util';
import {
	ILicensee,
	ISubscriptionLevel,
} from '@brand-magic/lib-types';
import { LicenseeService } from '../../services/licensee.service';
import { SubscriptionService } from '../../services/subscription.service';

@Component({
	selector: 'brand-magic-admin-licensee-edit',
	templateUrl: './admin-licensee-edit.template.html',
	styleUrls: ['./admin-licensee-edit.style.scss'],
})
export class AdminLicenseeEditComponent {
	public errorMessage = 'We were not able to load this licensee.';
	public errors: string[] = [];

	private licenseeId: string | null = null;
	public selectedLicensee: ILicensee | null = null;

	public allSubscriptionLevels: ISubscriptionLevel[] | null = null;

	public editStripeFields = false;
	public editSubscriptionFields = false;
	public editAdminFields = false;
	public editEnabledFields = false;
	public editFields = false;
	public enableSave = false;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private licenseeService: LicenseeService,
		private subscriptionService: SubscriptionService,
	) {
	}

	public ngOnInit() {
		let rt = this.route;
		if (this.route.children.length > 0) {
			rt = this.route.children[0];
		}

		rt.paramMap.subscribe(async (params: ParamMap) => {
			this.licenseeId = params.get('licenseeId');
			if (StringUtil.nullOrEmpty(this.licenseeId)) {
				this.errors.push('No licensee parameter was passed');
				return;
			}

			const subscriptionResponse = await this.subscriptionService.getSubscriptionLevels();
			if (!subscriptionResponse.success) {
				this.errors.push('We were not able to load subscription data: ' + subscriptionResponse.helpMessage);
				return;
			}
			this.allSubscriptionLevels = subscriptionResponse.data;

			this.loadLicensee();
		});
	}

	private async loadLicensee() {
		const licenseeResponse = await this.licenseeService.getLicenseeById(this.licenseeId as string);
		if (!licenseeResponse.success) {
			this.errors.push(licenseeResponse.helpMessage as string);
			return;
		}

		this.selectedLicensee = licenseeResponse.data as ILicensee;
	}

	public onToggleEditStripe() {
		if (this.licenseeId === environment.brandMagicAdminId) {
			if (!confirm('Editing the Stripe account associated with this brand magic account will likely cause the application to stop working correctly.\n\nARE YOU SURE you know what you are doing?')) {
				return;
			}
		}
		this.editStripeFields = !this.editStripeFields;
		this.enableSave = true;
	}

	public onToggleEditSubscription() {
		if (this.licenseeId === environment.brandMagicAdminId) {
			if (!confirm('Editing the Subscription level associated with this brand magic account will likely cause the application to stop working correctly.\n\nARE YOU SURE you know what you are doing?')) {
				return;
			}
		}
		this.editSubscriptionFields = !this.editSubscriptionFields;
		this.enableSave = true;
	}

	public onToggleEditAdmin() {
		if (this.licenseeId === environment.brandMagicAdminId) {
			if (!confirm('Editing the Stripe account associated with this brand magic account will likely cause the application to stop working correctly.\n\nARE YOU SURE you know what you are doing?')) {
				return;
			}
		}
		this.editAdminFields = !this.editAdminFields;
		this.enableSave = true;
	}

	public onToggleEditActive() {
		if (this.licenseeId === environment.brandMagicAdminId) {
			if (!confirm('Disabling the Stripe account associated with this brand magic account will likely cause the application to stop working correctly.\n\nARE YOU SURE you know what you are doing?')) {
				return;
			}
		}
		this.editEnabledFields = !this.editEnabledFields;
		this.enableSave = true;
	}

	public onCancel() {
		this.router.navigateByUrl('/admin/licensees');
	}

	public async onSaveLicensee() {
		const updateData = {
			'stripe.customerId': this.selectedLicensee?.stripe.customerId,
			'stripe.accountId': this.selectedLicensee?.stripe.accountId,
			'subscriptionId': this.selectedLicensee?.subscriptionId,
			'isAdmin': this.selectedLicensee?.isAdmin,
			'isActive': this.selectedLicensee?.isActive,
 		}

		const updateResponse = await this.licenseeService.updateLicenseeById(this.licenseeId as string, updateData);
		if (!updateResponse.success) {
			this.errors.push(updateResponse.helpMessage as string);
		} else {
			this.router.navigateByUrl('/admin/licensees');
		}
	}
}


<div class="embedding-container">
	<div class="embedding-menu">
		<mat-selection-list [multiple]="false">
			<mat-list-option
				*ngFor="let option of availableOptions"
				[selected]="selectedOption === option"
				(click)="onMenuItemSelected(option)">
				{{ option.text }}
			</mat-list-option>
		</mat-selection-list>

		<mat-divider *ngIf="selectedOption.code === 'personality-codes'"></mat-divider>

		<div
			class="personalities-menu"
			*ngIf="selectedOption.code === 'personality-codes'">
			<mat-selection-list [multiple]="false">
				<mat-list-option
					*ngFor="let personality of availablePersonalities"
					[selected]="selectedPersonality === personality"
					(click)="onPersonalitySelected(personality)">
					<span class="svg" [innerHTML]="getSvg(personality)"></span>
					The {{ personality.name }}
				</mat-list-option>
			</mat-selection-list>
		</div>
	</div>

	<mat-divider vertical="true"></mat-divider>

	<div class="embedding-content">
		<ng-container *ngIf="selectedOption.code === 'quiz-codes'">
			<h1>Test embedding code</h1>

			<h2
				*ngIf="!showEmbeddingCode">
				This feature is only available after you upgrade your subscription and
				complete your account sign-up.
			</h2>

			<div
				*ngIf="!showEmbeddingCode"
				class="action-buttons">
				<button
					mat-stroked-button
					color="primary"
					(click)="onCreateAccountLink()">
					<mat-icon>open_in_browser</mat-icon>
					Add banking details
				</button>
			</div>

			<p>
				To display the test on your website, you will need the below
				code which will create an iframe of the test directly on your
				website. If you aren’t confident with websites please ask your
				website developer to enter the html code below into a page on
				your website. This will embed and display the test on any page
				of your choosing on your website. If you feel comfortable
				adding code to your site follow the below instructions or
				watch the video tutorial:
			</p>

			<ol>
				<li>Create a new page on your website. We recommend naming it personality test or something similar</li>
				<li>Paste the below code into the page while on code view</li>
				<li>Save page</li>
			</ol>

			<div
				[ngClass]="{'unavailable': !showEmbeddingCode}">
				<mat-card>
					<mat-card-title>
							Embedding code for the <i>Personality Test</i>
					</mat-card-title>
					<mat-card-content>
						<div class="code-display">
							<p>{{ getQuizCode() }}</p>
						</div>
					</mat-card-content>
					<mat-card-actions>
						<button
							color="primary"
							mat-stroked-button
							(click)="onCopyQuizCode()">
							Copy embedding code
						</button>
					</mat-card-actions>
				</mat-card>
			</div>
		</ng-container>

		<ng-container *ngIf="selectedOption.code === 'personality-codes'">
			<h1>Personality embedding codes</h1>

			<h2
				*ngIf="!showEmbeddingCode">
				This feature is only available after you complete you account sign-up.
			</h2>

			<div
				*ngIf="!showEmbeddingCode"
				class="action-buttons">
				<button
					mat-stroked-button
					color="primary"
					(click)="onCreateAccountLink()">
					<mat-icon>open_in_browser</mat-icon>
					Add banking details
				</button>
			</div>

			<p>
				We have created iframes of every personality
				page. This will make it super easy to add the
				personality content to your site, save you time and
				ensure that if we update any master content, the change will
				immediately be reflected on your website. If you feel
				comfortable adding code to your site follow the
				below instructions or watch the video tutorial:
			</p>

			<ol>
				<li>Create a new page for each personality on your website</li>
				<li>Copy the iframe code and paste in into your page while in code view</li>
				<li>To ensure that all of your correspondence sends the client back to your site, copy your page URL</li>
				<li>Paste it into the Personalised URL relating to each personality</li>
			</ol>

			<div
				[ngClass]="{'unavailable': !showEmbeddingCode}">
				<mat-card>
					<mat-card-title>
						Embedding code for <i>The {{ selectedPersonality?.name ?? 'selected' }}</i> personality
					</mat-card-title>
					<mat-card-content>
						<div class="code-display">
							<p>{{ getPersonalityEmbedCode() }}</p>
						</div>
					</mat-card-content>
					<mat-card-actions>
						<button
							color="primary"
							mat-stroked-button
							(click)="onCopyPersonalityCode()">
							Copy embedding code
						</button>
					</mat-card-actions>
				</mat-card>
			</div>
		</ng-container>
	</div>
</div>

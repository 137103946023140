import {
	Component,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {
	IHttpResponse,
	ILicensee,
} from '@brand-magic/lib-types';
import { LicenceService } from '../../services/licence.service';
import { LicenseeService } from '../../services/licensee.service';

interface SaveEvent{
	licensee: ILicensee,
	password: string | null,
}

@Component({
	selector: 'brand-magic-account-details',
	templateUrl: './account-details.template.html',
	styleUrls: ['./account-details.style.scss'],
})
export class AccountDetailsComponent {
	public errors: string[] = [];

	public availableOptions = [{
		code: 'contact',
		text: 'Contact information'
	}, {
		code: 'payment',
		text: 'Payment details'
	}, {
		code: 'licence',
		text: 'Licence agreement'
	}];
	public selectedOption = this.availableOptions[0];

	public licensee!: ILicensee;

	public licenceIsLoading = true;
	public licenceHtml: any = '';

	constructor(
		private sanitizer: DomSanitizer,
		private licenceService: LicenceService,
		private licenseeService: LicenseeService,
	) {
		this.loadLicensee();
		this.loadLicence();
	}

	private async loadLicensee() {
		this.licenseeService.getCurrentLicensee().then((licenseeResponse: IHttpResponse<ILicensee>) => {
			if (!licenseeResponse.success) {
				this.errors.push(licenseeResponse.helpMessage as string);
				return;
			}
			this.licensee = licenseeResponse.data as ILicensee;
		});
	}

	private async loadLicence() {
		this.licenceIsLoading = true;

		this.errors.length = 0;

		const response = await this.licenceService.getCurrentLicence();
		if (!response.success) {
			this.errors.push(response.helpMessage as string);
		} else {
			const licenceContent = response.data?.content;
			if (licenceContent != null) {
				this.licenceHtml = this.sanitizer.bypassSecurityTrustHtml(licenceContent);
			}
		}

		this.licenceIsLoading = false;
	}

	public async onUpdateLicensee(event: SaveEvent): Promise<boolean> {
		this.errors.length = 0;

		const response = await this.licenseeService.updateLicenseeDetails(event.licensee);
		if (!response.success) {
			this.errors.push(response.helpMessage as string);
		}

		return response.success;
	}

	public onMenuItemSelected(option: any) {
		this.selectedOption = option;
	}

}

import { Injectable } from '@angular/core';
import {
	Router,
	ActivatedRouteSnapshot,
	CanActivate,
	CanActivateChild,
	RouterStateSnapshot,
	UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';

import {
	AuthService
} from '@brand-magic/lib-auth';
import {
	LicenseeService
} from '../../services/licensee.service'

@Injectable({
	providedIn: 'root'
})
export class AdminAuthGuard implements CanActivate, CanActivateChild {
	private static readonly LOGIN_PATH = '/auth/login';

	constructor(
		private router: Router,
		private authService: AuthService,
		private licenseeService: LicenseeService,
	) {
	}

	public async canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Promise<boolean | UrlTree> {
		if (!this.authService.isLoggedIn()) {
			this.authService.setRedirectUrl(state.url);
			return this.router.parseUrl('/auth/login');
		}

		const licenseeResponse = await this.licenseeService.getCurrentLicensee()
		if (!licenseeResponse.success) {
			console.log(licenseeResponse.helpMessage as string);
		}

		return licenseeResponse?.data?.isAdmin ?? false;
	}

	public canActivateChild(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return this.canActivate(route, state);
	}

}

import {
	Component,
	OnInit,
} from '@angular/core';
import {
	CurrencyPipe
} from '@angular/common';
import {
	Router,
} from '@angular/router';
import { QuizService } from '../../services/quiz.service';
import {
	IQuizData,
	IQuestion,
	IQuizPrice,
	QuizType,
	IHttpResponse,
} from '@brand-magic/lib-types';
import {
	StringUtil,
	TypeUtil,
} from '@brand-magic/lib-util';

interface IQuizSelector {
	text: string;
	quizType: string;
	quiz: IQuizData | null;
	isLoaded: boolean;
}

@Component({
	selector: 'brand-magic-admin-quiz-edit',
	templateUrl: './admin-quiz-edit.template.html',
	styleUrls: ['./admin-quiz-edit.style.scss'],
})
export class AdminQuizEditComponent implements OnInit {
	public errors: string[] = [];

	public availableQuizTypes: IQuizSelector[] = [{
		text: 'Business',
		quizType: QuizType.Business,
		quiz: null,
		isLoaded: false,
	}, {
		text: 'Personal',
		quizType: QuizType.Personal,
		quiz: null,
		isLoaded: false,
	}];

	public quizPrice: IQuizPrice | null = null;
	public priceIsLoaded = false;
	public costPerPlay = StringUtil.empty;
	public feePerPlay = StringUtil.empty;
	public currency = StringUtil.empty;
	public playsPerPurchase = StringUtil.empty;

	public selectedQuizType: (IQuizSelector | IQuizPrice) = this.availableQuizTypes[0];

	constructor(
		private router: Router,
		private currencyPipe: CurrencyPipe,
		private quizService: QuizService,
	) {
	}

	// Note that we are reusing quiz configs for prices, which is dumb, but whatever

	public ngOnInit() {
		this.quizService.getLatestQuiz(QuizType.Business).then((response: IHttpResponse<IQuizData> | null) => {
			if (response == null) {
				this.errors.push('Unfortunately we could not load the quiz at this time');
				return;
			}

			if (!response.success) {
				this.errors.push(response.helpMessage as string);
			}

			const quizType = this.availableQuizTypes[0];
			quizType.isLoaded = true;

			if (response == null || response.data == null) {
				this.errors.push('Unable to load latest business quiz');
				quizType.quiz = this.getEmptyQuiz(QuizType.Business);
				return;
			}

			quizType.quiz = response.data;
		});

		this.quizService.getLatestQuiz(QuizType.Personal).then((response: IHttpResponse<IQuizData> | null) => {
			if (response == null) {
				this.errors.push('Unfortunately we could not load the quiz at this time');
				return;
			}

			if (!response.success) {
				this.errors.push(response.helpMessage as string);
			}

			const quizType = this.availableQuizTypes[1];
			quizType.isLoaded = true;

			if (response == null || response.data == null) {
				this.errors.push('Unable to load latest personal quiz');
				quizType.quiz = this.getEmptyQuiz(QuizType.Personal);
				return;
			}

			quizType.quiz = response.data;
		});

		this.quizService.getLatestPrice().then((response: IHttpResponse<IQuizPrice>) => {
			this.priceIsLoaded = true;
			if (!response.success) {
				this.errors.push('Unable to load latest prices: ' + response.helpMessage);
				return;
			}

			this.quizPrice = response.data as IQuizPrice;
			this.costPerPlay = this.asCurrency(this.quizPrice.costPerPlay);
			this.feePerPlay = this.asCurrency(this.quizPrice.feePerPlay);
			this.currency = this.quizPrice.currency;
			this.playsPerPurchase = this.quizPrice.playsPerPurchase.toString();
		});
	}

	private asCurrency(val: any) {
		if (val == null) {
			return '';
		}

		const fromCents = val / 100;
		const formatted = this.currencyPipe.transform(fromCents, 'USD', '');
		return formatted ?? '';
	}

	public onMenuItemSelected(option: any) {
		this.selectedQuizType = option;
	}

	private getEmptyQuiz(quizType: string): IQuizData {
		const quiz: IQuizData = {
			quizId: StringUtil.empty,
			quizType: quizType,
			questions: []
		};

		for (let i = 0; i < 12; i++) {
			const question: IQuestion = {
				question: StringUtil.empty,
				hint: StringUtil.empty,
				options: [],
			};
			quiz.questions.push(question);

			for (let j = 0; j < 12; j++) {
				question.options.push({
					answer: StringUtil.empty,
					weightings: []
				});
			}
		}

		return quiz;
	}

	public async onSaveQuiz(quiz: IQuizData) {
		this.errors.length = 0;
		const quizId = await this.quizService.saveQuiz(quiz);
		if (quizId == null) {
			this.errors.push('There was a problem saving this quiz - please try again later');
		}
	}

	public async onSavePrice() {
		this.errors.length = 0;

		const costPerPlay = Number.parseFloat(this.costPerPlay) * 100;
		const feePerPlay = Number.parseFloat(this.feePerPlay) * 100;
		const currency = this.currency;
		const playsPerPurchase = Number.parseFloat(this.playsPerPurchase);

		if (!TypeUtil.isNumber(costPerPlay)) {
			this.errors.push('Cost per play does not appear to be a valid number');
		}
		if (!TypeUtil.isNumber(feePerPlay)) {
			this.errors.push('Fee per play does not appear to be a valid number');
		}
		if (StringUtil.nullOrEmpty(this.currency) || this.currency.length !== 3) {
			this.errors.push('The value for the transaction currency does not appear to be valid');
		}
		if (!TypeUtil.isNumber(playsPerPurchase)) {
			this.errors.push('Plays per purchase does not appear to be a valid number');
		}
		if (this.errors.length > 0) {
			return;
		}

		// make sure we have made a change
		if (
			costPerPlay === this.quizPrice?.costPerPlay &&
			feePerPlay === this.quizPrice?.feePerPlay &&
			currency === this.quizPrice?.currency &&
			playsPerPurchase === this.quizPrice?.playsPerPurchase
		) {
			this.router.navigateByUrl('/pages/home');
		}

		const newPrice: IQuizPrice = {
			quizPriceId: StringUtil.empty,
			costPerPlay: costPerPlay,
			feePerPlay: feePerPlay,
			currency: currency.toLowerCase(),
			playsPerPurchase: playsPerPurchase,
		}
		const quizResponse = await this.quizService.savePrice(newPrice);
		if (quizResponse.success) {
			this.router.navigateByUrl('/pages/home');
		} else {
			this.errors.push('We were unable to update prices: ' + quizResponse.helpMessage);
		}
	}

	public getTotalCost() {
		return this.calculateTotal(this.costPerPlay);
	}

	public getTotalFees() {
		return this.calculateTotal(this.feePerPlay);
	}

	private calculateTotal(val: any) {
		if (val == null) {
			return 'No value present';
		}

		const inCents = Number.parseFloat(val);
		const amount = Number.parseFloat(this.playsPerPurchase);
		const total = inCents * amount;
		if (isNaN(total)) {
			return 'One of your values has an error in it';
		}

		return this.currencyPipe.transform(total, this.currency.toUpperCase());
	}
}

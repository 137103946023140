import { Injectable } from '@angular/core';
import {
	AuthService,
} from '@brand-magic/lib-auth';
import {
	ICachingService
} from './caching-service.interface';
import {
	AdminService
} from './admin.service';
import {
	GraphService
} from './graph.service';
import {
	LeadService
} from './lead.service';
import {
	LicenseeService
} from './licensee.service';
import {
	PersonalisationService
} from './personalisation.service';
import {
	SubscriptionService
} from './subscription.service';

@Injectable({
  providedIn: 'root'
})
export class CacheManagementService {
	private cachingServices: ICachingService[];

	constructor(
		authService: AuthService,
		adminService: AdminService,
		graphService: GraphService,
		leadService: LeadService,
		licenseeService: LicenseeService,
		personalisationService: PersonalisationService,
		subscriptionService: PersonalisationService,
	) {
		this.cachingServices = [
			adminService,
			graphService,
			leadService,
			licenseeService,
			personalisationService,
			subscriptionService,
		];

		authService.onLogout(() => {
			this.clearCaches();
		});
	}

	public clearCaches() {
		for (let service of this.cachingServices) {
			service.clearCaches();
		}
	}

	public enableCaching(isEnabled: boolean) {
		for (let service of this.cachingServices) {
			service.enableCaching(isEnabled);
		}
	}
}


<h1 mat-dialog-title>You need to finish adding your details to Stripe before accessing this feature</h1>

<div mat-dialog-content>
	<p>
		We can only enable this feature when we have a means to pay commissions to you.
	</p>

	<p>
		To achieve this, we have partnered with Stripe for our ecommerce needs. Stripe employs
		industry-leading security and anti-fraud measures to ensure that your data is secured safely.
	</p>

</div>

<div mat-dialog-actions>
	<button
		mat-raised-button
		mat-dialog-close>
		Close
	</button>
</div>

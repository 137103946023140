
<div class="container">

	<h2>{{ subscriptionLevel.name }}</h2>

	<mat-form-field appearance="fill">
		<mat-label>
			Name
		</mat-label>
		<input
			matInput
			[(ngModel)]="subscriptionLevel.name">
	</mat-form-field>

	<div class="price-display"
		*ngIf="subscriptionLevel.isFree">
		Price per month: Free
	</div>

	<div class="price-display"
		*ngIf="!subscriptionLevel.isFree">
		Price per month: {{ subscriptionLevel.dollarsPerMonth | currency:'USD' }}
	</div>

	<div
		*ngIf="!subscriptionLevel.isFree"
		class="benefit-price">
		<mat-form-field appearance="fill">
			<mat-label>
				Price per month
			</mat-label>
			<input
				matInput
				[(ngModel)]="subscriptionLevel.dollarsPerMonth">
		</mat-form-field>
	</div>

	<mat-checkbox
		class="example-margin"
		[(ngModel)]="subscriptionLevel.isFree">
		Is free
	</mat-checkbox>

	<mat-checkbox
		class="example-margin"
		[(ngModel)]="subscriptionLevel.receivesFreeCoupon"
		(click)="onClickFreeCoupons()">
		Free coupon every month
	</mat-checkbox>

	<mat-form-field appearance="fill">
		<mat-label>
			Number of free coupons
		</mat-label>
		<input
			matInput
			[(ngModel)]="subscriptionLevel.freeCouponCount"
			[disabled]="!subscriptionLevel.receivesFreeCoupon">
	</mat-form-field>
	
	<h4>Benefits</h4>

	<div
		cdkDropList
		[cdkDropListData]="subscriptionLevel.benefits"
		class="benefits"
		(cdkDropListDropped)="onDropped($event)">
		<ng-container
			*ngFor="let benefit of subscriptionLevel.benefits">
			<div class="benefit-container"
				 cdkDrag
				 cdkDragLockAxis="y">
				<div
					class="drop-placeholder"
					*cdkDragPlaceholder>
				</div>
				<div class="drag-handle">
					<mat-icon>drag_indicator</mat-icon>
				</div>
				<div class="benefit">
					{{ benefit }}
				</div>
				<button
					mat-icon-button
					(click)="onDelete(benefit)">
					<mat-icon>cancel</mat-icon>
				</button>

			</div>
		</ng-container>
	</div>

	<div class="benefit-text">
		<mat-form-field appearance="fill">
			<mat-label>
				Benefit text
			</mat-label>
			<input
				matInput
				placeholder="Ex. Access to coupons"
				[(ngModel)]="newBenefitName">
		</mat-form-field>
	</div>

	<div>
		<button
			mat-raised-button
			(click)="onAddBenefit()">
			Add benefit
		</button>
	</div>

</div>

import { Injectable } from '@angular/core';
import { HttpService } from '@brand-magic/lib-http';
import {
	IEmail,
	IHttpResponse,
} from '@brand-magic/lib-types';

@Injectable({
	providedIn: 'root'
})
export class EmailService {

	constructor(
		private httpService: HttpService,
	) {
	}

	public async sendEmail(email: IEmail): Promise<IHttpResponse<any>> {
		try {
			const request = {
				email: email
			};
			const response = await this.httpService.post<any, any>('/api/send-email', request);

			if (!response.success) {
				console.log('Could not send email: ' + response.helpMessage);
			}

			return response;
		} catch (exception: any) {
			console.error(exception);
			return {
				success: false,
				helpMessage: exception.toString(),
				data: null,
			};
		}
	}
}


<div class="container">
	<h1>Purchase coupons</h1>

	<div
		*ngIf="errors.length > 0"
		class="errors">
		<h1>We encountered some errors related to your purchase</h1>

		<div>
			Unfortunately we encountered some errors. The details are as follows:
		</div>

		<brand-magic-error-display
			[errors]="errors">
		</brand-magic-error-display>
	</div>

	<div
		*ngIf="isLoading">
		<h2>Loading coupon information</h2>

		<div class="loading">
			<mat-spinner></mat-spinner>
		</div>

	</div>

	<div
		class="stripe-container"
		*ngIf="clientSecret != null">

		<h3>Purchase {{ count }} coupons for {{ count * costPerCoupon | currency:'USD' }}</h3>

		<ngx-stripe-payment
			[clientSecret]="getClientSecret()">
		</ngx-stripe-payment>

		<div class="action-buttons">
			<button
				mat-raised-button
				color="primary"
				(click)="onMakePaymentClicked()">
				Purchase coupons
			</button>

			<button
				mat-raised-button
				(click)="onCancelClicked()">
				Cancel
			</button>
		</div>
	</div>

</div>

<div class="admin container">
	<h1>Brand Magic overview</h1>

	<div>
		<a href="https://dashboard.stripe.com/dashboard" target="_new">
			Open Stripe dashboard
			<mat-icon>open_in_new</mat-icon>
		</a>
	</div>

	<div
		*ngIf="isLoading"
		class="loading">
		<mat-spinner></mat-spinner>
	</div>

	<div
		*ngIf="errors.length > 0"
		class="errors">
		<h1>We encountered an error trying load our stats</h1>

		<ul class="error-list">
			<li
				*ngFor="let error of errors">
				{{ error }}
			</li>
		</ul>
	</div>

	<ng-container
		*ngIf="!isLoading && hasStats()">
		<mat-card>
			<mat-card-content>
				<div class="breakdown-row">
					<div class="text">
						<h2 class="text-title">New licensees in {{ monthAndYear }}</h2>
						<div class="text-container">
							<div class="text-display">
								<h3 class="value">{{ licenseesThisMonth }}</h3>
								<div class="description">Licensees joined this month</div>
							</div>
						</div>
					</div>
					<div class="graph">
						<brand-magic-bar-chart
							[datasets]="licenseesThisMonthDatasets"
							[labels]="licenseesThisMonthLabels">
						</brand-magic-bar-chart>
					</div>
				</div>
			</mat-card-content>
		</mat-card>

		<mat-card>
			<mat-card-content>
				<div class="breakdown-row">
					<div class="text">
						<h2 class="text-title">Total licensees</h2>
						<div class="text-container">
							<div class="text-display">
								<h3 class="value">{{ allLicensees }}</h3>
								<div class="description">Licensee joined over time</div>
							</div>
						</div>
					</div>
					<div class="graph">
						<brand-magic-bar-chart
							[datasets]="allLicenseesDatasets"
							[labels]="allLicenseesLabels">
						</brand-magic-bar-chart>
					</div>
				</div>
			</mat-card-content>
		</mat-card>

		<mat-card>
			<mat-card-content>
				<div class="breakdown-row">
					<div class="text">
						<h2 class="text-title">Test plays in {{ monthAndYear }}</h2>
						<div class="text-container">
							<div class="text-display">
								<h3 class="value">{{ leadsThisMonth }}</h3>
								<div class="description">New leads this month</div>
							</div>
						</div>
					</div>
					<div class="graph">
						<brand-magic-line-chart
							[datasets]="playsThisMonthDatasets"
							[labels]="playsThisMonthLabels">
						</brand-magic-line-chart>
					</div>
				</div>
			</mat-card-content>
		</mat-card>

		<mat-card>
			<mat-card-content>
				<div class="breakdown-row">
					<div class="text">
						<h2 class="text-title">Total plays</h2>
						<div class="text-container">
							<div class="text-display">
								<h3 class="value">{{ allLeads }}</h3>
								<div class="description">Total leads</div>
							</div>
						</div>
					</div>
					<div class="graph">
						<brand-magic-line-chart
							[datasets]="allPlaysDatasets"
							[labels]="allPlaysLabels">
						</brand-magic-line-chart>
					</div>
				</div>
			</mat-card-content>
		</mat-card>

	</ng-container>
</div>

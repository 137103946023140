import {
	Component,
	ViewChild,
	AfterViewInit,
} from '@angular/core';
import { Router } from '@angular/router';
import {
	MatPaginator,
	PageEvent,
} from '@angular/material/paginator';
import { ILicensee } from '@brand-magic/lib-types';
import { LicenseeService } from '../../services/licensee.service';


@Component({
	selector: 'brand-magic-admin-licensees',
	templateUrl: './admin-licensees.template.html',
	styleUrls: ['./admin-licensees.style.scss'],
})
export class AdminLicenseesComponent {
	public errors: string[] = [];
	public isLoaded = false;
	public availablePageSizes: number[] = [5, 10, 15, 20];
	public totalItems = 0;
	public pageSize = 10;
	public currentPage = 0;
	public allLicensees: ILicensee[] = [];
	public dataPage: ILicensee[] = [];

	public displayedColumns = [
		'givenName', 'familyName', 'email',
		'companyName', 'isActive'
	];

	@ViewChild(MatPaginator)
	public paginator!: MatPaginator;

	constructor(
		private router: Router,
		private licenseeService: LicenseeService,
	) {
		this.initLicensees();
	}

	private async initLicensees() {
		const currentLicensee = await this.licenseeService.getCurrentLicensee();
		if (currentLicensee == null) {
			return;
		}

		const licenseesResponse = await this.licenseeService.getAllLicensees();
		if (!licenseesResponse.success) {
			this.errors.push('We were unable to load licensees for the system: ' + licenseesResponse.helpMessage);
		}

		this.allLicensees = licenseesResponse.data as ILicensee[];
		this.isLoaded = true;
		this.onPage({
			length: this.allLicensees.length,
			pageSize: this.pageSize,
			pageIndex: this.currentPage,
		});
	}

	public onPage(evt: PageEvent) {
		const startIndex = evt.pageSize * evt.pageIndex;
		const endIndex = startIndex + evt.pageSize;
		this.dataPage = this.allLicensees.slice(startIndex, endIndex);
	}

	public onLicenseeClicked(licensee: ILicensee) {
		const url = '/admin/licensee/' + licensee.licenseeId;
		this.router.navigateByUrl(url);
	}

}


export class Hsl {
	public h = 0;
	public s = 0;
	public l = 0;

	public toCss() {
		return `hsl(${ this.h }deg ${ this.s }% ${ this.l }%)`;
	}

	public adjustLightness(amount: number) {
		const hsl = new Hsl();
		hsl.h = this.h;
		hsl.s = this.s;
		hsl.l = this.l;

		let offset = hsl.l;
		if (offset < 50) {
			offset = 100 - offset;
		}

		let adjustment = amount + (amount * 10/offset);
		if (this.l > 50) {
			adjustment *= -1;
		}

		hsl.l = Math.round(hsl.l + adjustment);
		hsl.l = Math.max(0, hsl.l);
		hsl.l = Math.min(100, hsl.l);

		return hsl;
	}

	public getMaxContrast() {
		const maxContrast = new Hsl();
		maxContrast.h = this.h;
		maxContrast.s = this.s;
		maxContrast.l = this.l <= 50 ? 100 : 0;

		return maxContrast;
	}

	// roughly from https://stackoverflow.com/questions/3732046/how-do-you-get-the-hue-of-a-xxxxxx-colour
	public static fromHex(hexStr: string) {
		let r = parseInt(hexStr.substr(1,2), 16) / 255;
		let g = parseInt(hexStr.substr(3,2), 16) / 255;
		let b = parseInt(hexStr.substr(5,2), 16) / 255;

		const max = Math.max(r, g, b);
		const min = Math.min(r, g, b);
		let h = (max + min) / 2;
		let s = (max + min) / 2;
		let l = (max + min) / 2;

		if (max == min) {
			h = s = 0; // achromatic
		} else {
			const d = max - min;
			s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
			switch (max) {
				case r:
					h = (g - b) / d + (g < b ? 6 : 0);
					break;
				case g:
					h = (b - r) / d + 2;
					break;
				case b:
					h = (r - g) / d + 4;
					break;
			}
			h /= 6;
		}

		// convert h into degrees
		h *= 360;

		const hsl = new Hsl();
		hsl.h = h * 360; // degrees
		hsl.s = s * 100 // percentage;
		hsl.l = l * 100 // percentage;

		return hsl;
	}
}

import {
	Component,
	Input,
} from '@angular/core';
import {
	CdkDragDrop,
	moveItemInArray,
} from '@angular/cdk/drag-drop';

import {
	ISubscriptionLevel
} from '@brand-magic/lib-types';

@Component({
	selector: 'brand-magic-admin-edit-subscription-level',
	templateUrl: './admin-edit-subscription-level.template.html',
	styleUrls: ['./admin-edit-subscription-level.style.scss'],
})
export class AdminEditSubscriptionLevelComponent {

	@Input()
	public subscriptionLevel!: ISubscriptionLevel;

	public newBenefitName: string = '';

	public freeCouponsCount: number = 0;
	
	constructor(
	) {
	}

	public onDelete(benefit: string) {
		const index = this.subscriptionLevel.benefits.indexOf(benefit);
		if (index >= 0) {
			this.subscriptionLevel.benefits.splice(index, 1);
		}
	}

	public onDropped(dropEvent: any) {
		moveItemInArray(dropEvent.container.data, dropEvent.previousIndex, dropEvent.currentIndex);
	}

	public onAddBenefit() {
		this.subscriptionLevel.benefits.push(this.newBenefitName);
		this.newBenefitName = '';
	}

	public onClickFreeCoupons() {
		this.subscriptionLevel.freeCouponCount = !this.subscriptionLevel.receivesFreeCoupon ? this.subscriptionLevel.freeCouponCount : 0;
	}


}

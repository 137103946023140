<div class="signup-container">
	<mat-form-field class="editor" class="editor">
		<mat-label>Payment description</mat-label>
		<textarea
			[(ngModel)]="personalisation.pages.payment.description"
			[froalaEditor]="htmlEditorOptions"
			matInput>{{ personalisation.pages.payment.description }}</textarea>
		<mat-error>Payment description content is <strong>required</strong></mat-error>
	</mat-form-field>
</div>

import { Component } from '@angular/core';

@Component({
	selector: 'brand-magic-auth-shell',
	templateUrl: './auth-shell.template.html',
	styleUrls: ['./auth-shell.style.scss'],
})
export class AuthShellComponent {

	constructor(
	) {
	}

	ngOnInit(): void {
	}

}

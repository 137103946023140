import {
	Component,
} from '@angular/core';
import {
	Router,
} from '@angular/router';
import {
	AuthService,
	RecoveryRequestEvent,
	RecoveryFailureEvent,
} from '@brand-magic/lib-auth';

@Component({
	selector: 'brand-magic-recover-account',
	templateUrl: './recover-account.template.html',
	styleUrls: ['./recover-account.style.scss'],
})
export class RecoverAccountComponent {
	public showSuccessMessage = false;
	public errorMessage = 'We could not reset your account';
	public errors: string[] = [];

	public working = false;
	public emailAddress: string | null = null;

	constructor(
		private router: Router,
		private authService: AuthService
	) {
	}

	public async onRequest(event: RecoveryRequestEvent) {
		this.errors.length = 0;
		this.emailAddress = event.email;
		this.working = true;

		const response = await this.authService.recoverAccount(event);
		this.working = false;

		if (response.success) {
			this.showSuccessMessage = true;
		} else {
			this.errors.push(response.helpMessage as string);
		}
	}

	public onFail(event: RecoveryFailureEvent) {
		this.errors.length = 0;
		for (let evt of event.helpMessages) {
			this.errors.push(evt);
		}
	}

	public onCancel() {
		this.errors.length = 0;
		this.router.navigateByUrl('/auth/login');
	}

}

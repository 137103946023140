import {
	Component,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {
	ILicenseePersonalisation,
	IPersonalityContent,
	IPersonality,
	ILicensee,
	IPersonalisationReplacementValues,
} from '@brand-magic/lib-types';
import {
	StringUtil,
} from '@brand-magic/lib-util';
import { LicenseeService } from '../../services/licensee.service';
import { PersonalityService } from '../../services/personality.service';
import { PersonalisationService } from '../../services/personalisation.service';

interface IPersonalityContentGroup {
	personality: IPersonality;
	hasErrors: boolean;
}

@Component({
	selector: 'brand-magic-personalise',
	templateUrl: './personalise.template.html',
	styleUrls: ['./personalise.style.scss'],
})
export class PersonaliseComponent {
	private _selectedPersonalityGroup!: IPersonalityContentGroup;
	public isLoaded = false;
	public personalisation: ILicenseePersonalisation | null = null;
	private licensee: ILicensee | null = null;

	public errors: string[] = [];

	public availableOptions = [{
		code: 'primary',
		text: 'Primary colours'
	}, {
		code: 'accent',
		text: 'Accent colours'
	}, {
		code: 'settings',
		text: 'Settings',
	}, {
		code: 'personalities',
		text: 'Personalities'
	}, {
		code: 'personal-details',
		text: 'Personal Details page'
	}, {
		code: 'payment',
		text: 'Payment page'
	}, {
		code: 'instructions',
		text: 'Instructions page'
	}, {
		code: 'quiz-results',
		text: 'Test results page'
	}, {
		code: 'emails',
		text: 'Emails'
	}];

	public selectedOption = this.availableOptions[0];
	public availablePersonalityControlGroups: IPersonalityContentGroup[] = [];

	public get selectedPersonalityGroup(): IPersonalityContentGroup {
		return this._selectedPersonalityGroup;
	}

	public set selectedPersonalityGroup(group: IPersonalityContentGroup) {
		this._selectedPersonalityGroup = group;
		this.updateReplacementValues();
	}

	public isPaid = false;

	public replacementValues: IPersonalisationReplacementValues = {
		PLAYER_NAME: '',
		PERSONALITY: '',
		RESULT: '',
		QUIZ_HOME_URL: '',
		PERSONALITY_DETAIL_URL: '',
		PERSONALITY_DETAIL_LINK: '',
		CTA1_URL: '',
		CTA2_URL: '',
	};

	constructor(
		private sanitizer: DomSanitizer,
		private licenseeService: LicenseeService,
		private personalisationService: PersonalisationService,
		private personalityService: PersonalityService,
	) {
		this.loadPersonalisation();
		this.loadIsPaidSubscriber();
	}

	private async loadPersonalisation() {
		this.isLoaded = true;
		const licenseeResponse = await this.licenseeService.getCurrentLicensee();
		if (!licenseeResponse.success) {
			this.errors.push('We were unable to load your details: ' + licenseeResponse.helpMessage);
		} else {
			this.licensee = licenseeResponse.data as ILicensee;
		}
		const personalisationResponse = await this.personalisationService.getCurrentPersonalisation();
		if (!personalisationResponse.success) {
			this.errors.push('We were unable to load your personalisations: ' + personalisationResponse.helpMessage);
		}
		this.personalisation = personalisationResponse.data;
		this.loadPersonalities();
	}

	private async loadIsPaidSubscriber() {
		const response = await this.licenseeService.isPaidSubscriber();
		if (!response.success) {
			this.errors.push(response.helpMessage as string);
			return;
		}
		this.isPaid = response.data as boolean;
	}

	private async loadPersonalities() {
		if (this.personalisation == null) {
			return;
		}
		const personalities = this.personalityService.getPersonalities();
		this.availablePersonalityControlGroups = personalities.map((p: IPersonality) => {
			const propertyKey = p.personalityId as keyof typeof this.personalisation.personality;
			const pers = this.personalisation as ILicenseePersonalisation;

			return {
				personality: p,
				hasErrors: false,
			};
		});
		this.selectedPersonalityGroup = this.availablePersonalityControlGroups[0];
		this.updateReplacementValues();
	}

	public onPersonalitySelected(group: IPersonalityContentGroup) {
		this.selectedPersonalityGroup = group;
	}

	private updateReplacementValues() {
		if (
			this.licensee == null ||
				this.selectedPersonalityGroup == null ||
				this.personalisation == null
		) {
			return;
		}

		const personalityId = this.selectedPersonalityGroup.personality.personalityId as keyof typeof this.personalisation.personality;
		const personalityConfig = this.personalisation.personality[personalityId] as IPersonalityContent;

		this.replacementValues = {
			PLAYER_NAME: this.licensee.givenName,
			PERSONALITY: 'The ' + this.selectedPersonalityGroup.personality.name,
			RESULT: personalityConfig.description,
			PERSONALITY_DETAIL_URL: personalityConfig.url,
			PERSONALITY_DETAIL_LINK: `<a href="${personalityConfig.url}">The ${this.selectedPersonalityGroup.personality.name} Brand Personality</a>`,
			QUIZ_HOME_URL: this.personalisation.settings.quizHomeUrl,
			CTA1_URL: this.personalisation.settings.cta1Url,
			CTA2_URL: this.personalisation.settings.cta2Url,
		};
	}

	public onMenuItemSelected(option: any) {
		this.selectedOption = option;
	}

	public getErrors() {
		if (this.personalisation == null) {
			return ['No personalisation information present. You may need to restart you browser and start again.'];
		}

		let errors: any[] = [];

		if (StringUtil.nullOrEmpty(this.personalisation.color.primary.backgroundColor)) {
			errors.push('No value was provided for background color');
		}
		if (StringUtil.nullOrEmpty(this.personalisation.color.primary.color)) {
			errors.push('No value was provided for color');
		}
		if (StringUtil.nullOrEmpty(this.personalisation.color.primary.linkColor)) {
			errors.push('No value was provided for link color');
		}
		if (StringUtil.nullOrEmpty(this.personalisation.color.primary.hoverBackgroundColor)) {
			errors.push('No value was provided for hover background color');
		}
		if (StringUtil.nullOrEmpty(this.personalisation.color.primary.hoverColor)) {
			errors.push('No value was provided for hover color');
		}

		if (StringUtil.nullOrEmpty(this.personalisation.color.accent.backgroundColor)) {
			errors.push('No value was provided for the accent background color');
		}
		if (StringUtil.nullOrEmpty(this.personalisation.color.accent.color)) {
			errors.push('No value was provided for the accent color');
		}
		if (StringUtil.nullOrEmpty(this.personalisation.color.accent.linkColor)) {
			errors.push('No value was provided for the accent link color');
		}
		if (StringUtil.nullOrEmpty(this.personalisation.color.accent.hoverBackgroundColor)) {
			errors.push('No value was provided for the accent hover background color');
		}
		if (StringUtil.nullOrEmpty(this.personalisation.color.accent.hoverColor)) {
			errors.push('No value was provided for the accent hover color');
		}

		if (StringUtil.nullOrEmpty(this.personalisation.settings.quizHomeUrl)) {
			errors.push('No value was provided for the quiz home url');
		}

		if (StringUtil.nullOrEmpty(this.personalisation.pages.payment.description)) {
			errors.push('No value was provided for the Payment page description');
		}
		if (
			StringUtil.nullOrEmpty(this.personalisation.pages.instructions.youtubeVideoId) &&
				StringUtil.nullOrEmpty(this.personalisation.pages.instructions.alternateDescription)
		) {
			errors.push('No value was provided for the Instructions YouTube video Id or description. You must provide a value for one of these fields');
		}
		if (StringUtil.nullOrEmpty(this.personalisation.pages.instructions.instructionText)) {
			errors.push('No value was provided for the Instructions page instruction text');
		}
		if (StringUtil.nullOrEmpty(this.personalisation.pages.instructions.playButtonText)) {
			errors.push('No value was provided for the Instructions page \'Play quiz\' button text');
		}
		if (StringUtil.nullOrEmpty(this.personalisation.pages.quizResults.body)) {
			errors.push('No value was provided for the quiz results second email content');
		}

		this.availablePersonalityControlGroups.forEach(p => {
			const propertyKey = p.personality.personalityId as keyof typeof this.personalisation.personality;
			const personalityContent = this.personalisation?.personality[propertyKey];
			if (personalityContent == null) {
				errors.push(`No personality content was found for the ${p.personality.name}`);
				p.hasErrors = true;
				return;
			}
			if (StringUtil.nullOrEmpty(personalityContent.description)) {
				errors.push(`No description content was provided for the ${p.personality.name}`);
				p.hasErrors = true;
			}
			if (StringUtil.nullOrEmpty(personalityContent.url)) {
				errors.push(`No url link was provided for the ${p.personality.name}`);
			}
		});

		if (StringUtil.nullOrEmpty(this.personalisation.pages.quizResults.body)) {
			errors.push('No value was provided for the quiz results body');
		}

		if (StringUtil.nullOrEmpty(this.personalisation.emails.result1Body)) {
			errors.push('No value was provided for the quiz results first email content');
		}

		return errors;
	}

	public async onSave() {
		this.errors.length = 0;

		if (!this.isPaid) {
			this.licenseeService.showPaidSubscriberWarning();
			return;
		}

		this.errors = this.getErrors();

		if (this.errors.length > 0) {
			return;
		}

		const p = this.personalisation as ILicenseePersonalisation;
		const response = await this.personalisationService.saveLicenseePersonalisation(p);
	}

	public getSvg(personality: IPersonality) {
		return this.sanitizer.bypassSecurityTrustHtml(personality.svg);
	}
}

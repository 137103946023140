import {
	Component,
	OnInit,
	Input,
} from '@angular/core';

@Component({
	selector: 'brand-magic-bar-chart',
	templateUrl: './bar-chart.template.html',
	styleUrls: ['./bar-chart.style.scss'],
})
export class BarChartComponent implements OnInit {

	public chartData: any = {
		labels: [],
		datasets: []
	};

	public chartOptions = {
		maintainAspectRatio: false,
        layout: {
            padding: 10,
        },
		scales: {
			y: {
				stacked: true,
				grid: {
					display: true,
					color: 'rgba(0,0,0,0.05)',
				}
			},
			x: {
				grid: {
					display: false
				}
			}
		},
        plugins: {
			legend: {
				labels: {
					font: {
						size: 16,
					},
				},
			},
        },
	};

	@Input()
	public datasets!: any[];

	@Input()
	public labels!: string[];

	@Input()
	public height!: number;

	constructor() {
	}

	ngOnInit() {
		this.chartData.labels = this.labels;
		this.chartData.datasets = this.datasets;
	}

}

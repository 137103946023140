<h1 mat-dialog-title>
	Generate coupons
</h1>

<mat-dialog-content>
	<brand-magic-error-display
		*ngIf="errors.length > 0"
		header="We could not add these coupons"
		[errors]="errors">
	</brand-magic-error-display>

	<div class="add-coupon-container">
		<mat-form-field appearance="fill" class="add-amount">
			<mat-label>Number of coupons to add</mat-label>
			<mat-select
				[(value)]="couponsToAdd">
				<mat-option [value]="1"> 1 </mat-option>
				<mat-option [value]="2"> 2 </mat-option>
				<mat-option [value]="3"> 3 </mat-option>
				<mat-option [value]="4"> 4 </mat-option>
				<mat-option [value]="5"> 5 </mat-option>
				<mat-option [value]="10"> 10 </mat-option>
				<mat-option [value]="20"> 20 </mat-option>
			</mat-select>
		</mat-form-field>

		<mat-checkbox
			[(ngModel)]="isUnlimited"
			[disabled]="isLimitedPlay">
			is Unlimited?
		</mat-checkbox>

		<mat-checkbox
			[(ngModel)]="isLimitedPlay"
			[disabled]="isUnlimited">
			is Limited?
		</mat-checkbox>

		<mat-form-field appearance="fill">
			<mat-label>Max limited coupons</mat-label>
			<input
				matInput
				[disabled]="!isLimitedPlay"
				[(ngModel)]="limitedPlayMax">
		</mat-form-field>

		<mat-form-field id="expiryDateSelection" appearance="fill">
			<mat-label>Coupon expiry date</mat-label>
			<input
				matInput
				[disabled]="!isUnlimited"
				[matDatepicker]="expiryDatePicker"
				[(ngModel)]="expiryDate">
			<mat-datepicker-toggle matIconSuffix [for]="expiryDatePicker"></mat-datepicker-toggle>
			<mat-datepicker	#expiryDatePicker>
			</mat-datepicker>
		</mat-form-field>
	</div>
</mat-dialog-content>

<mat-dialog-actions>
	<button
		mat-raised-button
		color="primary"
		(click)="onAdd()">
		<mat-icon>add</mat-icon>
		Generate coupons
	</button>

	<button
		mat-raised-button
		mat-dialog-close>
		<mat-icon>cancel</mat-icon>
		Cancel
	</button>
</mat-dialog-actions>

import { Component } from '@angular/core';
import {
	Router,
	ActivatedRoute,
	ParamMap,
} from '@angular/router';
import { StringUtil } from '@brand-magic/lib-util';
import { ILicensee } from '@brand-magic/lib-types';
import { AuthService } from '@brand-magic/lib-auth';

@Component({
	selector: 'brand-magic-update-password',
	templateUrl: './update-password.template.html',
	styleUrls: ['./update-password.style.scss'],
})
export class UpdatePasswordComponent {
	public errorMessage = 'We were not able to reset your password';
	public errors: string[] = [];

	private resetCode: string | null = null;

	public isWorking = true;
	public showSuccessMessage = false;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private authService: AuthService,
	) {
	}

	public ngOnInit() {
		let rt = this.route;
		if (this.route.children.length > 0) {
			rt = this.route.children[0];
		}

		rt.paramMap.subscribe(async (params: ParamMap) => {
			this.resetCode = params.get('resetCode');
			if (StringUtil.nullOrEmpty(this.resetCode)) {
				this.errors.push('We could not find a reset code for this request');
				this.isWorking = false;
				return;
			}

			const licenseeResponse = await this.authService.getLicenseeByResetCode(this.resetCode as string);
			if (!licenseeResponse.success) {
				this.errors.push('We were not able to find your reset code.');
				this.errors.push('This probably means that the code has expired, or maybe it is incorrect.');
				this.errors.push('Please try resetting your account again.');
				this.isWorking = false;
				return;
			}

			this.isWorking = false;
		});
	}

	public async onRequest(event: any) {
		this.errors.length = 0;
		const response = await this.authService.updatePasswordByCode(this.resetCode as string, event.password);
		if (!response.success) {
			this.errors.push('We were not able to update your password. The message we received is:');
			this.errors.push(response.helpMessage as string);
			this.errors.push('If you think this is an error, you might try resetting your account again');
		}

		this.showSuccessMessage = response.success;
	}

	public onFail(event: any) {
		this.errors.length = 0;
		for (let msg of event.helpMessages) {
			this.errors.push(msg);
		}
	}

	public onCancel() {
		this.errors.length = 0;
		this.router.navigateByUrl('/auth/login');
	}
}

<div mat-dialog-title>
	Terms and conditions
</div>
<mat-dialog-content>
	<div
		*ngIf="errors.length > 0"
		class="errors">
		<h1>We encountered some errors loading the terms and conditions</h1>

		<brand-magic-error-display
			[errors]="errors">
		</brand-magic-error-display>
	</div>

	<div
		class="loading"
		*ngIf="!isLoaded()">
		<mat-spinner></mat-spinner>
	</div>

	<ng-container
		*ngIf="isLoaded()">
		<div [innerHTML]="licence"></div>
	</ng-container>
</mat-dialog-content>
<mat-dialog-actions>
	<button
		mat-button
		mat-dialog-close>
		<mat-icon>close</mat-icon>
		Close
	</button>
</mat-dialog-actions>

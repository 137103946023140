import { Component } from '@angular/core';
import { DateTime } from 'luxon';
import { AdminService } from '../../services/admin.service';

@Component({
	selector: 'brand-magic-admin-commissions',
	templateUrl: './admin-commissions.template.html',
	styleUrls: ['./admin-commissions.style.scss'],
})
export class AdminCommissionsComponent {
	public errors: string[] = [];
	public isLoading = false;
	public stats: any;

	public monthAndYear = DateTime.now().toFormat('MMM yyyy');

	public licenseesThisMonth = 0;
	public licenseesThisMonthLabels: string[] = [];
	public licenseesThisMonthDatasets: any = null;

	public allLicensees = 0;
	public allLicenseesLabels: string[] = [];
	public allLicenseesDatasets: any = null;

	public leadsThisMonth = 0;
	public playsThisMonthLabels: string[] = [];
	public playsThisMonthDatasets: any = null;

	public allLeads = 0;
	public allPlaysLabels: string[] = [];
	public allPlaysDatasets: any = null;

	constructor(
		private adminService: AdminService,
	) {
		this.loadStats();
	}

	public async loadStats() {
		this.errors.length === 0;
		if (this.stats == null) {
			this.isLoading = true;
			const response = await this.adminService.getAdminStats();
			this.isLoading = false;
			if (!response.success) {
				this.errors.push(response.helpMessage as string);
				return;
			}
			this.stats = response.data;
		}

		this.initLicenseeData();
		this.initPlaysData();
	}

	private initLicenseeData() {
		this.licenseesThisMonth = this.stats.licensees.thisMonth.joined;
		this.licenseesThisMonthLabels = this.stats.licensees.thisMonth.data.map((d: any) => d.label);
		this.licenseesThisMonthDatasets = [{
			label: 'Licensees by day',
			data: this.stats.licensees.thisMonth.data,
			parsing: {
				xAxisKey: 'label',
				yAxisKey: 'joined',
			},
			borderWidth: 1.5,
			backgroundColor: 'rgba(126,87,194,0.2)',
			borderColor: 'rgba(126,87,194,0.6)',
			hoverBackgroundColor: 'rgba(126,87,194,0.6)',
			hoverBorderColor: 'rgba(126,87,194,1)',
		}];

		this.allLicensees = this.stats.licensees.all.joined;
		this.allLicenseesLabels = this.stats.licensees.all.data.map((d: any) => d.label);
		this.allLicenseesDatasets = [{
			label: 'Licensees by month',
			data: this.stats.licensees.all.data,
			parsing: {
				xAxisKey: 'label',
				yAxisKey: 'joined',
			},
			borderWidth: 1.5,
			backgroundColor: 'rgba(37,87,194,0.2)',
			borderColor: 'rgba(37,87,194,0.6)',
			hoverBackgroundColor: 'rgba(37,87,194,0.6)',
			hoverBorderColor: 'rgba(37,87,194,1)',
		}];
	}

	private initPlaysData() {
		this.leadsThisMonth = this.stats.leads.thisMonth.leads;
		this.playsThisMonthLabels = this.stats.leads.thisMonth.data.map((d: any) => d.label);
		this.playsThisMonthDatasets = [{
			label: 'Business plays',
			data: this.stats.leads.thisMonth.data,
			parsing: {
				xAxisKey: 'label',
				yAxisKey: 'businessPlays',
			},
			backgroundColor: 'rgba(126,87,194,0.2)',
			borderColor: 'rgba(126,87,194,0.6)',
			pointBackgroundColor: 'rgba(126,87,194,0.6)',
			pointBorderColor: 'rgba(126,87,194,1)',
			pointHoverBackgroundColor: 'rgba(126,87,194,0.8)',
			pointHoverBorderColor: 'rgba(126,87,194,1)',
			fill: 'origin',
		}, {
			label: 'Personal plays',
			data: this.stats.leads.thisMonth.data,
			parsing: {
				xAxisKey: 'label',
				yAxisKey: 'personalPlays',
			},
			backgroundColor: 'rgba(37,87,194,0.2)',
			borderColor: 'rgba(37,87,194,0.6)',
			pointBackgroundColor: 'rgba(37,87,194,0.6)',
			pointBorderColor: 'rgba(37,87,194,1)',
			pointHoverBackgroundColor: 'rgba(37,87,194,0.8)',
			pointHoverBorderColor: 'rgba(37,87,194,1)',
			fill: 'origin',
		}, {
			label: 'Unplayed',
			data: this.stats.leads.thisMonth.data,
			parsing: {
				xAxisKey: 'label',
				yAxisKey: 'playsRemaining',
			},
			backgroundColor: 'rgba(194,194,87,0.2)',
			borderColor: 'rgba(194,194,87,0.6)',
			pointBackgroundColor: 'rgba(194,194,87,0.6)',
			pointBorderColor: 'rgba(194,194,87,1)',
			pointHoverBackgroundColor: 'rgba(194,194,87,0.8)',
			pointHoverBorderColor: 'rgba(194,194,87,1)',
			fill: 'origin',
		}];

		this.allLeads = this.stats.leads.all.leads;
		this.allPlaysLabels = this.stats.leads.all.data.map((d: any) => d.label);
		this.allPlaysDatasets = [{
			label: 'Business plays',
			data: this.stats.leads.all.data,
			parsing: {
				xAxisKey: 'label',
				yAxisKey: 'businessPlays',
			},
			backgroundColor: 'rgba(126,87,194,0.2)',
			borderColor: 'rgba(126,87,194,0.6)',
			pointBackgroundColor: 'rgba(126,87,194,0.6)',
			pointBorderColor: 'rgba(126,87,194,1)',
			pointHoverBackgroundColor: 'rgba(126,87,194,0.8)',
			pointHoverBorderColor: 'rgba(126,87,194,1)',
			fill: 'origin',
		}, {
			label: 'Personal plays',
			data: this.stats.leads.all.data,
			parsing: {
				xAxisKey: 'label',
				yAxisKey: 'personalPlays',
			},
			backgroundColor: 'rgba(37,87,194,0.2)',
			borderColor: 'rgba(37,87,194,0.6)',
			pointBackgroundColor: 'rgba(37,87,194,0.6)',
			pointBorderColor: 'rgba(37,87,194,1)',
			pointHoverBackgroundColor: 'rgba(37,87,194,0.8)',
			pointHoverBorderColor: 'rgba(37,87,194,1)',
			fill: 'origin',
		}, {
			label: 'Unplayed',
			data: this.stats.leads.all.data,
			parsing: {
				xAxisKey: 'label',
				yAxisKey: 'playsRemaining',
			},
			backgroundColor: 'rgba(194,194,87,0.2)',
			borderColor: 'rgba(194,194,87,0.6)',
			pointBackgroundColor: 'rgba(194,194,87,0.6)',
			pointBorderColor: 'rgba(194,194,87,1)',
			pointHoverBackgroundColor: 'rgba(194,194,87,0.8)',
			pointHoverBorderColor: 'rgba(194,194,87,1)',
			fill: 'origin',
		}];
	}

	public hasStats() {
		return this.stats != null;
	}

	private easeOutCirc(x: number): number {
		return Math.sqrt(1 - Math.pow(x - 1, 2));
	}

}

import {
	Component,
	OnInit,
	Input,
	Output,
	EventEmitter,
} from '@angular/core';
import {
	MatDialog,
} from '@angular/material/dialog';
import {
	FormControl,
	Validators,
} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

import {
	ILicensee,
	ICountry,
} from '@brand-magic/lib-types';
import {
	StringUtil,
	TypeUtil,
} from '@brand-magic/lib-util';

import {
	AddressService
} from '../../services/address.service';
import {
	TermsComponent
} from '../terms/terms.component';

@Component({
	selector: 'brand-magic-licensee-edit',
	templateUrl: './licensee-edit.template.html',
	styleUrls: ['./licensee-edit.style.scss'],
})
export class LicenseeEditComponent implements OnInit {
	public givenName!: FormControl;
	public familyName!: FormControl;
	public phone!: FormControl;
	public mobile!: FormControl;

	public companyName!: FormControl;
	public abn!: FormControl;
	public email!: FormControl;
	public website!: FormControl;
	public agreedToTerms!: FormControl;

	public street1!: FormControl;
	public street2!: FormControl;
	public city!: FormControl;
	public state!: FormControl;
	public postcode!: FormControl;
	public countryCode!: FormControl;

	public password!: FormControl;
	public passwordConfirm!: FormControl;

	private allControls: any[] = [];

	public errorHeader = 'We found some errors with the information you provided';
	public errorDescription = [
		'You did not provide values for some required fields.',
		'Please double-check the form and try again.',
	];
	public errors: string[] = [];

	public passwordVal = '';
	public passwordConfirmVal = '';

	public filteredCountries!: Observable<ICountry[]>;

	@Input()
	public licensee!: ILicensee;

	@Output()
	public save = new EventEmitter<{licensee: ILicensee, password: string | null}>()

	constructor(
		private addressService: AddressService,
		public dialog: MatDialog,
	) {
	}

	public isPasswordRequired() {
		return StringUtil.nullOrEmpty(this.licensee.password);
	}

	public ngOnInit() {
		if (this.licensee == null) {
			return;
		}

		this.givenName = new FormControl(this.licensee.givenName, Validators.required);
		this.familyName = new FormControl(this.licensee.familyName, Validators.required);
		this.password = new FormControl(
			this.passwordVal,
			this.isPasswordRequired() ? Validators.required: Validators.nullValidator
		);
		this.passwordConfirm = new FormControl(
			this.passwordConfirmVal,
			this.isPasswordRequired() ? Validators.required: Validators.nullValidator
		);
		this.phone = new FormControl(this.licensee.phone, Validators.required);
		this.mobile = new FormControl(this.licensee.mobile, Validators.required);
		this.companyName = new FormControl(this.licensee.companyName, Validators.required);
		this.email = new FormControl(this.licensee.email, Validators.required);
		this.website = new FormControl(this.licensee.website, Validators.required);
		this.abn = new FormControl(this.licensee.abn);
		this.agreedToTerms = new FormControl(this.licensee.agreedToTerms, Validators.required);
		this.street1 = new FormControl(this.licensee.address.address1, Validators.required);
		this.street2 = new FormControl(this.licensee.address.address2);
		this.city = new FormControl(this.licensee.address.city, Validators.required);
		this.state = new FormControl(this.licensee.address.state, Validators.required);
		this.postcode = new FormControl(this.licensee.address.postcode, Validators.required);

		const selectedCountry = this.addressService.getCountryByCode(this.licensee.address.countryCode);
		this.countryCode = new FormControl(selectedCountry, Validators.required);

		this.allControls = [{
			name: 'Given name',
			ctrl: this.givenName,
		}, {
			name: 'Family name',
			ctrl: this.familyName,
		}, {
			name: 'Password',
			ctrl: this.password,
		}, {
			name: 'Password Confirm',
			ctrl: this.passwordConfirm,
		}, {
			name: 'Email',
			ctrl: this.email,
		}, {
			name: 'Phone',
			ctrl: this.phone,
		}, {
			name: 'Company name',
			ctrl: this.companyName,
		}, {
			name: 'ABN',
			ctrl: this.abn,
		}, {
			name: 'Website',
			ctrl: this.website,
		}, {
			name: 'Email',
			ctrl: this.email,
		}, {
			name: 'Agreed to terms',
			ctrl: this.agreedToTerms,
		}, {
			name: 'Address 1',
			ctrl: this.street1,
		}, {
			name: 'Address 2',
			ctrl: this.street2,
		}, {
			name: 'Suburb',
			ctrl: this.city,
		}, {
			name: 'State',
			ctrl: this.state,
		}, {
			name: 'Postcode',
			ctrl: this.postcode,
		}, {
			name: 'Country code',
			ctrl: this.countryCode,
		}];

		// Create observable for country auto-complete
		this.filteredCountries = this.countryCode.valueChanges.pipe(
			startWith(''),
			map(value => {
				const name = typeof value === 'string' ? value : value?.name;
				return name ? this.filterCountries(name as string) : this.addressService.countries.slice();
			}),
		);

	}

	public getName(country: ICountry): string {
		return country && country.name ? country.name : '';
	}

	private filterCountries(name: string): ICountry[] {
		// First match by exact country code
		if (name.length === 2 && name === name.toUpperCase()) {
			const exact = this.addressService.countries.find(c => c.code === name);
			if (exact != null) {
				return [exact];
			}
		}

		const filterValue = name.toLowerCase();
		return this.addressService.countries.filter(c => c.name.toLowerCase().includes(filterValue));
	}


	public hasChangedValues() {
		for (let i = 0; i < this.allControls.length; i++) {
			const control = this.allControls[i].ctrl;
			if (control == null) {
				console.log('cntrl ' + i + ' is null');
				continue;
			}
			if (control.dirty) {
				return true;
			}
		}
		return false;
	}

	public getErrors() {
		const errors: any[] = [];
		this.allControls.forEach((namedControl) => {
			if (namedControl.ctrl.errors != null) {
				errors.push(namedControl.name + ' is required');
			}
		});

		if (this.isPasswordRequired()) {
			const pw = this.password.getRawValue();
			const pwConfirm = this.passwordConfirm.getRawValue();
			if (StringUtil.nullOrEmpty(pw)) {
				errors.push('Password is required');
			} else if (StringUtil.nullOrEmpty(pwConfirm)) {
				errors.push('Password confirmation is required');
			} else if (pw != pwConfirm) {
				errors.push('Password and Password confimation do not match');
			}
		}

		return errors;
	}

	public async onSave() {
		this.errors = this.getErrors();

		if (this.errors.length > 0) {
			return;
		}

		const rawCountry = this.countryCode.getRawValue();
		let selectedCountry = rawCountry;
		if (rawCountry?.code != null) {
			selectedCountry = rawCountry.code;
		}
		if (selectedCountry.length != 2) {
			const c = this.addressService.getCountryByName(selectedCountry);
			selectedCountry = c?.code ?? '';
			if (StringUtil.nullOrEmpty(selectedCountry)) {
				this.errors.push(`Please select a valid country (saw: '${JSON.stringify(rawCountry, null, '\t')}')`);
				return;
			}
		}

		const licenseeUpdate = {
			givenName: this.givenName.getRawValue(),
			familyName: this.familyName.getRawValue(),
			phone: this.phone.getRawValue(),
			mobile: this.mobile.getRawValue(),
			companyName: this.companyName.getRawValue(),
			email: this.email.getRawValue(),
			website: this.website.getRawValue(),
			abn: this.abn.getRawValue(),
			agreedToTerms: this.agreedToTerms.getRawValue(),
			address: {
				address1: this.street1.getRawValue(),
				address2: this.street2.getRawValue(),
				city: this.city.getRawValue(),
				state: this.state.getRawValue(),
				postcode: this.postcode.getRawValue(),
				countryCode: selectedCountry
			}
		};

		const licensee = JSON.parse(JSON.stringify(this.licensee));
		const updated = Object.assign(licensee, licenseeUpdate);

		const password = this.isPasswordRequired() ? this.password.getRawValue() : null;

		const updObj = {
			licensee: updated,
			password: password
		};

		this.save.emit(updObj);
	}


	public onViewTerms() {
		this.dialog.open(TermsComponent, {
			width: '600px',
			height: '800px'
		});
	}
}

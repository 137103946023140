import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class MenuService {
	public isOpenned = true;

	constructor() {
	}

	public toggle() {
		this.isOpenned = !this.isOpenned;
	}
}

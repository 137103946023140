import {
	Component,
	OnInit,
	Input,
	ViewChild,
	AfterViewInit,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {
	MatSelectionList,
	MatSelectionListChange,
} from '@angular/material/list';
import { BaseChartDirective } from 'ng2-charts';
import { DateTime } from 'luxon';
import { PersonalityService } from '../../services/personality.service';
import type {
	IPersonality,
	IAnnotatedQuizResults,
	IAnswer,
} from '@brand-magic/lib-types';

@Component({
	selector: 'brand-magic-quiz-result-display',
	templateUrl: './quiz-result-display.template.html',
	styleUrls: ['./quiz-result-display.style.scss'],
})
export class QuizResultDisplayComponent implements OnInit, AfterViewInit {

	public primaryPersonality: IPersonality | null = null;
	public secondaryPersonality: IPersonality | null = null;

	@Input()
	public quizResult: IAnnotatedQuizResults | null = null;

	@ViewChild(MatSelectionList)
	public selectionList!: MatSelectionList;

	@ViewChild(BaseChartDirective)
	public chart!: BaseChartDirective;

	public selectedOptions!: IAnswer[];

	private personalities: IPersonality[] = [];

	public data: any = {
		labels: [],
		datasets: [],
	};
	public options = {
		maintainAspectRatio: true,
        layout: {
            padding: 10,
        },
		scales: {
			y: {
				stacked: false,
				grid: {
					display: true,
					color: 'rgba(0,0,0,0.05)',
				}
			},
			x: {
				grid: {
					display: false
				}
			}
		},
        plugins: {
			legend: {
				labels: {
					font: {
						size: 16,
					},
				},
			},
        },
	};


	constructor(
		private sanitizer: DomSanitizer,
		private personalityService: PersonalityService,
	) {
	}

	public ngOnInit() {
		this.loadPersonalityCache();
		this.initGraph();
	}

	public ngAfterViewInit() {
		setTimeout(() => {
			this.selectionList.selectAll();
			this.drawGraph();
		}, 0);
	}

	public onSelectionChanged(listChanged: MatSelectionListChange) {
		this.drawGraph()
	}


	public onSelectAll() {
		this.selectionList.selectAll();
		this.drawGraph();
	}

	public onSelectNone() {
		this.selectionList.deselectAll();
		this.drawGraph();
	}

	// Text
	public getHeaderText() {
		if (this.quizResult == null) {
			return '';
		}
		const quizType = this.quizResult.quizType[0].toUpperCase() + this.quizResult.quizType.substr(1).toLowerCase();
		const quizDate = this.quizResult.datePlayed.toLocaleString(DateTime.DATETIME_SHORT);
		return quizType + ' quiz played on ' + quizDate;
	}

	public getDatePlayed() {
		if (this.quizResult == null) {
			return '';
		}
		return this.quizResult.datePlayed.toLocaleString(DateTime.DATETIME_SHORT);
	}




	// Preload personalities referenced in this component
	private loadPersonalityCache() {
		if (this.quizResult == null) {
			return;
		}
		const id1 = this.quizResult.primaryPersonalityId;
		const id2 = this.quizResult.secondaryPersonalityId;

		if (id1 != null && id1.length > 0) {
			this.primaryPersonality = this.personalityService.getPersonalityById(id1);
		}
		if (id2 != null && id2.length > 0) {
			this.secondaryPersonality = this.personalityService.getPersonalityById(id2);
		}

		for (let ans of this.quizResult.answers) {
			for (let weighting of ans.weightings) {
				const p = this.personalities.find(p => p.personalityId === weighting.personalityId);
				if (p != null) {
					continue;
				}

				const personality = this.personalityService.getPersonalityById(weighting.personalityId);
				if (personality == null) {
					continue;
				}
				this.personalities.push(personality);
			}
		}

		this.personalities.sort((p1, p2) => {
			return p1.name.localeCompare(p2.name);
		});
	}


	// Graph methods
	private initGraph() {
		this.data.labels = this.personalities.map(p => p.name);

		// set up the graph dataset
		// const palette = this.getColourPalette(this.data.labels.length);
		const palette = this.getHeroPalette();

		const dataset: any = {
			label: 'Personalities indicated by quiz',
			data: null,
			parsing: {
				xAxisKey: 'personality',
				yAxisKey: 'val',
			},
			borderWidth: 1.5,
		};
		Object.assign(dataset, palette);

		this.data.datasets.push(dataset);
	}

	private drawGraph() {
		if (this.quizResult == null || this.selectedOptions == null) {
			return;
		}
		const data = Array(this.data.labels.length).fill(0);

		for (let answer of this.selectedOptions) {
			for (const weighting of answer.weightings) {
				const idx = this.personalities.findIndex(p => p.personalityId === weighting.personalityId);
				if (idx >= 0) {
					data[idx] += weighting.weight;
				}
			}
		}

		this.data.datasets[0].data = data;
		this.chart?.update();
	}


	// Generate a variable number of 'well-distanced' colours
	private getHeroPalette() {
		return {
			backgroundColor: this.personalities.map(p => {
				return `hsla(${p.colour.h}, ${p.colour.s}%, ${p.colour.l}%, 0.5)`;
			}),
			borderColor: this.personalities.map(p => {
				return `hsla(${p.colour.h}, ${p.colour.s}%, ${p.colour.l}%, 0.9)`;
			}),
			hoverBackgroundColor: this.personalities.map(p => {
				return `hsla(${p.colour.h}, ${p.colour.s}%, ${p.colour.l}%, 0.8)`;
			}),
			hoverBorderColor: this.personalities.map(p => {
				return `hsla(${p.colour.h}, ${p.colour.s}%, ${p.colour.l}%, 1)`;
			}),
		}
	}

	private getColourPalette(colourCount: number) {
		// Hsla colour space
		let h = this.randomInt(0, 360);
		const s = this.randomInt(70, 100);
		const l = this.randomInt(5, 30);

		let palette: any = {
			backgroundColor: [],
			borderColor: [],
			hoverBackgroundColor: [],
			hoverBorderColor: [],
		};
		for (let i = 0; i < colourCount; i++) {
			h = (h + 222.5) % 360;

			palette.backgroundColor.push(`hsla(${h}, ${s}%, ${l}%, 0.5)`);
			palette.borderColor.push(`hsla(${h}, ${s}%, ${l}%, 0.8)`);
			palette.hoverBackgroundColor.push(`hsla(${h}, ${s}%, ${l}%, 0.8)`);
			palette.hoverBorderColor.push(`hsla(${h}, ${s}%, ${l}%, 1)`);
		}

		return palette;
	}

	private randomInt(min: number, max: number) {
		const distance = max - min;
		const v = Math.round(Math.random() * distance);
		return min + v;
	}


	// Helper methods to get selected hero name / svg
	public hasPrimary() {
		return this.primaryPersonality != null;
	}

	public getPrimaryName() {
		return this.primaryPersonality?.name ?? '';
	}

	public getPrimarySvg() {
		if (this.primaryPersonality == null) {
			return '';
		}
		return this.sanitizer.bypassSecurityTrustHtml(this.primaryPersonality.svg);
	}

	public hasSecondary() {
		return this.secondaryPersonality != null;
	}

	public getSecondaryName() {
		return this.secondaryPersonality?.name ?? '';
	}

	public getSecondarySvg() {
		if (this.secondaryPersonality == null) {
			return '';
		}
		return this.sanitizer.bypassSecurityTrustHtml(this.secondaryPersonality.svg);
	}

}

import { Component } from '@angular/core';
import { MatDatepicker, } from '@angular/material/datepicker';
import { MatDialogRef, } from '@angular/material/dialog';
import {
	UntypedFormControl,
	Validators,
} from '@angular/forms';
import { DateTime } from 'luxon';
import {
	StringUtil
} from '@brand-magic/lib-util';

@Component({
	selector: 'brand-magic-admin-add-coupon',
	templateUrl: './admin-add-coupon.template.html',
	styleUrls: ['./admin-add-coupon.style.scss'],
})
export class AdminAddCouponDialog {
	public errors: string[] = [];

	public couponsToAdd = 1;
	public isUnlimited = false;
	public isLimitedPlay = false;
	public limitedPlayMax = null;
	public expiryDate: null | string = null;

	constructor(
		public dialogRef: MatDialogRef<AdminAddCouponDialog>,
	) {
	}

	public onAdd() {
		this.errors.length = 0;

		let dt = null;
		if (this.isUnlimited) {
			if (StringUtil.nullOrEmpty(this.expiryDate)) {
				this.errors.push('Please select an expiry date for the coupon');
				return;
			}
			try {
				dt = new Date(this.expiryDate as string);
			} catch (exception) {
				this.errors.push('The expiry date does not appear to be well-formed. Please double-check that itś a valid date');
				return;
			}
		}
		const returnValue = {
			couponsToAdd: this.couponsToAdd,
			isUnlimited: this.isUnlimited,
			expiryDate: dt,
			isLimitedPlay: this.isLimitedPlay,
			limitedPlayMax: this.limitedPlayMax,
		};
		this.dialogRef.close(returnValue);
	}
}

<div class="container">
	<brand-magic-error-display
		[header]="header"
		[additionalInformation]="errorDescription"
		[errors]="errors">
	</brand-magic-error-display>

	<mat-card
		*ngIf="quiz != null">
		<mat-card-content>
			<mat-tab-group animationDuration="0ms">
				<mat-tab
					*ngFor="let question of quiz.questions; index as questionIndex"
					[label]="(questionIndex + 1).toString()">
					<div class="quiz-edit-container">

						<h2>Question {{ (questionIndex + 1).toString() }}</h2>

						<mat-form-field appearance="fill">
							<mat-label>Question</mat-label>
							<input
								matInput
								[(ngModel)]="question.question">
						</mat-form-field>

						<mat-form-field appearance="fill">
							<mat-label>Hint</mat-label>
							<input
								matInput
								[(ngModel)]="question.hint">
						</mat-form-field>

						<h3>Answers</h3>

						<mat-accordion
							*ngIf="question != null">
							<mat-expansion-panel
								*ngFor="let answer of question.options; index as optionIndex">
								<mat-expansion-panel-header>
									<mat-panel-title>
										{{ (optionIndex + 1).toString() }}) {{ answer.answer }}
									</mat-panel-title>
									<mat-panel-description>
										{{ getAnswerDescription(answer) }}
									</mat-panel-description>
								</mat-expansion-panel-header>

								<mat-form-field class="quiz-form" appearance="fill">
									<mat-label>Answer</mat-label>
									<input
										matInput
										[(ngModel)]="answer.answer">
								</mat-form-field>

								<div class="weightings-container">
									<div
										*ngFor="let weighting of getWeightings(answer)"
										class="weighting">
										<span
											class="img"
											[innerHTML]="weighting.icon">
										</span>

										<label class="weighting-name">
											{{ weighting.name }}
										</label>

										<span
											class="amount">
											<mat-slider
												[max]="1"
												[min]="0"
												[step]="0.1"
												[thumbLabel]="true"
												[(ngModel)]="weighting.weight"
												aria-labelledby="weighting-name">
											</mat-slider>
										</span>
									</div>
								</div>
							</mat-expansion-panel>
						</mat-accordion>
					</div>
				</mat-tab>
			</mat-tab-group>
		</mat-card-content>
		<mat-card-actions>
			<div class="action-buttons">
				<button
					mat-raised-button
					(click)="onSave()"
					color="primary">
					Save changes
				</button>

				<mat-checkbox
					[(ngModel)]="doShuffle">Shuffle the answers before saving
				</mat-checkbox>
			</div>
		</mat-card-actions>
	</mat-card>

</div>

import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import {
	HttpService,
} from '@brand-magic/lib-http';
import {
	IHttpResponse,
} from '@brand-magic/lib-types';
import {
	AuthService,
} from '@brand-magic/lib-auth';

@Injectable({
	providedIn: 'root'
})
export class StripeService {

	constructor(
		private httpService: HttpService,
		private authService: AuthService
	) {
	}

	private getHelpStruct(msg: string): any {
		return {
			success: false,
			helpMessage: msg,
			data: null,
		};
	}

	public async createConnectedAccount(): Promise<IHttpResponse<any>> {
		const request = {
			returnUrl: environment.stripeReturnUrl,
			refreshUrl: environment.stripeRefreshUrl
		};

		const response = await this.httpService
			.post<any, null>('/api/stripe-create-connected-account', request);

		if (!response.success) {
			console.log('Unable to create a connected account: ' + response.helpMessage as string);
		} else {
			// ensure our JWT token has up-to-date login data
			const authResponse = await this.authService.refreshAuthToken();
			if (!authResponse.success) {
				console.log('Unable to update auth token: ' + authResponse.helpMessage as string);
			}
		}

		return response;
	}

	public async createAccountLink(
		stripeAccountId: string,
	): Promise<IHttpResponse<any>> {
		const request = {
			accountId: stripeAccountId,
			returnUrl: environment.stripeReturnUrl,
			refreshUrl: environment.stripeRefreshUrl
		};

		const response = await this.httpService
			.post<any, null>('/api/stripe-create-account-link', request);

		if (!response.success) {
			console.log('Unable to create an account link: ' + response.helpMessage as string);
		}

		return response;
	}

	public async navigateToAccountLink(
		stripeAccountId: string
	): Promise<IHttpResponse<any>> {
		const response = await this.createAccountLink(stripeAccountId);

		if (!response.success) {
			console.error(response.helpMessage);
			return response;
		}

		document.location.href = response.data.url;

		return response;
	}


	public async loadAccount(
		stripeAccountId: string,
	): Promise<IHttpResponse<any>> {
		const request = {
			accountId: stripeAccountId,
		};

		const response = await this.httpService
			.post<any, null>('/api/stripe-load-account', request);

		if (!response.success) {
			console.log('We were unable to load this account information: ' + response.helpMessage as string);
		}

		return response;
	}

	public async createCouponPaymentIntent(): Promise<IHttpResponse<string>> {
		const request = {};

		const response = await this.httpService
			.post<any, string>('/api/stripe-purchase-coupons', request);

		if (!response.success) {
			console.log('Unable to create a purchase intent: ' + response.helpMessage);
		}

		return response;
	}
}

import { Component } from '@angular/core';
import { ILicensee } from '@brand-magic/lib-types';
import { LicenseeService } from '../../services/licensee.service';
import { StripeService } from '../../services/stripe.service';


@Component({
	selector: 'brand-magic-account-created',
	templateUrl: './account-created.template.html',
	styleUrls: ['./account-created.style.scss'],
})
export class AccountCreatedComponent {
	public errorMessage = 'We were not able to connect with Stripe.';
	public errors: string[] = [];

	private userName: string | null = null;

	constructor(
		private licenseeService: LicenseeService,
		private stripeService: StripeService,
	) {
	}

	private async loadName() {
		const licenseeResponse = await this.licenseeService.getCurrentLicensee();
		if (!licenseeResponse.success) {
			this.errors.push('We could not load your details at this time: ' + licenseeResponse.helpMessage);
		}

	}

	public getName() {
		return this.userName;
	}

	public async onCreateAccountLink() {
		this.errors.length = 0;

		const licenseeResponse = await this.licenseeService.getCurrentLicensee();
		if (!licenseeResponse.success) {
			this.errors.push('We could not load your details at this time: ' + licenseeResponse.helpMessage);
			return;
		}

		const licensee = licenseeResponse.data as ILicensee;

		await this.stripeService.navigateToAccountLink(
			licensee.stripe.accountId,
		);

		// If we didn't redirect, there must have been an error
		this.errors.push('We were unable to redirect you to stripe at this time. Please try again later.');
	}
}

<div class="url">
	<mat-form-field class="example-full-width" appearance="fill">
		<mat-label>
			Personality detail URL
		</mat-label>
		<input
			matInput
			[(ngModel)]="personalisation.personality[personalityKey].url">
	</mat-form-field>
</div>

<div class="">
	<mat-form-field class="editor" class="editor">
		<mat-label>{{ personality.name }} content</mat-label>
		<textarea
			[(ngModel)]="personalisation.personality[personalityKey].description"
			[froalaEditor]="htmlEditorOptions"
			matInput>{{ personalisation.personality[personalityKey].description }}</textarea>
		<mat-error>{{ personality.name }} content is <strong>required</strong></mat-error>
	</mat-form-field>
</div>

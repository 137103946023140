<div class="container">
	<ng-container
		*ngIf="lead == null">
		<h1>Loading lead details</h1>
	</ng-container>

	<ng-container
		*ngIf="lead != null">
		<h2
			*ngIf="!isPaid">
			This feature is only available for Magician and Wizard subscribers.
		</h2>

		<h3
			*ngIf="!isPaid">
			Please consider upgrading your subscription.
		</h3>

		<p
			class="paid-only"
			*ngIf="!isPaid">
			<a
				mat-raised-button
				routerLink="/pages/subscription">
				Upgrade your subscription
			</a>
		</p>

		<div [ngClass]="{'unavailable': !isPaid, 'lead-container': true }">

			<div
				class="lead"
				*ngIf="lead != null">
				<h1>Lead detail</h1>

				<mat-card>
					<mat-card-content>
						<div class="lead-detail">
							<div class="lead-grid">
								<div class="property">Given name:</div>
								<div class="value">{{ lead.givenName }}</div>

								<div class="property">Business name:</div>
								<div class="value">{{ lead.businessName }}</div>

								<div class="property">Email:</div>
								<div class="value">{{ lead.email }}</div>

								<div class="property">Phone:</div>
								<div class="value">{{ lead.phone }}</div>

								<div class="property">Country:</div>
								<div class="value">{{ lead.address.countryCode }}</div>

								<div class="property">Interest:</div>
								<div class="value">{{ lead?.interest ?? '' }}</div>
							</div>
						</div>
					</mat-card-content>
				</mat-card>
			</div>

			<ng-container
				*ngIf="quizResults === null && !quizLoaded">
				<h2>Loading the test results . . .</h2>
			</ng-container>
			<ng-container
				*ngIf="quizResults === null && quizLoaded">
				<h2>There was an error loading the test results</h2>

				<p>
					We were not able to load the test results.
				</p>
				<p>
					If the problem persists, please contact an administrator.
				</p>
			</ng-container>
			<ng-container
				*ngIf="quizResults != null && quizResults.length === 0">
				<h2>User has not played the test yet</h2>
			</ng-container>
			<ng-container
				*ngIf="quizResults != null && quizResults.length > 0">
				<div
					class="quiz-result"
					*ngFor="let result of quizResults">
					<mat-divider></mat-divider>
					<mat-card>
						<mat-card-content>
							<brand-magic-quiz-result-display
								[quizResult]="result">
							</brand-magic-quiz-result-display>
						</mat-card-content>
					</mat-card>
				</div>
			</ng-container>
		</div>
	</ng-container>
</div>

<div class="contaienr">
	<mat-form-field class="password" appearance="fill">
		<mat-label>Password</mat-label>
		<input
			matInput
			type="password"
			[formControl]="passwordControl">
		<mat-hint align="start">
			Passwords must be at least 8 characters long,
			have lowercase and uppercase characters,
			punctuation marks and numbers.
		</mat-hint>
		<mat-error *ngIf="passwordControl.hasError('required')">
			Password is <strong>required</strong>
		</mat-error>
	</mat-form-field>

	<mat-form-field class="password" appearance="fill">
		<mat-label>Password confirmation</mat-label>
		<input
			matInput
			type="password"
			[formControl]="passwordConfirmControl">
		<mat-hint align="start">
			Please retype your password to ensure you have not made
			any mistakes
		</mat-hint>
		<mat-error *ngIf="passwordConfirmControl.hasError('required')">
			Password confirmation is <strong>required</strong>
		</mat-error>
	</mat-form-field>

	<div class="action-buttons">
		<button
			mat-raised-button
			color="primary"
			(click)="onSubmit()">
			<mat-icon>done</mat-icon>
			Update password
		</button>

		<button
			mat-raised-button
			(click)="onCancel()">
			<mat-icon>arrow_back</mat-icon>
			Return to login
		</button>
	</div>
</div>

<div class="container">
	<h1>Account created</h1>

	<brand-magic-error-display
		[header]="errorMessage"
		[errors]="errors">
	</brand-magic-error-display>

	<p>
		We have successfully created your account. We're almost done.
	</p>

	<h2>Complete the process in Stripe</h2>

	<p>
		We use Stripe to seemlessly pay you commissions. It's pretty awesome.
		You will need to add your banking details there so that we can pay commissions.
	</p>

	<div class="action-buttons">
		<button
			mat-stroked-button
			color="primary"
			(click)="onCreateAccountLink()">
			<mat-icon>open_in_browser</mat-icon>
			Add banking details
		</button>
	</div>

</div>

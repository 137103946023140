import {
	Component,
	ViewEncapsulation,
} from '@angular/core';
import {
	Router,
} from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';

import {
	StringUtil,
} from '@brand-magic/lib-util';
import {
	IPersonality,
	ILicensee,
	ISubscriptionLevel,
	IHttpResponse,
} from '@brand-magic/lib-types';
import { PersonalityService } from '../../services/personality.service';
import { LicenseeService } from '../../services/licensee.service';
import { StripeService } from '../../services/stripe.service';

@Component({
	selector: 'brand-magic-embedding-codes',
	templateUrl: './embedding-codes.template.html',
	styleUrls: ['./embedding-codes.style.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class EmbeddingCodesComponent {
	public errors: string[] = [];

	public availableOptions = [{
		code: 'quiz-codes',
		text: 'Test codes'
	}, {
		code: 'personality-codes',
		text: 'Personality codes'
	}];

	public selectedOption = this.availableOptions[0];
	public selectedPersonality: IPersonality | null = null;

/*
	This is the code that is squashed to make the embedding code.
	You should maintain this code to make the embedding code maintainable.
	I use an online minifier, eg https://skalman.github.io/UglifyJS-online/

	<iframe id="bmQuiz" src="" style="width: 100%; height: 800px; border: none;"></iframe>
	<script type="text/javascript">
		let d = document;                 // d == document (shorthand)
		let u = (new URL(d.location));
		let sp = u.searchParams;          // sp - search parameters in embedding page. We use this to check if this a postback?
		let ls = d.localStorage;          // ls == localstorage (shorthand)
		let cr = crypto, c;

		c = sp.get('leadId');             // try to get leadId. First from query parameters
		if (c != null && ls != null) {
			try {
				ls.setItem('c', c);
			} catch(ex) {}
		}
		if (c == null && ls != null) {   // try getting from local storage
			try {
				c = ls.getItem('c');     // c is client-id. Cookies/localstorage is not allowed in iframe,
			} catch (ex) {}
		}
		if (c == null) {                 // We will create one so that we can follow it
			if (cr.randomUUID) {
				c = cr.randomUUID().replace(new RegExp(/-/, 'g'), '');
			} else {
				const arr = new Uint32Array(4);
				cr.getRandomValues(arr);
				c = arr.join('');
			}
			c = c.slice(0, 24);
			if (ls != null) {
				try {
					ls.setItem('c', c);
				} catch(ex) {}
			}
		}
		let b = '';                       // contents of b will be replaced with the quiz url for the licensee (with licensee code);
		let fp = new URLSearchParams();   // fp - frame parameters
		fp.set('c', c);
		if (sp.get('pg') === 'instructions') {
			b = b.replace('/quiz/', '/instructions/');
			for (let p of ['quizType', 'leadId', 'payment_intent']) {
				fp.set(p, sp.get(p));
			}
		} else {
			fp.set('h', u.origin + u.pathname);   // h is the host url. The iframe will use this for stripe post-backs
		}

		let el = d.getElementById('bmQuiz');
		el.setAttribute('src', `${b}?${fp.toString()}`);
	</script>
*/

	private quizStart = '<iframe id="bmQuiz" src="" style="width:100%;height:800px;border:none;"></iframe><script type="text/javascript">' +
	'let c,d=document,u=new URL(d.location),sp=u.searchParams,ls=d.localStorage,cr=crypto;if(null!=(c=sp.get("leadId"))&&null!=ls)' +
		'try{ls.setItem("c",c)}catch(e){}if(null==c&&null!=ls)try{c=ls.getItem("c")}catch(e){}if(null==c){if(cr.randomUUID)c=cr.randomUUID().replace(new RegExp(/-/,"g"),"");' +
		'else{const e=new Uint32Array(4);cr.getRandomValues(e),c=e.join("")}if(c=c.slice(0,24),null!=ls)try{ls.setItem("c",c)}catch(e){}}let b="';

	private quizEnd = '",fp=new URLSearchParams;if(fp.set("c",c),"instructions"===sp.get("pg")){b=b.replace("/quiz/","/instructions/");' +
		'for(let e of["quizType","leadId","payment_intent"])fp.set(e,sp.get(e))}else fp.set("h",u.origin+u.pathname);let el=d.getElementById("bmQuiz");el.setAttribute("src",`${b}?${fp.toString()}`);</script>';

	private personalityStart = `<iframe style="border:none;width:100%;height:800px;" src="${environment.quizBaseUrl}`;
	private personalityEnd = '"></iframe>';

	public showEmbeddingCode = false;
	public availablePersonalities: IPersonality[] = []

	private cachedQuizCode: any = null;
	private cachedPersonalityCode: any = null;

	constructor(
		private sanitizer: DomSanitizer,
		private router: Router,
		private personalityService: PersonalityService,
		private licenseeService: LicenseeService,
		private stripeService: StripeService,
	) {
		this.availablePersonalities = this.personalityService.getPersonalities();
		if (this.availableOptions.length > 0) {
			this.selectedPersonality = this.availablePersonalities[0];
		}
		const promises = [
			this.licenseeService.getCurrentSubscriptionLevel(),
			this.licenseeService.arePayoutsAvailable()
		];
		Promise.all(promises).then((responses: any[]) => {
			let isPaidSubscription = false;
			if (responses[0].success) {
				const subscriptionLevel = responses[0].data as ISubscriptionLevel;
				isPaidSubscription = !subscriptionLevel.isFree;
			}

			const hasStripeAccount = responses[1];

			this.showEmbeddingCode = true;
			if (isPaidSubscription && !hasStripeAccount) {
				this.showEmbeddingCode = false;
			}
		});
	}

	public async onCreateAccountLink() {
		this.errors.length = 0;

		const licenseeResponse = await this.licenseeService.getCurrentLicensee();
		if (!licenseeResponse.success) {
			this.errors.push('We could not load your details at this time: ' + licenseeResponse.helpMessage);
			return;
		}

		const licensee = licenseeResponse.data as ILicensee;

		if (StringUtil.nullOrEmpty(licensee?.stripe?.accountId)) {
			this.router.navigateByUrl('/pages/subscription');
			return;
		}

		await this.stripeService.navigateToAccountLink(
			licensee.stripe.accountId,
		);

		// If we didn't redirect, there must have been an error
		this.errors.push('We were unable to redirect you to stripe at this time. Please try again later.');
	}

	public onMenuItemSelected(option: any) {
		this.selectedOption = option;
	}

	public onPersonalitySelected(personality: any) {
		this.cachedPersonalityCode = null;
		this.selectedPersonality = personality;
	}

	public getQuizCode() {
		if (this.cachedQuizCode != null) {
			return this.cachedQuizCode;
		}
		this.licenseeService.getCurrentLicensee().then((licenseeResponse: IHttpResponse<ILicensee>) => {
			if (!licenseeResponse.success) {
				this.errors.push('Could not load licensee: ' + licenseeResponse.helpMessage);
				return;
			}
			const licensee = licenseeResponse.data as ILicensee;
			const licenseeQuizUrl = `${environment.quizBaseUrl}/quiz/${licensee.licenseeId}`;
			this.cachedQuizCode = this.quizStart + licenseeQuizUrl + this.quizEnd;
		});
		return 'Not loaded';
	}

	public getSvg(personality: IPersonality) {
		return this.sanitizer.bypassSecurityTrustHtml(personality.svg);
	}

	public getPersonalityEmbedCode() {
		if (this.cachedPersonalityCode != null) {
			return this.cachedPersonalityCode;
		}
		this.licenseeService.getCurrentLicensee().then((licenseeResponse: IHttpResponse<ILicensee>) => {
			if (!licenseeResponse.success || this.selectedPersonality == null) {
				console.log('could not load licensee or selected personality: ' + licenseeResponse.helpMessage);
				return;
			}
			const licensee = licenseeResponse.data as ILicensee;
			const name = this.selectedPersonality.name.toLowerCase();
			this.cachedPersonalityCode = this.personalityStart + `/personality/${licensee.licenseeId}/${name}` + this.personalityEnd;
		});
		return 'Not loaded';
	}

	public async onCopyQuizCode() {
		if (!this.showEmbeddingCode) {
			this.licenseeService.showAccountRequiredWarning();
			return;
		}
		const code = await this.getQuizCode();
		const success = await this.copyTextToClipboard(code);
	}

	public async onCopyPersonalityCode() {
		if (!this.showEmbeddingCode) {
			this.licenseeService.showAccountRequiredWarning();
			return;
		}
		const code = await this.getPersonalityEmbedCode();
		const success = await this.copyTextToClipboard(code);
	}

	private async copyTextToClipboard(text: string): Promise<boolean> {
		let success = false;
		if (!navigator.clipboard) {
			success = this.copyTextToClipboardFallback(text);
			return success;
		}

		try {
			await navigator.clipboard.writeText(text);
			success = true;
		} catch (exception) {
		}

		return success;
	}

	private copyTextToClipboardFallback(text: string) {
		var textArea = document.createElement("textarea");
		textArea.value = text;

		// Avoid scrolling to bottom
		textArea.style.top = '0';
		textArea.style.left = '0';
		textArea.style.position = 'fixed';
		textArea.style.display = 'hidden';

		document.body.appendChild(textArea);
		textArea.focus();
		textArea.select();

		let success = false;
		try {
			success = document.execCommand('copy');
		} catch (err) {
		}

		document.body.removeChild(textArea);
		return success;
	}

}

<div
	*ngIf="hasErrors()"
	class="errors">
	<h1>{{ header }}</h1>

	<div
		*ngFor="let info of additionalInformation">
		{{ info }}
	</div>

	<ul class="error-list">
		<li
			*ngFor="let error of errors">
			{{ error }}
		</li>
	</ul>
</div>

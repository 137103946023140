
<mat-tab-group
	animationDuration="0ms"
	mat-align-tabs="start">
	<mat-tab label="First result email content">
		<div class="edit-container">
			<mat-form-field class="editor" class="editor">
				<mat-label>First test play result email content</mat-label>
				<textarea
					[(ngModel)]="personalisation.emails.result1Body"
					[froalaEditor]="htmlEditorOptions"
					matInput>{{ personalisation.emails.result1Body }}</textarea>
				<mat-error>Results email one body is <strong>required</strong></mat-error>
			</mat-form-field>

			<div class="preview-container">
				<h3>First result email preview</h3>

				<div
					class="preview"
					[innerHtml]="personalisation.emails.result1Body | preview:replacementValues">
				</div>
			</div>
		</div>
	</mat-tab>
	<mat-tab label="Second result email content">
		<div class="edit-container">
			<mat-form-field class="editor" class="editor">
				<mat-label>Second test play result email content</mat-label>
				<textarea
					[(ngModel)]="personalisation.emails.result2Body"
					[froalaEditor]="htmlEditorOptions"
					matInput>{{ personalisation.emails.result2Body }}</textarea>
				<mat-error>Results email two body is <strong>required</strong></mat-error>
			</mat-form-field>

			<div class="preview-container">
				<h3>Second result email preview</h3>

				<div
					class="preview"
					[innerHtml]="personalisation.emails.result2Body | preview:replacementValues">
				</div>
			</div>
		</div>
	</mat-tab>
</mat-tab-group>

<div class="container">
	<h1>{{ headerText }}</h1>

	<p>
		You are on subscription level
		<strong>{{ getSubscriptionLevel() }}</strong>
	</p>

	<p *ngIf="!isPaidSubscriber">
		If you would like to unlock more support, revenue IP
		or the Learning Hub, you can
		<a routerLink="/pages/subscription">upgrade your subscription</a>
		at any time.
	</p>

	<div
		class="attention"
		*ngIf="isPaidSubscriber && !arePayoutsAvailable">

		<h2>Congratulations on upgrading your Licence!</h2>

		<p>
			You are now eligible to receive commission on any tests played through your website
		</p>

		<p>
			In order to receive commission you need to do a one-off set-up of a commission Stripe
			account that has KYC verification. This will take about 10 minutes, however, once it
			is done, it is done forever.
		</p>

		<div>
			To do this you will need:
			<ul>
				<li>ID such as a driver's licence</li>
				<li>ABN or ACN</li>
			</ul>

			These will be matched against your ASIC record.
		</div>

		<div class="action-buttons">
			<button
				mat-raised-button
				color="primary"
				(click)="onReceiveCommissionsClicked()">
				<mat-icon>monetization_on</mat-icon>
				Start receiving commissions
			</button>
		</div>
	</div>

	<mat-card
		*ngIf="!isPaidSubscriber || arePayoutsAvailable">
		<mat-card-content>
			<div class="breakdown-row">
				<div class="text">
					<h2 class="text-title">Activity for {{ getMonthAndYear() }}</h2>
					<div class="text-container">
						<div class="month-plays text-display">
							<h3 class="value">{{ playsThisMonth }}</h3>
							<div class="description">Test plays for the month</div>
						</div>
						<div class="month-earnings text-display">
							<h3 class="value">{{ earningsThisMonth | currency }}</h3>
							<div class="description">Estimated earnings for the month</div>
						</div>
					</div>
				</div>
				<div class="graph">
					<brand-magic-bar-chart
						[datasets]="thisMonthDatasets"
						[labels]="thisMonthLabels">
					</brand-magic-bar-chart>
				</div>
			</div>
		</mat-card-content>
	</mat-card>

	<mat-card
		*ngIf="!isPaidSubscriber || arePayoutsAvailable">
		<mat-card-content>
			<div class="breakdown-row">
				<div class="text">
					<h2 class="text-title">Activity to date</h2>
					<div class="text-container">
						<div class="year-plays text-display">
							<h3 class="value">{{ playsAllTime }}</h3>
							<div class="description">All test plays to date</div>
						</div>
						<div class="month-earnings text-display">
							<h3 class="value">{{ earningsAllTime | currency }}</h3>
							<div class="description">Estimated earnings</div>
						</div>
					</div>
				</div>
				<div class="graph">
					<brand-magic-bar-chart
						[datasets]="allDatasets"
						[labels]="allLabels">
					</brand-magic-bar-chart>
				</div>
			</div>
		</mat-card-content>
	</mat-card>

	<p>
		* Note that the estimated earnings is based upon the number of times the test has been played and does not take into consideration
		free plays as a result of purchasing coupons.
	</p>
</div>

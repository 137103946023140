import { Injectable } from '@angular/core';
import {
	HttpService,
} from '@brand-magic/lib-http';
import {
	ILicence,
	IHttpResponse,
} from '@brand-magic/lib-types';

@Injectable({
	providedIn: 'root'
})
export class LicenceService {

	constructor(
		public httpService: HttpService,
	) {
	}

	public async getCurrentLicence(): Promise<IHttpResponse<ILicence>> {
		const response = await this.httpService
			.get<ILicence>('/api/get-current-licence');

		if (!response.success) {
			console.error(`Unable to get the latest licence: ${ response.helpMessage }`);
		}

		return response;
	}

	public async updateLicence(licence: ILicence): Promise<IHttpResponse<boolean>> {
		const requestData = {
			licence: licence
		};

		const response = await this.httpService
			.post<any, any>('/api/update-licence', requestData);

		if (!response.success) {
			console.error(`Unable to update licence: ${ response.helpMessage }`);
		}

		return response;
	}

}

import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {
	DomSanitizer,
	SafeHtml,
} from '@angular/platform-browser';
import { StringUtil } from '@brand-magic/lib-util';
import { ILicence } from '@brand-magic/lib-types';
import { LicenceService } from '../../services/licence.service';

@Component({
	selector: 'brand-magic-terms',
	templateUrl: './terms.template.html',
	styleUrls: ['./terms.style.scss'],
})
export class TermsComponent {
	public errors: string[] = [];
	public licence: SafeHtml | null = null;
	public isWorking = true;

	constructor(
		private sanitizer: DomSanitizer,
		private licenceService: LicenceService,
		public dialogRef: MatDialogRef<TermsComponent>
	) {
		this.loadLicence();
	}

	private async loadLicence() {
		this.errors.length = 0;
		this.isWorking = true;

		const licenceResult = await this.licenceService.getCurrentLicence();
		this.isWorking = false;
		if (!licenceResult.success) {
			this.errors.push(`We were unable to load the current terms and conditions: ${licenceResult.helpMessage}`);
			return;
		}

		const licence: ILicence = licenceResult.data as ILicence;
		this.licence = this.sanitizer.bypassSecurityTrustHtml(licence.content);
	}

	public isLoaded() {
		return this.licence != null;
	}

	public onClose() {
		this.dialogRef.close();
	}
}

import { Injectable } from '@angular/core';
import {
	StringUtil,
} from '@brand-magic/lib-util';
import {
	HttpClient,
	HttpHeaders,
} from '@angular/common/http';
import {
	IAuthToken,
	ILoginRequest,
	ICreateLicenseeRequest,
	ICreateLicenseeResponse,
	IHttpResponse,
	ILicensee,
} from '@brand-magic/lib-types';
import {
	RecoveryRequestEvent,
} from '../components/account-recovery/recovery-request.event';

import {
	JwtDecoder,
} from '../jwt-decoder';

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	private readonly AUTH_KEY = 'auth-token';
	private authToken: string | null = null;
	private jwtDecoder: JwtDecoder;
	private logoutFns: (() => void)[] = [];

	// where should we redirect after a successful login?
	private redirectUrl: string = StringUtil.empty;

	constructor(
		private http: HttpClient,
	) {
		this.jwtDecoder = new JwtDecoder();
	}

	public onLogout(logoutFn: () => void) {
		this.logoutFns.push(logoutFn);
	}

	public setRedirectUrl(url: string) {
		this.redirectUrl = url;
	}

	public getRedirectUrl() {
		return this.redirectUrl;
	}

	private setAuthToken(tokenStr: string) {
		this.authToken = tokenStr;
		localStorage.setItem(this.AUTH_KEY, tokenStr);
	}

	public isLoggedIn() {
		const token = this.getAuthToken();
		return !StringUtil.nullOrEmpty(token);
	}

	public getAuthToken(): string | null {
		if (this.authToken == null) {
			this.authToken = localStorage.getItem(this.AUTH_KEY);
		}

		if (!this.jwtDecoder.seemsFine(this.authToken)) {
			this.logout();
		}

		return this.authToken;
	}

	public getAuthData(): IAuthToken | null {
		const tokenStr = this.getAuthToken();
		return this.jwtDecoder.decode(tokenStr);
	}

	public login(data: ILoginRequest): Promise<IHttpResponse<string>> {
		this.logout();
		return new Promise((resolve, err) => {
			if (data == null) {
				resolve(this.nullDataError('login()'));
			}
			const opts = {
				responseType: 'json' as const,
			};
			try {
				this.http
					.post<IHttpResponse<string>>('/api/login', data, opts)
					.subscribe({
						next: resp => {
							if (resp.success) {
								this.setAuthToken(resp.data as string);
							}
							resolve(resp);
						},
						error: error => {
							const httpResponse: IHttpResponse<string> = {
								success: false,
								helpMessage: error.message,
								data: null,
							};
							resolve(httpResponse);
						},
					});
			} catch (exception: any) {
				console.error('Error with login: ', exception);
				const httpResponse: IHttpResponse<string> = {
					success: false,
					helpMessage: exception.message,
					data: null,
				};
				resolve(httpResponse);
			}
		});
	}

	public refreshAuthToken(): Promise<IHttpResponse<string>> {
		return new Promise((resolve, err) => {
			const opts = {
				responseType: 'json' as const,
				headers: new HttpHeaders(),
			};
			const authToken = this.getAuthToken();
			if (authToken != null && authToken.length > 0) {
				opts.headers = new HttpHeaders({
					'Authorization': `Bearer ${authToken}`,
				});
			}
			try {
				this.http
					.post<IHttpResponse<string>>('/api/refresh-auth-token', {}, opts)
					.subscribe({
						next: resp => {
							if (resp.success) {
								this.setAuthToken(resp.data as string);
							}
							resolve(resp);
						},
						error: error => {
							const httpResponse: IHttpResponse<string> = {
								success: false,
								helpMessage: error.message,
								data: null,
							};
							resolve(httpResponse);
						},
					});
			} catch (exception: any) {
				console.error('Exception refreshing auth token: ', exception);
				const httpResponse: IHttpResponse<string> = {
					success: false,
					helpMessage: exception.message,
					data: null,
				};
				resolve(httpResponse);
			}
		});
	}

	public recoverAccount(data: RecoveryRequestEvent): Promise<IHttpResponse<null>> {
		return new Promise((resolve, err) => {
			if (data == null) {
				resolve(this.nullDataError('recoverAccount()'));
			}
			const opts = {
				responseType: 'json' as const,
			};
			try {
				this.http
					.post<IHttpResponse<null>>('/api/recover-account', data, opts)
					.subscribe({
						next: resp => {
							resolve(resp);
						},
						error: error => {
							const httpResponse: IHttpResponse<null> = {
								success: false,
								helpMessage: error.message,
								data: null,
							};
							resolve(httpResponse);
						},
					});
			} catch (exception: any) {
				console.error('Error caught in recover account: ', exception);
				const httpResponse: IHttpResponse<null> = {
					success: false,
					helpMessage: exception.message,
					data: null,
				};
				resolve(httpResponse);
			}
		});
	}

	public async getLicenseeByResetCode(resetCode: string): Promise<IHttpResponse<ILicensee>> {
		return new Promise((resolve, reject) => {
			const requestData = {
				resetCode: resetCode
			};

			const opts = {
				responseType: 'json' as const,
			};

			try {
				this.http
					.post<IHttpResponse<ILicensee>>('/api/get-licensee-by-code', requestData)
					.subscribe({
						next: resp => {
							resolve(resp);
						},
						error: error => {
							const httpResponse: IHttpResponse<ILicensee> = {
								success: false,
								helpMessage: error.message,
								data: null,
							};
							resolve(httpResponse);
						},
					});
			} catch (exception: any) {
				console.error('Error creating new user: ', exception);
				const httpResponse: IHttpResponse<ILicensee> = {
					success: false,
					helpMessage: exception.message,
					data: null,
				};
				resolve(httpResponse);
			}
		});
	}

	public async updatePasswordByCode(resetCode: string, password: string): Promise<IHttpResponse<any>> {
		return new Promise((resolve, reject) => {
			const requestData = {
				resetCode: resetCode,
				password: password,
			};

			const opts = {
				responseType: 'json' as const,
			};

			try {
				this.http
					.post<IHttpResponse<ILicensee>>('/api/update-password-by-code', requestData)
					.subscribe({
						next: resp => {
							resolve(resp);
						},
						error: error => {
							const httpResponse: IHttpResponse<ILicensee> = {
								success: false,
								helpMessage: error.message,
								data: null,
							};
							resolve(httpResponse);
						},
					});
			} catch (exception: any) {
				console.error('Error updating password: ', exception);
				const httpResponse: IHttpResponse<ILicensee> = {
					success: false,
					helpMessage: exception.message,
					data: null,
				};
				resolve(httpResponse);
			}
		});
	}

	public createNewUser(data: ICreateLicenseeRequest): Promise<IHttpResponse<ICreateLicenseeResponse>> {
		return new Promise((resolve, err) => {
			this.logout();
			if (data == null) {
				resolve(this.nullDataError('createNewUser()'));
			}
			const opts = {
				responseType: 'json' as const,
			};

			try {
				this.http
					.post<IHttpResponse<ICreateLicenseeResponse>>('/new-user', data)
					.subscribe({
						next: resp => {
							resolve(resp);
						},
						error: error => {
							const httpResponse: IHttpResponse<ICreateLicenseeResponse> = {
								success: false,
								helpMessage: error.message,
								data: null,
							};
							resolve(httpResponse);
						},
					});
			} catch (exception: any) {
				console.error('Error creating new user: ', exception);
				const httpResponse: IHttpResponse<ICreateLicenseeResponse> = {
					success: false,
					helpMessage: exception.message,
					data: null,
				};
				resolve(httpResponse);
			}
		});
	}

	public logout() {
		this.authToken = null;
		localStorage.removeItem(this.AUTH_KEY);

		this.logoutFns.forEach((fn: () => void) => {
			try {
				fn();
			} catch (exception: any) {
				console.error('Could not execute logout callback: ' + exception.toString());
			}
		});
	}

	private nullDataError(methodName: string): IHttpResponse<any> {
		console.log('Null data passed to ' + methodName);
		return {
			success: false,
			helpMessage: 'No data provided for request',
			data: null
		};
	}
}

import { Injectable } from '@angular/core';
import {
	HttpService,
} from '@brand-magic/lib-http';
import { LicenseeService } from './licensee.service';
import {
	ILicenseePersonalisation,
	IHttpResponse,
} from '@brand-magic/lib-types';
import { ICachingService } from './caching-service.interface';

@Injectable({
	providedIn: 'root'
})
export class PersonalisationService implements ICachingService {
	private cachingEnabled = true;
	private personalisation: ILicenseePersonalisation | null = null;

	constructor(
		public httpService: HttpService,
	) {
	}

	public async getDefaultPersonalisation(): Promise<IHttpResponse<ILicenseePersonalisation>> {
		const response = await this.httpService
			.get<ILicenseePersonalisation>('/api/get-default-personalisation');

		if (!response.success) {
			console.error(response.helpMessage);
		}

		return response;
	}

	public async saveDefaultPersonalisation(personalisation: ILicenseePersonalisation): Promise<IHttpResponse<boolean>> {
		const request = {
			personalisation: personalisation
		}
		const response = await this.httpService
			.post<any, boolean>('/api/save-default-personalisation', request);

		if (!response.success) {
			console.error(response.helpMessage);
		}

		return response;
	}

	public async getCurrentPersonalisation(): Promise<IHttpResponse<ILicenseePersonalisation>> {
		const response: IHttpResponse<ILicenseePersonalisation> = {
			success: false,
			helpMessage: null,
			data: null
		}
		if (this.personalisation == null || !this.enableCaching) {
			const personalisationResponse = await this.getLicenseePersonalisation();
			if (!personalisationResponse.success) {
				console.log(personalisationResponse.helpMessage);
				response.helpMessage = 'Unable to load personalisation: ' + personalisationResponse.helpMessage;
			}
			this.personalisation = personalisationResponse.data;
		}

		response.data = this.personalisation;
		response.success = response.data != null;
		return response;
	}

	public async saveLicenseePersonalisation(personalisation: ILicenseePersonalisation): Promise<IHttpResponse<boolean>> {
		const request = {
			personalisation: personalisation
		}
		const response = await this.httpService
			.post<any, boolean>('/api/save-licensee-personalisation', request);

		if (!response.success) {
			console.error(response.helpMessage);
		}

		return response;
	}


	private async getLicenseePersonalisation(): Promise<IHttpResponse<ILicenseePersonalisation>> {
		const response = await this.httpService
			.get<ILicenseePersonalisation>('/api/get-licensee-personalisation');

		if (!response.success) {
			console.error(response.helpMessage);
		}

		return response;
	}

	public clearCaches() {
		this.personalisation = null;
	}

	public enableCaching(isEnabled: boolean) {
		this.cachingEnabled = isEnabled;
	}
}

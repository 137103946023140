<div
	class="login-buttons"
	*ngIf="!isLoggedIn()">
	<button
		mat-stroked-button
		(click)="onLoginClicked()">
		<mat-icon>account_circle</mat-icon>
		Login
	</button>
</div>

<ng-container
	*ngIf="isLoggedIn()">
	<button
		mat-stroked-button
		(click)="onLogoutClicked()">
		<mat-icon>account_circle</mat-icon>
		Log out {{ displayName }}
	</button>
</ng-container>

import {
	Pipe,
	PipeTransform,
} from '@angular/core';
import {
	DomSanitizer,
	SafeHtml,
} from '@angular/platform-browser';
import {
	StringUtil,
} from '@brand-magic/lib-util';

declare var Handlebars: any;

/*
 * Preview handlebar-templated content.
 *
 * usage:
 *   templateStr | preview:replacementVals:selectedPersonality
 *
 * Example:
 *  'Congrats {{PLAYER_NAME}}, you are {{ PERSONALITY_NAME }}' | preview:{ PLAYER_NAME: 'bob' }:selectedPersonality
 *
 * Replacement Tokens:
 *  {{{ PLAYER_NAME }}} - The name of the person playing the quiz
 *  {{{ PERSONALITY }}} - The name of the selected personality
 *  {{{ RESULT }}} - This the content block defined in the personality content block.
 *  {{{ PERSONALITY_DETAIL_URL }}} - The URL for the user's selected personality
 *  {{{ PERSONALITY_DETAIL_LINK }}} - A complete URL for user's selected personality
 *  {{{ LINK_TO_QUIZ_HOME "Link text goes here" }}} - Create a link back to the homepage of your quiz
 *  {{{ CTA1_BUTTON "Button text goes here" }}} - Create a link back to the book
 *  {{{ CTA2_BUTTON "Button text goes here" }}} - Create a link back to the course
 *
 * Replacement Values:
 *  PLAYER_NAME: this.licensee.givenName,
 *  PERSONALITY: 'The ' + this.selectedPersonalityGroup.personality.name,
 *  RESULT: personalityConfig.description,
 *  PERSONALITY_DETAIL_URL: personalityConfig.url,
 *  QUIZ_HOME_URL: this.personalisation.quizHomeUrl,
 *  CTA1_BUTTON: this.personalisation.cta1Url
 *  CTA2_BUTTON: this.personalisation.cta2Url
 *
 */
@Pipe({
	name: 'preview'
})
export class PreviewPipe implements PipeTransform {

	constructor(
		private sanitizer: DomSanitizer,
	) {
	}

	public transform(
		template: string,
		replacementValues: any,
	): SafeHtml {
		if (StringUtil.nullOrEmpty(template) || replacementValues == null) {
			return '';
		}

		try {
			const options = {
				helpers: {
					LINK_TO_QUIZ_HOME: (text: string) => {
						if (StringUtil.nullOrEmpty(text)) {
							text = 'Quiz home';
						}

						const link = "<a href='" + replacementValues.QUIZ_HOME_URL + "' target='_blank'>" + text +"</a>";
						return new Handlebars.SafeString(link);
					},
					CTA1_BUTTON: (text: string) => {
						if (StringUtil.nullOrEmpty(replacementValues.CTA1_URL)) {
							replacementValues.CTA1_URL = '';
							text = 'CTA1_URL personalisation value is missing';
						}

						if (StringUtil.nullOrEmpty(text)) {
							text = 'No text provided for this CTA';
						}

						const link = "<a class='cta-button cta1' href='" + replacementValues.CTA1_URL + "' target='_blank'>" + text +"</a>";
						return new Handlebars.SafeString(link);
					},
					CTA2_BUTTON: (text: string) => {
						if (StringUtil.nullOrEmpty(replacementValues.CTA2_URL)) {
							replacementValues.CTA2_URL = '';
							text = 'CTA2_URL personalisation value is missing';
						}

						if (StringUtil.nullOrEmpty(text)) {
							text = 'No text provided for this CTA';
						}

						const link = "<a class='cta-button cta1' href='" + replacementValues.CTA2_URL + "' target='_blank'>" + text +"</a>";
						return new Handlebars.SafeString(link);
					},
				}
			};

			const templateFn = Handlebars.compile(template);
			const html = templateFn(replacementValues, options);

			console.log(templateFn);

			return this.sanitizer.bypassSecurityTrustHtml(html);
		} catch (exception: any) {
			console.error('Exception caught previewing handlebar content', exception);
			return 'We were not able to preview your content - ' +
				'most likely there is an error with your template parameters. ' +
				'Details: ' + exception.toString();
		}
	}

}

import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DateTime } from 'luxon';
import { StringUtil } from '@brand-magic/lib-util';
import {
	LicenseeService,
} from '../../services/licensee.service';
import {
	ILicensee,
} from '@brand-magic/lib-types';

interface SaveEvent{
	licensee: ILicensee,
	password: string | null,
}

@Component({
	selector: 'brand-magic-create-account',
	templateUrl: './create-account.template.html',
	styleUrls: ['./create-account.style.scss'],
})
export class CreateAccountComponent {

	public errorMessage = 'We encountered an error trying to create your account.';
	public errorDescription = [
		'If the error is a result of data your have supplied, please make any necessary corrections.',
		'If this is our mistake, we have been notified of it and will investigate it soon.'
	];
	public errors: string[] = [];

	public licensee: ILicensee = {
		licenseeId: StringUtil.empty,
		stripe: {
			customerId: StringUtil.empty,
			accountId: StringUtil.empty,
			subscriptionId: StringUtil.empty,
			subscriptionExpiry: DateTime.fromSeconds(0),
		},
		isAdmin: false,
		password: StringUtil.empty,
		userName: StringUtil.empty,
		givenName: StringUtil.empty,
		familyName: StringUtil.empty,
		address: {
			address1: StringUtil.empty,
			city: StringUtil.empty,
			state: StringUtil.empty,
			postcode: StringUtil.empty,
			countryCode: StringUtil.empty,
		},
		companyName: StringUtil.empty,
		abn: StringUtil.empty,
		email: StringUtil.empty,
		website: StringUtil.empty,
		phone: StringUtil.empty,
		mobile: StringUtil.empty,
		subscriptionId: StringUtil.empty,
		agreedToTerms: false,
		isActive: true,
		dateCreated: DateTime.now(),
	};

	public isWorking = false;

	constructor(
		private licenseeService: LicenseeService,
		private router: Router,
	) {
	}

	public async onSave(update: SaveEvent) {
		this.licensee = update.licensee;
		const password = update.password as string;

		this.clearErrors();
		this.isWorking = true;
		const response = await this.licenseeService.insertAccount(this.licensee, password);
		this.isWorking = false;
		if (!response.success) {
			this.addError(response.helpMessage);
			return;
		}

		this.router.navigateByUrl('/pages/subscription');
	}

	private clearErrors() {
		this.errors.length = 0;
	}

	private addError(str: string | null | undefined) {
		if (str != null) {
			this.errors.push(str);
		}
	}
}

<div class="container">
	<form>
		<h1>Brand Magic</h1>

		<p>
			Welcome to Brand Magic!
		</p>

		<p>
			Before you can access our content, you will need to login to authenticate yourself.
		</p>

		<brand-magic-error-display
			[header]="errorMessage"
			[errors]="errors">
		</brand-magic-error-display>


		<brand-magic-auth-login
			(loginRequest)="onLogin($event)"
			(loginFailure)="onLoginFailure($event)">
		</brand-magic-auth-login>

		<p>
			Don't have one? <a routerLink="/auth/create-account">Create an account</a>!
		</p>

		<p>
			<a routerLink="/auth/recover-account">Forgot your password?</a> Don't worry - you can recover your email.
		</p>
	</form>
</div>

import {
	Component,
	Output,
	EventEmitter,
} from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { StringUtil  } from '@brand-magic/lib-util';

@Component({
	selector: 'auth-login-display',
	templateUrl: './login-display.template.html',
	styleUrls: ['./login-display.style.scss'],
})
export class LoginDisplayComponent {
	public displayName = '';

	@Output()
	public login = new EventEmitter();

	@Output()
	public logout = new EventEmitter();

	constructor(
		private authService: AuthService,
	) {
	}

	public isLoggedIn() {
		return this.authService.isLoggedIn();
	}

	public onLoginClicked() {
		this.login.emit();
	}

	public onLogoutClicked() {
		this.authService.logout();
		this.logout.emit();
	}

	public getDisplayName() {
		if (StringUtil.nullOrEmpty(this.displayName)) {
			const authData = this.authService.getAuthData();
			if (authData == null) {
				return this.displayName;
			}

			this.displayName = authData.givenName;
		}

		return this.displayName;
	}
}

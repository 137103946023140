<div class="container">
	<h1>Buy and manage coupons</h1>

	<ng-container
		*ngIf="!isPaid">

		<h2>
			This feature is only available for Magician and Wizard subscribers.
		</h2>

		<h3>
			Please consider upgrading your subscription.
		</h3>

		<p
			class="upgrade-button">
			<a
				mat-raised-button
				routerLink="/pages/subscription">
				Upgrade your subscription
			</a>
		</p>

	</ng-container>

	<div
		*ngIf="errors.length > 0"
		class="errors">
		<h1>We encountered some errors related to your coupons</h1>

		<div>
			Unfortunately we encountered some errors. The details are:
		</div>

		<brand-magic-error-display
			[errors]="errors">
		</brand-magic-error-display>
	</div>

	<div
		[ngClass]="{'unavailable': !isPaid, 'purchase-button': true}">
		<p
			class="purchase-button">
			You can purchase coupons at a time which you can give to prospective clients so that
			they can play the test without paying. You could use this as a promotion or an
			onboarding tool.
		</p>

		<div class="action-buttons">
			<button
				mat-raised-button
				(click)="onPurchaseCuponsClicked()">
				<mat-icon>add_shopping_cart</mat-icon>
				Purchase 10 coupons
			</button>
		</div>

		<div
			*ngIf="isLoading"
			class="loading">
			<mat-spinner></mat-spinner>
		</div>

		<div
			*ngIf="!isLoading && allCoupons.length === 0">

			<h2>No coupons</h2>

			<p>
				You don't have any coupons yet.
			</p>

			<p>
				However, it's pretty easy to get some, just by clicking the 'purchase coupons' button!
			</p>

		</div>

		<div
			[ngClass]="{'hidden': isLoading || allCoupons.length === 0}">
			<table
				mat-table
				[dataSource]="dataSource"
				class="mat-elevation-z6 coupon-table">

				<ng-container matColumnDef="couponCode">
					<th mat-header-cell *matHeaderCellDef>
						Coupon Code
					</th>
					<td mat-cell *matCellDef="let coupon">
						{{ coupon.couponCode }}
					</td>
				</ng-container>

				<ng-container matColumnDef="playerName">
					<th mat-header-cell *matHeaderCellDef>
						Player Name
					</th>
					<td mat-cell *matCellDef="let coupon">
						<span
							*ngIf="isSent(coupon)">
							{{ coupon.playerName }}
						</span>
						<mat-form-field
							*ngIf="!isSent(coupon)"
							appearance="fill">
							<mat-label>Player name</mat-label>
							<input
								matInput
								[(ngModel)]="coupon.playerName">
						</mat-form-field>
					</td>
				</ng-container>

				<ng-container matColumnDef="email">
					<th mat-header-cell *matHeaderCellDef>
						Email address
					</th>
					<td mat-cell *matCellDef="let coupon">
						<span
							*ngIf="isSent(coupon)">
							{{ coupon.email }}
						</span>
						<mat-form-field
							*ngIf="!isSent(coupon)"
							appearance="fill">
							<mat-label>Email address</mat-label>
							<input
								matInput
								[(ngModel)]="coupon.email">
						</mat-form-field>
					</td>
				</ng-container>

				<ng-container matColumnDef="invited">
					<th mat-header-cell *matHeaderCellDef class="center">
						Invited
					</th>
					<td mat-cell *matCellDef="let coupon" class="small">
						{{ dateToString(coupon.invited) }}
					</td>
				</ng-container>

				<ng-container matColumnDef="redeemed">
					<th mat-header-cell *matHeaderCellDef class="center">
						Redeemed
					</th>
					<td mat-cell *matCellDef="let coupon" class="small">
						{{ dateToString(coupon.played) }}
					</td>
				</ng-container>

				<ng-container matColumnDef="action">
					<th mat-header-cell *matHeaderCellDef>
					</th>
					<td mat-cell *matCellDef="let coupon">
						<button
							mat-raised-button
							disabled
							*ngIf="isSent(coupon)">
							<mat-icon>mark_email_read</mat-icon>
							Sent
						</button>
						<button
							mat-raised-button
							*ngIf="!isSent(coupon)"
							color="primary"
							(click)="onSend(coupon)">
							<mat-icon>mail</mat-icon>
							Send
						</button>
					</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
				<tr class="coupon-row" mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
			</table>

			<mat-paginator
				class="mat-elevation-z6"
				[pageSizeOptions]="availablePageSizes"
				[pageSize]="pageSize"
				showFirstLastButtons
				aria-label="Select page of coupons">
			</mat-paginator>

		</div>
	</div>
</div>
